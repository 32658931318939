import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EventService {
    private events: any = {};

    on(eventName: string, callback: (...args: any[]) => void): Subscription {
        if (!this.events[eventName]) {
            this.events[eventName] = new Subject<any>();
        }
        return this.events[eventName].subscribe(callback);
    }

    off(eventName: string, callback?: (...args: any[]) => void): void {
        if (!this.events[eventName]) {
            return;
        }

        this.events[eventName].unsubscribe();
        this.events[eventName] = null;
    }

    trigger(eventName: string, ...args: any[]): void {
        if (!this.events[eventName]) {
            return;
        }
        
        this.events[eventName].next(...args);
    }
}
