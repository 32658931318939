import { loadPrimaryReportByVariableForPersonalize } from "./deatailed-reporting.actions";

export interface State {
    primaryReportForPersonalizePayload: any;
    primaryReportForPersonalize: any;

}

const initialState: State = {
    primaryReportForPersonalizePayload: null,
    primaryReportForPersonalize: null,

};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        // Primary Report By Variable For Personalize
        case loadPrimaryReportByVariableForPersonalize.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                primaryReportForPersonalizePayload: requestPayload,
            });
        }
        case loadPrimaryReportByVariableForPersonalize.success.type: {

            return Object.assign({}, state, {
                primaryReportForPersonalize: action,
            });
        }



        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}


export const getPrimaryReportForPersonalize = (state: State) => state.primaryReportForPersonalize;