import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from '@app/shared/services/user.service';
import { loadAccountList, loadAdminsByCustomer } from '@app/invites/invites.actions';
import { Subject, skip, takeUntil } from 'rxjs';
import { ERole } from 'src/assets/enums/profileEnum';
import * as reducers from '../../../../reducers';
import { Store } from '@ngrx/store';
import { SecurlyInviteListComponent } from '../securly-invite-list/securly-invite-list.component';
@Component({
  selector: 'app-securly-invite-popup',
  templateUrl: './securly-invite-popup.component.html',
  styleUrls: ['./securly-invite-popup.component.scss']
})
export class SecurlyInvitePopupComponent {
  accountsList: any = [];
  selectedAccount: any;
  filteredaccountsList: any = [];
  selectedAccountSearchControl = new FormControl();
  eRole = ERole;
  destroy$: Subject<boolean> = new Subject<boolean>();
  confirmBtnDisable: boolean = true
  constructor(
    public dialogRef: MatDialogRef<SecurlyInvitePopupComponent>,
    public dialog: MatDialog,
    private store: Store<reducers.State>,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
  ) {

    this.selectedAccountSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredaccountsList = this.accountsList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });

    if (dialogData.fromAccountPage) {
      
      this.selectedAccount = {
        customerEmail: dialogData.customerTextId,
        id: dialogData.accountId
      }
      this.confirmBtnDisable = false;
    } else if (this.userService.user?.role?.key != this.eRole.ADMIN) {

      this.selectedAccount = {
        customerEmail: this.userService.user?.customerTextId,
        id: this.userService.user?.accountId
      }
    } else {
      this.getAccountsList();
    }
  }


  getAccountsList() {
    this.store.dispatch(loadAccountList.init({ isForSecurlySync: true }));
    this.store.select(reducers.getinviteAccountList).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.data) {
        this.accountsList = res.data;
        this.filteredaccountsList = this.accountsList;
      }
    });
  }

  onAccountChange() {
    if (this.selectedAccount) {
      this.confirmBtnDisable = false;
    } else {
      this.confirmBtnDisable = true
    }
  }

  sync() {
    this.store.dispatch(loadAdminsByCustomer.init({
      selectedAccountId: this.selectedAccount.id,
      pageIndex: 0,
      pageSize: 10,
      schoolIds: [],
      search: ''
    }));
    this.store.select(reducers.getAdminsByCustomer).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.success) {
        this.openSecurlySyncDialog(res.data.datas, res.data.totalRecordCount);
      }

    });
  }

  openSecurlySyncDialog(data: any, totalRecCount: any) {
    this.destroy$.next(true);
    const dialogRef = this.dialog.open(SecurlyInviteListComponent, {
      width: '1100px',
      data: {
        list: data,
        totalRecCount: totalRecCount,
        customerTextId: this.selectedAccount.customerEmail,
        accountId: this.selectedAccount.id
      },
    });
    dialogRef.afterClosed().subscribe((data: any) => {
        this.dialogRef.close()
    });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
