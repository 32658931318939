import { Injectable } from '@angular/core';
import { LoginResponseDto, UserResponseDto } from '../service-proxies/service-proxies';

// import { RealTimeUpdatePusherService } from '../real-time-update/real-time-update-pusher.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  loginResponse: LoginResponseDto | null;
  user?: UserResponseDto;

  constructor(/* private _realTimeUpdatePusherService: RealTimeUpdatePusherService */) {

  }

  setLoginResponse(loginReponse: LoginResponseDto | null): void {

    this.loginResponse = loginReponse;
    this.user = this.loginResponse?.user;
    let accessToken = this.loginResponse?.accessToken
    let refreshToken = this.loginResponse?.refreshToken
    localStorage.setItem('accessToken', `${accessToken}`);
    localStorage.setItem('refreshToken', `${refreshToken}`);

    // this._realTimeUpdatePusherService.subscribeUserChannel(this.user?.userId || 0);
  }

  setuser(data: any): void {

    this.user = data;
    // this._realTimeUpdatePusherService.subscribeUserChannel(data.userId || 0);
  }

}
