import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'round'
})
export class RoundPipe implements PipeTransform {

  transform(value: number): number {
    if (value < 1) {
      return parseFloat(value.toFixed(2));
    } else {
      return Math.round(value);
    }
  }

}
