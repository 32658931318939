import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ColumnSorterService } from '@app/shared/directives/column-sorter.service';
import { Store } from '@ngrx/store';
import * as reducers from '../../../reducers';
import { resetColumnSettings, resetTable } from '@app/app.actions';
@Component({
  selector: 'va-mat-table-column-sorter, button[va-mat-table-column-sorter]',
  templateUrl: './sorter-select.component.html',
  styleUrls: ['./sorter-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ColumnSorterService],
})
export class SorterSelectComponent implements OnInit {
  @Output()
  columnsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Input()
  resetArray: any[];
  @Input()
  columns: string[];
  @Input()
  columnNames: string[];
  @Input()
  saveName?: string;
  columnInfo: any[];
  constructor(
    private elementRef: ElementRef,
    private columnSorterService: ColumnSorterService,
    private store: Store<reducers.State>
  ) { }

  ngOnInit() {
    this.columnInfo = this.columns?.map((currElement, index) => {
      return {
        id: currElement,
        name: this.columnNames[index],
        hidden: false,
        disable: this.isColumnDisable(currElement),
      };
    });

    this.columnInfo = this.columnSorterService.loadSavedColumnInfo(
      this.columnInfo,
      this.saveName
    );
    this.emitColumns(false);
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.classList += 'va-mat-button-no-input';
  }
  isColumnDisable(id: string) {
    const col = this.resetArray.find((col) => col.id == id);
    return col?.disable;
  }

  columnMenuDropped(event: CdkDragDrop<any>): void {
    moveItemInArray(
      this.columnInfo,
      event.item.data.columnIndex,
      event.currentIndex
    );
    this.emitColumns(true);
  }
  toggleSelectedColumn(columnId: string) {
    const colFound = this.columnInfo?.find((col) => col.id === columnId);
    colFound.hidden = !colFound.hidden;
    this.emitColumns(true);
  }

  private emitColumns(saveColumns: boolean) {
    // Only emit the columns on the next animation frame available
    window.requestAnimationFrame(() => {
      this.columnsChange.emit(
        this.columnInfo
          ?.filter((colInfo) => !colInfo.hidden)
          .map((colInfo) => colInfo.id)
      );
      if (saveColumns) {
        this.columnSorterService.saveColumnInfo(this.columnInfo, this.saveName);
      }
    });
  }
  onReset(event: any) {
    this.columnInfo = [
      ...this.resetArray.map((col: any) => {
        col.hidden = false;
        return col;
      }),
    ];
    this.store.dispatch(resetColumnSettings.init({ tableName: this.saveName }));
    this.store.dispatch(resetTable({}));
    this.emitColumns(true);
  }

  onlyOneColumnShown() {
    return this.columnInfo?.filter((col) => !col.hidden).length === 1;
  }

}
