import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";

export const loadUserList = createAsyncAction(
    '[Users] Load User List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadUserRoles = createAsyncAction(
    '[Users] Load UserRoles List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAccountList = createAsyncAction(
    '[Users] Load Account List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const deleteUserfromList = createAsyncAction(
    '[Users] Delete User from List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const createNewUser = createAsyncAction(
    '[Users] Create User',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAllSchools = createAsyncAction(
    '[Users] load all schools',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const updateUser  = createAsyncAction(
    '[Users] update User',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadUserById  = createAsyncAction(
    '[Users] get User by Id',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);