import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private jwtToken: string | null = null;
  token: any;
  refreshToken: any;

  constructor(
    // private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private _authService : AuthenticationService,
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let accessToken = localStorage.getItem('accessToken');
    let refreshToken = localStorage.getItem('refreshToken');
    this.token = this.userService.loginResponse
    this.token = this.userService.loginResponse?.accessToken ? this.userService.loginResponse?.accessToken : accessToken;
    this.refreshToken = this.userService.loginResponse?.refreshToken ? this.userService.loginResponse?.refreshToken : refreshToken;

    let tempRequest: HttpRequest<unknown>;

    if (!this.token || !this.refreshToken) {
      tempRequest = request;
    }
    else {
      tempRequest = request.clone({
        setHeaders: {
          "Authorization": 'Bearer' + ' ' + this.token,
          'refreshToken': this.refreshToken,
        }
      });
    }

    return next.handle(tempRequest).pipe(

      tap({

        next: () => { },
        error: async (err: any) => {

          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }

            if (err.status == 401) {
              // localStorage.removeItem('accessToken');
              // localStorage.removeItem('refreshToken');
              this._authService.logout()
              // await this.router.navigate(['/auth/loginemail']);
              // location.reload();
            }
          }
        }
      })
    );
  }
}
