<div class="dailog-box-container">

    <div class="heading-wrapper">
        <div class="heading-text">
            <h4 class="mb-0">Change Password</h4>
        </div>
        <div class="modal-close-btn ">
            <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove"></i></span>
        </div>
    </div>
    <form [formGroup]="createUserForm">
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <div class="icon-input-wrapper">
                    <label class="label-text" for="password">Password</label>
                    <input [type]="hide ? 'password' : 'text'" class="form-control" [(ngModel)]="password"
                        name="password" formControlName="password" id="password" aria-describedby="desc"
                        placeholder="Enter Your Password" />
                    <div class="mat-form-field-icon">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <button mat-icon-button matSuffix (click)="autoGeneratePassword()"
                            aria-label="Generate password">
                            <mat-icon>autorenew</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="error" *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)">
                    <div class="display" *ngIf="f['password'].errors && f['password'].errors['password']">
                        <p>Password is required</p>
                    </div>
                </div>
                <div class="error" *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)">
                    <div class="display" *ngIf="f['password'].errors && !f['password'].errors['required']">
                        <p>It must be at least 8 characters long and must contain at least one
                            uppercase, one
                            lowercase, one digit, one special character from the set [#?!@$%^&*-].</p>
                    </div>
                </div>
                <div class="error">
                    <div class="display" *ngIf="createUserForm.hasError('notSame')">
                        <p>Password & Confirm Password must be same</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
                <div class="icon-input-wrapper">
                    <label class="label-text" for="confirmPassword">Confirm Password</label>
                    <input [type]="hide ? 'password' : 'text'" class="form-control" [(ngModel)]="confirmPassword"
                        name="confirmPassword" formControlName="confirmPassword" id="confirmPassword" aria-describedby="desc"
                        placeholder="Enter Your Confirm Password" />
                    <div class="mat-form-field-icon">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="error"
                    *ngIf="f['confirmPassword'].invalid && (f['confirmPassword'].dirty || f['confirmPassword'].touched)">
                    <div class="display"
                        *ngIf="f['confirmPassword'].errors && f['confirmPassword'].errors['confirmPassword']">
                        <p>Password is required</p>
                    </div>
                </div>
                <div class="error"
                    *ngIf="f['confirmPassword'].invalid && (f['confirmPassword'].dirty || f['confirmPassword'].touched)">
                    <div class="display"
                        *ngIf="f['confirmPassword'].errors && !f['confirmPassword'].errors['required']">
                        <p>It must be at least 8 characters long and must contain at least one
                            uppercase, one
                            lowercase, one digit, one special character from the set [#?!@$%^&*-].</p>
                    </div>
                </div>
                <div class="error">
                    <div class="display" *ngIf="createUserForm.hasError('notSame')">
                        <p>Password & Confirm Password must be same</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-end custom-button">
            <button type="submit" class="button small btn-primary" (click)="submit()">Change Password</button>
        </div>

    </form>
</div>