import { createAction, props } from "@ngrx/store";
import { createAsyncAction } from "./util";

export const loadColumnSettings = createAsyncAction(
  '[Table] Load Grid Column Settings',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()
);
export const updateColumnSettings = createAsyncAction(
  '[Table] Update Grid Column Settings',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()
);
export const resetColumnSettings = createAsyncAction(
  '[Table] Reset Grid Column Settings',
  props<any>(),
  props<{ payload: any }>(),
  props<{ message: string }>()
);
export const resetTable = createAction(
  '[Users] Reset Table Data',
  props<any>()
);
