import { ClinicalFrameworkStatus, ClinicalFrameworkToAddDto, ClinicalFrameworkToUpdateDto } from "@app/shared/service-proxies/service-proxies";
import { createAsyncAction } from "@app/util";
import { createAction, props } from "@ngrx/store";

export const loadClinicalFramework = createAsyncAction(
    '[Frameworks] Load Clinical Framework',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadDeleteAPIResponse = createAsyncAction(
    '[Delete Frameworks] Delete Clinical Framework',
    props<{ id: number}>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadCloneAPIResponse = createAsyncAction(
    '[Clone Frameworks] Clone Clinical Framework',
    props<{ id: number}>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadChangeStatusAPIResponse = createAsyncAction(
    '[Change Status- Frameworks] Change Status- Clinical Framework',
    props<{ id: number, status: ClinicalFrameworkStatus}>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadCreateAPIResponse = createAsyncAction(
    '[Create Frameworks] Create Clinical Framework',
    props<{data: ClinicalFrameworkToAddDto}>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadUpdateAPIResponse = createAsyncAction(
    '[Update Frameworks] Update Clinical Framework',
    props<{id: number , data: ClinicalFrameworkToUpdateDto}>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadDetailAPIResponse = createAsyncAction(
    '[Load Framework Detail] Load Clinical Framework Detail',
    props<{id: number}>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);