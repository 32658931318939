import { AcceptUserInviteDto, IResult, UserInviteToListDtoIDataResult } from "@app/shared/service-proxies/service-proxies";
import { acceptUserInvite, forgetPassword, loadUserInviteByCode, resetPassword, validateResetPasswordLink } from "./auth.actions";


export interface State {
    userInviteByCodePayload: any;
    userInviteByCode: UserInviteToListDtoIDataResult;
    acceptUserInvitePayload: AcceptUserInviteDto;
    acceptUserInvite: IResult;
    forgetPasswordPayload: AcceptUserInviteDto;
    forgetPassword: IResult;
    resetPasswordPayload: AcceptUserInviteDto;
    resetPassword: IResult;
    validateResetPasswordLinkPayload: any;
    validateResetPasswordLink: IResult;
}

const initialState: State = {
    userInviteByCodePayload: null,
    userInviteByCode: null,
    acceptUserInvitePayload: null,
    acceptUserInvite: null,
    forgetPasswordPayload: null,
    forgetPassword: null,
    resetPasswordPayload: null,
    resetPassword: null,
    validateResetPasswordLinkPayload: null,
    validateResetPasswordLink: null,
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        // User List
        case loadUserInviteByCode.init.type: {
            const requestPayload = action;
            return Object.assign({}, state, {
                userInviteByCodePayload: requestPayload,
            });
        }
        case loadUserInviteByCode.success.type: {

            return Object.assign({}, state, {
                userInviteByCode: action,
            })
        }
        case acceptUserInvite.init.type: {
            const requestPayload = action;
            return Object.assign({}, state, {
                acceptUserInvitePayload: requestPayload,
            });
        }
        case acceptUserInvite.success.type: {

            return Object.assign({}, state, {
                acceptUserInvite: action,
            })
        }
        case forgetPassword.init.type: {
            const requestPayload = action;
            return Object.assign({}, state, {
                forgetPasswordPayload: requestPayload,
            });
        }
        case forgetPassword.success.type: {

            return Object.assign({}, state, {
                forgetPassword: action,
            })
        }
        case resetPassword.init.type: {
            const requestPayload = action;
            return Object.assign({}, state, {
                resetPasswordPayload: requestPayload,
            });
        }
        case resetPassword.success.type: {

            return Object.assign({}, state, {
                resetPassword: action,
            })
        }
        case validateResetPasswordLink.init.type: {
            const requestPayload = action;
            return Object.assign({}, state, {
                validateResetPasswordLinkPayload: requestPayload,
            });
        }
        case validateResetPasswordLink.success.type: {

            return Object.assign({}, state, {
                validateResetPasswordLink: action,
            })
        }

        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getUserInviteByCode = (state: State) => state.userInviteByCode;
export const acceptUserInvitation = (state: State) => state.acceptUserInvite;
export const forgetUserPassword = (state: State) => state.forgetPassword;
export const resetUserPassword = (state: State) => state.resetPassword;
export const validateResetLink = (state: State) => state.validateResetPasswordLink;