<div class="container">
    <div class="message-box-wrapper">

        <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />

        <img src="assets/mail.png" width="75" alt="mail">
        <h5 class="fw-semibold mb-12">Success !</h5>
        <p>A email has been send to <b>{{emailAddress}}</b>. Please check for an email from company and click on the
            included link to reset your password.</p>
        <button routerLink="/auth/loginemail" class="btn btn-primary w-100 text-white">Back To Login</button>
    </div>
</div>