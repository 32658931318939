/*
* Entry point of the application.
* Only platform bootstrapping code should be here.
* For app-specific initialization, use `app/app.component.ts`.
*/

import { AppConsts } from './app/shared/AppConsts';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';
import { hmrBootstrap } from './hmr';
import { RootModule } from './root.module';
import { API_BASE_URL } from '@app/shared/service-proxies/service-proxies';

if (environment.production) {
  enableProdMode();
  if (window && window.console) {
    // Prevent console logs from running in production
    window.console.log = function () { };
    window.console.info = function () { };
    window.console.warn = function () { };
    window.console.error = function () { };
  }
}
fetch("/assets/appconfig.json").then(x => x.json())
  .then(config => {
    AppConsts.initialize(config)
    const bootstrap = () => platformBrowserDynamic([{ provide: API_BASE_URL, useValue: AppConsts.apiBaseUrl }]).bootstrapModule(RootModule);

    if (environment.hmr) {
      hmrBootstrap(module, bootstrap);
    } else {
      bootstrap().catch((err) => console.error(err));
    }
  })
