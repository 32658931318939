import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/shared/services/authentication.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(
    private _router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
  }
  onBackToLogin() { localStorage.clear()
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    this.authenticationService.logout();
  }


}
