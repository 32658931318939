import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-password-changed-message',
  templateUrl: './password-changed-message.component.html',
  styleUrls: ['./password-changed-message.component.scss']
})
export class PasswordChangedMessageComponent {

  message: string = '' ;
  routeState: any;
  constructor(
      private router: Router,
      
  ) {
      
      this.routeState = this.router.getCurrentNavigation()?.extras.state;
      if(this.routeState?.message){

          this.message = this.routeState?.message
      }


  }

}
