<ng-container *ngIf="isSSO">
  <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
  <!-- <div class="loader-container">
    <ngx-ui-loader loaderId="onSSO" [hasProgressBar]="false" bgsPosition="center-center" bgsType="double-bounce"></ngx-ui-loader>
  </div> -->
  
</ng-container>
<ng-container  *ngIf="!isSSO">
  <h3 translate></h3>
  <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
  <div class="form-style">
    <form [formGroup]="loginForm" novalidate>
      <div class="form-group">
        <label class="d-block">
          <input type="text" class="form-control" formControlName="username" autocomplete="username"
            [placeholder]="'Username' | translate" />
          <span hidden translate>Username</span>
          <small [hidden]="
                      loginForm.controls.username.valid ||
                      loginForm.controls.username.untouched
                    " class="text-danger" translate>
            Username is required
          </small>
        </label>
        <label class="d-block">
          <input type="password" class="form-control" formControlName="password" autocomplete="current-password"
            [placeholder]="'Password' | translate" required />
          <span hidden translate>Password</span>
          <small [hidden]="
                      loginForm.controls.password.valid ||
                      loginForm.controls.password.untouched
                    " class="text-danger" translate>
            Password is required
          </small>
        </label>
        <div class="login-actions">
          <div>
            <a routerLink="/auth/forgot-password" id="forget-password" class="">{{"Forgot Password"}}</a>
          </div>
        </div>
      </div>
      <button class="btn btn-primary w-100" [disabled]="!loginForm.valid" (click)="redirectToDashboard()" type="button">
        <span translate style="color: #fff;">Login</span>
      </button>
    </form>
  </div>
</ng-container>
