import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResetUserPasswordDto, UserServiceProxy } from '@app/shared/service-proxies/service-proxies';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  password: any;
  confirmPassword: any;
  userId: any;
  hide = true;
  
  createUserForm:FormGroup

  constructor(
    private userServiceProxy: UserServiceProxy,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangePasswordComponent>
  ) {
    this.userId = data.userId
   }

  ngOnInit(): void {
  }


  submit() {
    const resetUserPasswordDto = new ResetUserPasswordDto();
    resetUserPasswordDto.password = this.password
    resetUserPasswordDto.passwordConfirmation = this.confirmPassword
    resetUserPasswordDto.userId = this.userId
    this.userServiceProxy.resetUserPassword(resetUserPasswordDto).subscribe(data => {
      this.dialogRef.close({
        clicked: 'submit',
      });

    })

  }

  get f() {
    return this.createUserForm.controls;
  }

  generatePassword() {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890#?!@$%^&*-";
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let password = "";
    do {
      password = "";
      for (let i = 0; i < 8; i++) {
        password += chars[Math.floor(Math.random() * chars.length)];
      }
    } while (!regex.test(password));
    return password;
  }

  autoGeneratePassword() {
    const password = this.generatePassword();
    this.password = password;
    this.confirmPassword = password
  }

  initform() {
    this.createUserForm = this.formbuilder.group({
        password : new FormControl(null,[Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+~`|}{[\]:;?><,./-=])[0-9a-zA-Z!@#$%^&*()_+~`|}{[\]:;?><,./-=]{8,}$/)]),
        confirmPassword : new FormControl(null,[Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+~`|}{[\]:;?><,./-=])[0-9a-zA-Z!@#$%^&*()_+~`|}{[\]:;?><,./-=]{8,}$/)]),
      },
      {
        validators : this.checkPasswords
      });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls['password'].value;
    const confirmPass = group.controls['confirmPassword'].value;

    return pass === confirmPass ? null : { notSame: true };
}

ngAfterContentInit(){
    this.initform()
  }

}
