<div *ngIf="isTokenValid && isTokenValid != undefined">
    <h3 translate></h3>
    <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
    <p>Your new password should be different from previously used passwords</p>
    <div class="form-style">
        <form [formGroup]="resetPasswordForm" novalidate>
            <div class="form-group">
                <label class="d-block">
                    <div class="icon-input-wrapper">
                        <input id="pass" [type]="hide ? 'password' : 'text'" formControlName="newpassword"
                            class="form-control" [placeholder]="'Enter your password' | translate" />
                        <span hidden translate>New Password</span>
                        <div class="mat-form-field-icon">
                            <button mat-icon-button matSuffix (click)="autoGeneratePassword()"
                                aria-label="Generate password">
                                <mat-icon>autorenew</mat-icon>
                            </button>
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="f['newpassword'].invalid && (f['newpassword'].dirty || f['newpassword'].touched)">
                        <div class="display" *ngIf="f['newpassword'].errors && f['newpassword'].errors['required']">
                            <p>Password is required</p>
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="f['newpassword'].invalid && (f['newpassword'].dirty || f['newpassword'].touched)">
                        <div class="display" *ngIf="f['newpassword'].errors && !f['newpassword'].errors['required']">
                            <p>It must be at least 8 characters long and must contain at least one
                                uppercase, one
                                lowercase, one digit, one special character from the set [#?!@$%^&*-].</p>
                        </div>
                    </div>
                </label>
            </div>
            <div class="form-group">
                <label class="d-block">
                    <div class="icon-input-wrapper">
                        <input id="confirmPass" [type]="hide ? 'password' : 'text'" formControlName="confirmpassword"
                            class="form-control" [placeholder]="'Enter confirm password' | translate" />
                        <span hidden translate>Confirm Password</span>
                        <div class="mat-form-field-icon">
                            <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="f['confirmpassword'].invalid && (f['confirmpassword'].dirty || f['confirmpassword'].touched)">
                        <div class="display"
                            *ngIf="f['confirmpassword'].errors && f['confirmpassword'].errors['confirmpassword']">
                            <p>Password is required</p>
                        </div>
                    </div>
                    <div class="error"
                        *ngIf="f['confirmpassword'].invalid && (f['confirmpassword'].dirty || f['confirmpassword'].touched)">
                        <div class="display"
                            *ngIf="f['confirmpassword'].errors && !f['confirmpassword'].errors['required']">
                            <p>It must be at least 8 characters long and must contain at least one
                                uppercase, one
                                lowercase, one digit, one special character from the set [#?!@$%^&*-].</p>
                        </div>
                    </div>
                    <div class="error">
                        <div class="display" *ngIf="resetPasswordForm.hasError('notSame')">
                            <p>Password & Confirm Password must be same</p>
                        </div>
                    </div>
                </label>
            </div>
            <button class="btn btn-primary w-100" type="button" [disabled]="resetPasswordForm.invalid"
                (click)="saveNewPassword()">
                <span translate style="color: #fff;">Set New Password</span>
            </button>
        </form>
    </div>
</div>
<div *ngIf="!isTokenValid && isTokenValid != undefined">
    <div class="error-massage-wrapper">
        <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
        <img src="assets/warning-sign.svg" class="img-fluid py-3" style="width:15%; padding: 0 !important;" />
        <h5 class="">Error !</h5>
        <p style="font-size: large;">{{errorMsg}}</p>
        <button routerLink="/auth/loginemail" class="btn btn-primary w-100 text-white">Back To Login</button>
    </div>
</div>