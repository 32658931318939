import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AnalyzerServiceProxy, AppVersion, GetAllSchoolsRequest, GetAllSchoolsResponseIDataResult, IResult, InsertBulkUsersInvitationBySecurlySyncDto, UserInviteSecurlySyncToListDtoPaginatedListIDataResult, UserInviteServiceProxy } from '@app/shared/service-proxies/service-proxies';
import { Subject, debounce, debounceTime, skip, takeUntil } from 'rxjs';
import * as reducers from '../../../../reducers';
import { Store } from '@ngrx/store';
import { createBulkInviteBySecurlySync, loadAdminsByCustomer, loadSchoolList } from '@app/invites/invites.actions';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
@Component({
  selector: 'app-securly-invite-list',
  templateUrl: './securly-invite-list.component.html',
  styleUrls: ['./securly-invite-list.component.scss']
})
export class SecurlyInviteListComponent implements OnInit {
  userInviteSecurlySync: any = [];
  checkboxvalue: boolean = false;
  btnDisable: boolean = true;
  selectedUsers: any = [];
  allChecked: boolean = false;
  displayedColumns: string[] = [
    'checkBox',
    'name',
    'email',
    'role',
    'campuses',
    'status',
  ];
  displayedColumnNames: string[] = [
    'CheckBox',
    'Name',
    'Email',
    'Role',
    'Campuses',
    'Status',
  ];
  tableName = 'user-invite-list';
  resetColumnOrder = [
    { id: 'checkBox', name: 'CheckBox', hidden: false, disable: false },
    { id: 'name', name: 'Name', hidden: false, disable: false },
    { id: 'email', name: 'Email', hidden: false, disable: false },
    { id: 'role', name: 'Role', hidden: false, disable: false },
    { id: 'campuses', name: 'Campuses', hidden: false, disable: false },
    { id: 'status', name: 'Status', hidden: false, disable: false },
  ];

  filterSelected: number = 1;

  private searchSubject = new Subject<string>();
  private readonly debounceTimeMs = 1000;

  filterOptions = [
    { label: 'Select All', value: 1 },
    { label: 'Select Specific', value: 2 },
  ]


  paginatorConfig = {
    length: 0,
    pageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
    pageIndex: 0,
  };
  availableUsers: any[] = [];
  searchFilter: string = '';
  schoolIdsFilter: any = [];

  schoolList: any = [];
  filteredschoolList: any = [];
  schoolSearchControl = new FormControl();
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public dialogRef: MatDialogRef<SecurlyInviteListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public userInviteServiceProxy: UserInviteServiceProxy,
    private _snackBarService: SecurlySnackBarService,
    private store: Store<reducers.State>,
    private analyzerServiceProxy: AnalyzerServiceProxy) {
      
    this.getSchoolList();

    this.schoolSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredschoolList = this.schoolList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });
    this.userInviteSecurlySync = dialogData.list.map((item: any) => ({
      ...item,
      check: true
    }));
    this.availableUsers = this.userInviteSecurlySync?.filter((x: any) => x.status !== 'Accepted' && x.status !== 'Pending')
    this.selectedUsers = this.userInviteSecurlySync?.filter((x: any) => x.status !== 'Accepted' && x.status !== 'Pending')
    this.paginatorConfig.length = dialogData.totalRecCount;
  }

  ngOnInit(): void {
    this.searchSubject.pipe(debounceTime(this.debounceTimeMs)).subscribe((searchValue: any) => {
      this.performSearch(searchValue);
    });
  }
  onSearch() {
    this.searchSubject.next(this.searchFilter);
  }

  performSearch(searchValue: string) {
    this.onFilterChange();
  }

  getPaginatedsync(event?: any) {
    this.paginatorConfig.pageIndex = event ? event.pageIndex : this.paginatorConfig.pageIndex;
    this.paginatorConfig.pageSize = event ? event.pageSize : this.paginatorConfig.pageSize;
    this.store.dispatch(loadAdminsByCustomer.init({
      selectedAccountId: this.dialogData.accountId,
      pageIndex: this.paginatorConfig.pageIndex,
      pageSize: this.paginatorConfig.pageSize,
      schoolIds: this.schoolIdsFilter,
      search: this.searchFilter
    }));
    this.store.select(reducers.getAdminsByCustomer).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.success) {
        this.paginatorConfig.length = res.data.totalRecordCount;
        this.userInviteSecurlySync = res.data.datas.map((item: any) => ({
          ...item,
        }));

        // this.userInviteSecurlySync = res.data.datas;
        if (this.userInviteSecurlySync) {
          if (this.filterSelected == 1) {
            this.userInviteSecurlySync?.forEach((element: any) => {
              element.check = true
              if (element.status != 'Pending' && element?.status != 'Accepted') {
                let indexOAU = this.availableUsers.findIndex((x: any) => x.sourceId == element.sourceId);
                if (indexOAU == -1) {
                  this.availableUsers.push(element);
                }

                let index = this.selectedUsers.findIndex((x: any) => x.sourceId == element.sourceId);
                if (index == -1) {
                  this.selectedUsers.push(element);
                }
              }
            });
          } else {
            this.userInviteSecurlySync?.forEach((element: any) => {
              if (element.status != 'Pending' && element?.status != 'Accepted') {
                let indexOAU = this.availableUsers.findIndex((x: any) => x.sourceId == element.sourceId);
                if (indexOAU == -1) {
                  this.availableUsers.push(element);
                }
              }
              let index = this.selectedUsers.findIndex((x: any) => x.sourceId == element.sourceId);
              if (index > -1) {
                element.check = true
              } else {
                if (element.status == 'Pending' || element?.status == 'Accepted') {
                  element.check = true
                } else {
                  element.check = false
                }
              }

            });
          }

        }
      }
    });
  }

  onCheckBoxClick(rowData: any) {
    rowData.check = !rowData.check;
    this.btnDisable = true;
    if (rowData.check) {
      if (!this.isKeyPresent(this.selectedUsers, rowData.sourceId)) {
        this.selectedUsers.push(rowData);
      }
    } else {
      this.filterSelected = 2
      this.selectedUsers = this.selectedUsers.filter((obj: any) => obj.sourceId !== rowData.sourceId);
    }
    if (this.selectedUsers.length == this.availableUsers.length) {
      this.filterSelected = 1
    }
  }

  isKeyPresent(arr: any, key: any) {
    return arr.some((obj: any) => obj.sourceId === key);
  }

  sendInvite() {
    // Key to be removed
    let keyToRemove = 'check';
    // Using map() to remove the key from each object
    let modifiedArray = this.selectedUsers.map((obj: any) => {
      // Destructure the object, and remove the specified key
      const { [keyToRemove]: removedKey, ...rest } = obj;
      return rest; // Return object without the specified key
    });

    let body = {
      accountId: this.dialogData.accountId,
      isForAllUsers: this.filterSelected == 1 ? true : false,
      userInvites: modifiedArray,
      orgsFilter: this.schoolIdsFilter,
      searchFilter: this.searchFilter,
      appVersion:AppVersion.AppBaseUrlV2
    } as InsertBulkUsersInvitationBySecurlySyncDto;

    this.store.dispatch(createBulkInviteBySecurlySync.init({ ...body }));
    this.store.select(reducers.createBulkUsersInviteBySecurlySync).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: IResult) => {
      if (res.success) {
        this._snackBarService.open(res.message, SecurlySnackBarType.Success);
        this.dialogRef.close({
          clicked: 'submit',
        });
      } else {
        this._snackBarService.open(res.message, SecurlySnackBarType.Failure);
      }
    });
  }

  onClose() {
    this.dialogRef.close();
  }

  // onAllClick() { // not impacted
  //   this.allChecked = !this.allChecked;
  //   if (this.allChecked) {
  //     this.selectedUsers = this.userInviteSecurlySync;
  //     this.userInviteSecurlySync.forEach((element: any) => {
  //       element.check = true
  //     })
  //   } else {
  //     this.selectedUsers = [];
  //     this.userInviteSecurlySync.forEach((element: any) => {
  //       if (element.status == 'Pending' || element?.status == 'Accepted') {
  //         element.check = true
  //       } else {
  //         element.check = false
  //       }

  //     })
  //   }
  // }


  onFilterSelection(val: any) {
    this.selectedUsers = [];
    if (val == 1) {
      this.userInviteSecurlySync.forEach((element: any) => {
        element.check = true
      })
      this.selectedUsers = this.userInviteSecurlySync?.filter((x: any) => x.status !== 'Accepted' && x.status !== 'Pending')
    } else {
      this.userInviteSecurlySync.forEach((element: any) => {
        if (element.status == 'Pending' || element?.status == 'Accepted') {
          element.check = true
        } else {
          element.check = false
        }
      })
    }

  }

  getSchoolList() {
    let payload = {
      selectedAccountId: this.dialogData.accountId
    } as GetAllSchoolsRequest

    this.store.dispatch(loadSchoolList.init({ ...payload }));
    this.store.select(reducers.getSchoolsList).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.success) {
        this.schoolList = res.data.schools;
        this.filteredschoolList = res.data.schools;
      }
    });
  }


  onFilterChange() {
    let timeoutId = null;
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      this.selectedUsers = [];
      this.getPaginatedsync();
    }, 1000);
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
