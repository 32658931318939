import { loadColumnSettings,resetColumnSettings, resetTable} from "./app.actions";

export interface State {
  columnSettings: any[];
  resetColumnStatus: boolean;
  resetTable: boolean;
 
}

const initialState: State = {
  columnSettings: [],
  resetTable: false,
  resetColumnStatus: false,
 
};

// tslint:disable-next-line: typedef
export function reducer(state = initialState, action: any): State {
  let stateToReturn = state; // default

  switch (action.type) {
    case loadColumnSettings.success.type: {
      return Object.assign({}, state, {
        columnSettings: action.body,
      });
    }
    case loadColumnSettings.init.type: { }
    case loadColumnSettings.failure.type: {
      return Object.assign({}, state, {
        columnSettings: [],
      });
    }
    case resetColumnSettings.init.type: {
      return Object.assign({}, state, {
        resetColumnStatus: false,
      });
    }
    case resetColumnSettings.success.type: {
      return Object.assign({}, state, {
        resetColumnStatus: true,
      });
    }
    case resetTable.type: {

      return Object.assign({}, state, {
        resetTable: true,
      });
    }
    
    default:
      stateToReturn
      break;
  }
  return stateToReturn;

}
export const getGridColumnSettings = (state: State) => state.columnSettings;
export const getResetGridColumnSettings = (state: State) => state.resetColumnStatus;
export const getResetTable = (state: State) => state.resetTable;
