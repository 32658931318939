import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { checkAnalysisRequestExist, loadAllStudentsAsPaginated, loadFrameworkDropdown, loadIntervalRecordsSummary, loadKeywordSummary, loadPromptPreview, submitFrameworkRequest } from "./analysis-builder.action";
import { AnalysisRequestServiceProxy, AnalyzerServiceProxy, ClinicalFrameworkServiceProxy } from "@app/shared/service-proxies/service-proxies";

@Injectable()
export class AnalysisBuilderEffects {
    constructor(
        private actions$: Actions,
        private _clinicalFrameworkService: ClinicalFrameworkServiceProxy,
        private analyzerService: AnalyzerServiceProxy,
        private analysisRequestServiceProxy: AnalysisRequestServiceProxy,
    ) { }
    loadFrameworkDropdown$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadFrameworkDropdown.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.getAll().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadFrameworkDropdown.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Framworks.',
                            errorMapping: {},
                            failureAction: loadFrameworkDropdown.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Framworks.',
                        })
                    })
                )
            })
        )
    })

    loadAllStudentsAsPaginated$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllStudentsAsPaginated.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.analyzerService.getAllStudentsAsPaginated(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllStudentsAsPaginated.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Students.',
                            errorMapping: {},
                            failureAction: loadAllStudentsAsPaginated.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Students.',
                        })
                    })
                )
            })
        )
    })


    loadKeywordSummary$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadKeywordSummary.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.analyzerService.getKeywordsSummary(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadKeywordSummary.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Keyword Summary.',
                            errorMapping: {},
                            failureAction: loadKeywordSummary.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Keyword Summary.',
                        })
                    })
                )
            })
        )
    })



    loadIntervalRecordsSummary$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadIntervalRecordsSummary.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.analyzerService.getIntervalRecordsSummary(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadIntervalRecordsSummary.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Interval Records Summary.',
                            errorMapping: {},
                            failureAction: loadIntervalRecordsSummary.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Interval Records Summary.',
                        })
                    })
                )
            })
        )
    })


    loadPromptPreview$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadPromptPreview.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.getPromptPreview(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadPromptPreview.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Prompt Preview.',
                            errorMapping: {},
                            failureAction: loadPromptPreview.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to get Prompt Preview.',
                        })
                    })
                )
            })
        )
    })


    checkAnalysisRequestExist$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(checkAnalysisRequestExist.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.analysisRequestServiceProxy.isAnalysisRequestExist(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return checkAnalysisRequestExist.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Request.',
                            errorMapping: {},
                            failureAction: checkAnalysisRequestExist.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission toget Analysis Request.',
                        })
                    })
                )
            })
        )
    })


    submitFrameworkRequest$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(submitFrameworkRequest.init),
            map((action: any) => action),
    
            mergeMap((payload) => {
                return this.analyzerService.submitFrameworkRequest(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return submitFrameworkRequest.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Framework Request.',
                            errorMapping: {},
                            failureAction: submitFrameworkRequest.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to get Framework Request.',
                        })
                    })
                )
            })
        )
    })


}




