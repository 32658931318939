import { ClinicalFrameworkToListDtoListIDataResult, PrimaryViewDataByVariableDtoListIDataResult } from "@app/shared/service-proxies/service-proxies";
import { checkAnalysisRequestExist, loadAllStudentsAsPaginated, loadFrameworkDropdown, loadIntervalRecordsSummary, loadKeywordSummary, loadPromptPreview, submitFrameworkRequest } from "./analysis-builder.action";

export interface State {
    frameworkDropdownListPayload: any;
    frameworkDropdownList: ClinicalFrameworkToListDtoListIDataResult;
    getAllStudentsAsPaginatedPayload: any;
    getAllStudentsAsPaginated: any
    getAllKeywordSummaryPayload: any;
    getAllKeywordSummary: any;
    getInternalRecordsSummaryPayload: any;
    getInternalRecordsSummary: any
    getPromptPreviewPayload: any;
    getPromptPreview: any;
    isAnalysisRequestExistPayload: any;
    isAnalysisRequestExist: any;
    frameworkRequestPayload: any;
    frameworkRequest: any;

}

const initialState: State = {
    frameworkDropdownListPayload: null,
    frameworkDropdownList: null,
    getAllStudentsAsPaginatedPayload: null,
    getAllStudentsAsPaginated: null,
    getAllKeywordSummaryPayload: null,
    getAllKeywordSummary: null,
    getInternalRecordsSummaryPayload: null,
    getInternalRecordsSummary: null,
    getPromptPreviewPayload: null,
    getPromptPreview: null,
    isAnalysisRequestExistPayload: null,
    isAnalysisRequestExist: null,
    frameworkRequestPayload: null,
    frameworkRequest: null,
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        //  Framework dropdown list
        case loadFrameworkDropdown.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                frameworkDropdownListPayload: requestPayload,
            });
        }
        case loadFrameworkDropdown.success.type: {

            return Object.assign({}, state, {
                frameworkDropdownList: action,
            })
        }

        // ----------------- load All Students AsPaginated ---------------//

        case loadAllStudentsAsPaginated.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getAllStudentsAsPaginatedPayload: requestPayload,
            });
        }
        case loadAllStudentsAsPaginated.success.type: {

            return Object.assign({}, state, {
                getAllStudentsAsPaginated: action,
            })
        }


        // ----------------- load All Keyword Summary ---------------//

        case loadKeywordSummary.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getAllKeywordSummaryPayload: requestPayload,
            });
        }
        case loadKeywordSummary.success.type: {

            return Object.assign({}, state, {
                getAllKeywordSummary: action,
            })
        }

        // -----------------  load Interval Records Summary---------------//

        case loadIntervalRecordsSummary.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getInternalRecordsSummaryPayload: requestPayload,
            });
        }
        case loadIntervalRecordsSummary.success.type: {

            return Object.assign({}, state, {
                getInternalRecordsSummary: action,
            })
        }

        // -----------------   load Prompt Preview---------------//

        case loadPromptPreview.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getPromptPreviewPayload: requestPayload,
            });
        }
        case loadPromptPreview.success.type: {

            return Object.assign({}, state, {
                getPromptPreview: action,
            })
        }


        // -----------------   is Analysis Request Exist---------------//

        case checkAnalysisRequestExist.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                isAnalysisRequestExistPayload: requestPayload,
            });
        }
        case checkAnalysisRequestExist.success.type: {

            return Object.assign({}, state, {
                isAnalysisRequestExist: action,
            })
        }


        // -----------------   submit Framework Request ------------------//

        case submitFrameworkRequest.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                frameworkRequestPayload: requestPayload,
            });
        }
        case submitFrameworkRequest.success.type: {

            return Object.assign({}, state, {
                frameworkRequest: action,
            })
        }



        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getFrameworkDropdown = (state: State) => state.frameworkDropdownList;
export const getAllStudentsAsPaginated = (state: State) => state.getAllStudentsAsPaginated;
export const getAllKeywordSummary = (state: State) => state.getAllKeywordSummary;
export const getInternalRecordsSummary = (state: State) => state.getInternalRecordsSummary;
export const getPromptPreview = (state: State) => state.getPromptPreview;
export const isAnalysisRequestExist = (state: State) => state.isAnalysisRequestExist;
export const frameworkRequest = (state: State) => state.frameworkRequest;

