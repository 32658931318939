import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountServiceProxy, IResult, ResetPasswordDto } from '@app/shared/service-proxies/service-proxies';
import * as reducers from '../../reducers';
import { Subject, skip, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
import { resetPassword, validateResetPasswordLink } from '../auth.actions';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {

  hide = true;
  token: any;
  email: any;
  isLoading: boolean = false;
  resetPasswordForm: any;
  isTokenValid: boolean = undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorMsg:string = ''
  constructor(
    public activatedroute: ActivatedRoute,
    public accountServiceProxy: AccountServiceProxy,
    public router: Router,
    private formbuilder: FormBuilder,
    private store: Store<reducers.State>,
    private _snackBarService: SecurlySnackBarService,
  ) {
    this.initform();
    this.store.select(reducers.validateUserResetPasswordLink).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: IResult) => {
      this.isTokenValid = res.success;
      this.errorMsg = res.message;

    })

    this.activatedroute.queryParams.subscribe((params: any) => {
      this.token = encodeURIComponent(params['token']);
      this.email = params['Email']
      this.store.dispatch(validateResetPasswordLink.init({ email: this.email, code: this.token }));
    });
  }

  initform() {
    this.resetPasswordForm = this.formbuilder.group({
      newpassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+~`|}{[\]:;?><,./-=])[0-9a-zA-Z!@#$%^&*()_+~`|}{[\]:;?><,./-=]{8,}$/)]),
      confirmpassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+~`|}{[\]:;?><,./-=])[0-9a-zA-Z!@#$%^&*()_+~`|}{[\]:;?><,./-=]{8,}$/)]),

    },
      {
        validators: this.checkPasswords
      }

    );
  }

  get f() {
    return this.resetPasswordForm.controls;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls['newpassword'].value;
    const confirmPass = group.controls['confirmpassword'].value;

    return pass === confirmPass ? null : { notSame: true };
  }
  generatePassword() {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890#?!@$%^&*-";
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let password = "";
    do {
      password = "";
      for (let i = 0; i < 8; i++) {
        password += chars[Math.floor(Math.random() * chars.length)];
      }
    } while (!regex.test(password));
    return password;
  }
  autoGeneratePassword() {
    const password = this.generatePassword();
    this.f['newpassword'].setValue(password);
    this.f['confirmpassword'].setValue(password);
  }

  saveNewPassword() {
    if (this.resetPasswordForm.controls['newpassword'].value == this.resetPasswordForm.controls['confirmpassword'].value) {
      let body = {
        email: this.email,
        verificationCode: this.token,
        password: this.resetPasswordForm.controls['newpassword'].value,
        passwordConfirmation: this.resetPasswordForm.controls['confirmpassword'].value
      } as ResetPasswordDto
      this.store.dispatch(resetPassword.init({ ...body }));
      this.store.select(reducers.userResetPassword).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.router.navigate(['auth/reset-password/confirmed'], { state: { message: 'Your password has been changed successfully.' } });
          // this._snackBarService.open(res.message, SecurlySnackBarType.Success)
        } else {
          this.isLoading = false;
          this._snackBarService.open(res.message, SecurlySnackBarType.Failure)
        }
      });
    }


  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
