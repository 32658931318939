import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";

export const loadUserInviteList = createAsyncAction(
    '[Invites] Load User Invite List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadUserRoles = createAsyncAction(
    '[Invites] Load UserRoles List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAccountList = createAsyncAction(
    '[Invites] Load Account List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadSchoolList = createAsyncAction(
    '[Invites] Load School List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const createUserInvitationsByCsv = createAsyncAction(
    '[Invites] Create User Invitations By Csv',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const createUserInvite = createAsyncAction(
    '[Invites] Create User Invite',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const downloadSampleFile = createAsyncAction(
    '[Invites] Download Sample File',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const revokeInvite = createAsyncAction(
    '[Invites] Revoke Invite',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const resendInvite = createAsyncAction(
    '[Invites] Resend Invite',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAdminsByCustomer = createAsyncAction(
    '[Invites] Load Admins By Customer',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const createBulkInviteBySecurlySync = createAsyncAction(
    '[Invites] Create Bulk Invite By Securly Sync',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
