import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomizerSettingsService } from '@app/shared/services/customizer-settings.service';
import { FileUploadService } from '@app/shared/services/file-upload.service';
import { UserService } from '@app/shared/services/user.service';
import { ERole } from 'src/assets/enums/profileEnum';
import * as reducers from '../../../../reducers';
import { Store } from '@ngrx/store';
import { createUserInvitationsByCsv, downloadSampleFile, loadAccountList, loadSchoolList } from '../../../../invites/invites.actions';
import { Subject, skip, takeUntil } from 'rxjs';
import { AppVersion, GetAllSchoolsRequest, IResult, StringSuccessDataResult, UserInvitationCsvDto } from '@app/shared/service-proxies/service-proxies';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
import { AppConsts } from '@app/shared/AppConsts';
@Component({
  selector: 'app-create-bulk-user-invite',
  templateUrl: './create-bulk-user-invite.component.html',
  styleUrls: ['./create-bulk-user-invite.component.scss']
})
export class CreateBulkUserInviteComponent implements OnInit {
  customerTextId: string;
  accountId: number;
  fileName: string;
  files: File[] = [];
  accountsList: any = [];
  selectedAccount: any = null;
  filteredaccountsList: any = [];
  customerSearchControl = new FormControl();
  selectedRoleId: any;
  eRole = ERole;
  confirmBtnDisable: boolean = true

  schoolIds: any = [];
  schoolSearchControl = new FormControl();
  filteredschoolList: any = [];
  schoolList: any = [];
  isCsvContainCampusAdminUser: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  async onSelect(event: any) {

    this.files = [];
    this.files.push(...event.addedFiles);
    this.fileUploadService.uploadFile(this.files[0], '/api/File/UploadFileToCloud').subscribe((res: any) => {
      if (res.body.success) {
        this.fileName = res.body.data.fileName;
        AppConsts.uploadRequest = false;
        this.checkForEnable();
      } else {
        AppConsts.uploadRequest = false;
      }
    }, error => {
      AppConsts.uploadRequest = false;
    })

  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  constructor(
    public themeService: CustomizerSettingsService,
    private fileUploadService: FileUploadService,
    private store: Store<reducers.State>,
    private _snackBarService: SecurlySnackBarService,
    public userService: UserService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialogRef: MatDialogRef<CreateBulkUserInviteComponent>
  ) {

    this.customerSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredaccountsList = this.accountsList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });

    this.schoolSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredschoolList = this.schoolList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });

    if (!dialogData.fromAccountPage && this.userService.user?.role?.name == this.eRole.ADMIN) {

      this.getAccountsList()
    }





  }

  ngOnInit(): void {

    this.customerTextId = this.dialogData.customerTextId;
    this.accountId = this.dialogData.accountId;

    this.checkForEnable();

    if (this.customerTextId) {
      this.getSchoolList()
    }

  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  onSave() {

    let body = {
      fileName: this.fileName,
      customerTextId: this.customerTextId,
      accountId: this.accountId,
      schoolIds: this.schoolIds,
      isCsvContainCampusAdminUser: this.isCsvContainCampusAdminUser,
      appVersion: AppVersion.AppBaseUrlV2
    } as UserInvitationCsvDto;
    this.store.dispatch(createUserInvitationsByCsv.init({ ...body }));
    this.store.select(reducers.createUserInvitationByCsv).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: IResult) => {
      if (res.success) {
        this.dialogRef.close()
        this._snackBarService.open("Successfully created.", SecurlySnackBarType.Success);
      } else {
        this._snackBarService.open(res.message, SecurlySnackBarType.Failure);
      }
    });
  }


  downloadSampleFile() {
    this.store.dispatch(downloadSampleFile.init({}));
    this.store.select(reducers.getDemoCSVFile).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: StringSuccessDataResult) => {
      if (res.success) {
        window.open(res.data, '_blank')
      }
    });
  }

  getAccountsList() {
    this.store.dispatch(loadAccountList.init({ isForSecurlySync: false }));
    this.store.select(reducers.getinviteAccountList).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.data) {
        this.accountsList = res.data;
        this.filteredaccountsList = this.accountsList;
      }
    });
  }

  onAccountSelected() {
    this.accountId = this.selectedAccount.id;
    this.customerTextId = this.selectedAccount.customerEmail;
    this.checkForEnable();
    if (this.customerTextId) {
      this.getSchoolList()
    }
  }

  checkForEnable() {
    if (this.accountId && this.fileName && this.customerTextId) {
      if (this.isCsvContainCampusAdminUser) {
        if (this.schoolIds.length > 0) {
          this.confirmBtnDisable = false;
        } else {
          this.confirmBtnDisable = true;
        }
      } else {
        this.confirmBtnDisable = false;
      }

    } else {
      this.confirmBtnDisable = true
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  getSchoolList() {
    let payload = {
      selectedAccountId: this.accountId,
    } as GetAllSchoolsRequest

    this.store.dispatch(loadSchoolList.init({ ...payload }));
    this.store.select(reducers.getSchoolsList).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res) => {
      if (res.success) {
        this.schoolList = res.data.schools;
        this.filteredschoolList = res.data.schools;
      }
    })
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
