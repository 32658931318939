<div>
  <div class="heading-wrapper">
    <div class="heading-text">
      <h4 class="mb-0">SIS Invite</h4>
    </div>
    <div class="modal-close-btn ">
      <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove"></i></span>
    </div>
  </div>
  <mat-dialog-content *ngIf="this.userService.user?.role?.name === eRole.ADMIN && !(dialogData.fromAccountPage)">
    <div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="form-group without-icon">
          <label class="d-block mb-12 fw-semibold gray-color">Account</label>

          <mat-select panelClass="custom-search-select" class="form-control custom-mat-select"
            [(ngModel)]="selectedAccount" name="selectedAccount" (selectionChange)="onAccountChange()">

            <mat-option>
              <ngx-mat-select-search [formControl]="selectedAccountSearchControl"
                placeholderLabel="Search"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngIf="filteredaccountsList?.length === 0" [value]="'no-entry'" disabled>
              No Entries Found
            </mat-option>
            <mat-option *ngFor="let data of filteredaccountsList" [value]="data">{{data.name}}</mat-option>
          </mat-select>

        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="btn-wrapper">
    <button [ngClass]="dialogData.fromAccountPage ? 'btn btn-primary w-100 text-white' : 'button small btn-primary'"
      (click)="sync()" [disabled]="confirmBtnDisable">
      Sync Users
    </button>
  </div>

</div>