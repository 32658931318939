import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { SuccessSnackbarComponent } from './success-snackbar.component';
import { FailureSnackbarComponent } from './failure-snackbar.component';
import { AngularMaterialModule } from './angular-material.module';
import { PaginationComponent } from './components/pagination.component';
import { LoadingIndicatorComponent } from './components/loading-indicator.component';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { VaMatTableComponent } from './components/va-mat-table.component';
import { ColumnSorterService } from './directives/column-sorter.service';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { SaveAndCancelBannerComponent } from './components/save-and-cancel-banner/save-and-cancel-banner.component';
import { SpyAnchorDirective } from './directives/spy-anchor.directive';
import { SpyAreaForDirective } from './directives/spy-area-for.directive';
import { SpyScrollContainerDirective } from './directives/spy-scroll-container.directive';
import { StickyDirective } from './directives/sticky.directive';
import { DefaultFaviconDirective } from './directives/default-favicon.directive';
import { ConvertCsvPipe } from './pipes/convert-csv.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { ChatsComponent } from './components/chats/chats.component';
import { FeatureUnavailableModalComponent } from './components/feature-unavailable-modal/feature-unavailable-modal.component';
import { PasswordChangeModalComponent } from './components/password-change-modal/password-change-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConcurrentLoginModalComponent } from './components/concurrent-login-modal/concurrent-login-modal.component';
import { YesNoGeneralModalComponent } from './components/yes-no-general-modal/yes-no-general-modal.component';
import { DisableTooltipDirective } from './directives/disable-tooltip.directive';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomDropdownComponent } from './custom-dropdown.component';
import { SorterSelectComponent } from './components/sorter-select/sorter-select.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SelectAllDirective } from './directives/select-all.directive';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TreeModule } from 'primeng/tree';
import { TreeSelectModule } from 'primeng/treeselect';
import { DropdownModule } from 'primeng/dropdown';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SecurlyButtonModule } from '@securly-engineering/securly-styles';
import { AccountServiceProxy, AnalysisRecurringRequestLogServiceProxy, AnalysisRecurringRequestServiceProxy, AnalysisRequestServiceProxy, AnalyzerServiceProxy, AssistantChatSessionServiceProxy, AuthServiceProxy, ClinicalFrameworkServiceProxy, CohortServiceProxy, DashboardDataVizReportServiceProxy, DashboardReportServiceProxy, DiscernAssistantServiceProxy, FileServiceProxy, PlanServiceProxy, PusherCachingServiceProxy, RoleServiceProxy, UserInviteServiceProxy, UserServiceProxy } from './service-proxies/service-proxies';
import { RealTimeUpdatePusherService } from './common/session/real-time-update/real-time-update-pusher.service';
import { DeleteConfirmationComponent } from './components/delete-confirmation/delete-confirmation.component';
import { ChangePasswordComponent } from './components/modals/change-password/change-password.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CreateBulkUserInviteComponent } from './components/modals/create-bulk-user-invite/create-bulk-user-invite.component';
import { SecurlyInvitePopupComponent } from './components/modals/securly-invite-popup/securly-invite-popup.component';
import { SecurlyInviteListComponent } from './components/modals/securly-invite-list/securly-invite-list.component';
import { CreateUserInviteComponent } from './components/modals/create-user-invite/create-user-invite.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { UserService } from './services/user.service';
import { ViewLogsComponent } from './components/modals/view-logs/view-logs.component';
import { ConfirmationDialogComponent } from './components/modals/confirmation-dialog/confirmation-dialog.component';
import { CustomMessageComponent } from './components/modals/custom-message/custom-message.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { RoundPipe } from './pipes/round.pipe';
import { CreateCohortComponent } from './components/modals/create-cohort/create-cohort.component';
import { ExistingCohortComponent } from './components/modals/existing-cohort/existing-cohort.component';
import { CreateCohortScreeningComponent } from './components/modals/create-cohort-screening/create-cohort-screening.component';
import { ExistingCohortScreeningComponent } from './components/modals/existing-cohort-screening/existing-cohort-screening.component';


@NgModule({
  imports: [
    CommonModule,
    MatTabsModule,
    RouterModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    Ng2SearchPipeModule,
    TreeModule,
    TreeSelectModule,
    DropdownModule,
    SecurlyButtonModule,
    NgxMatSelectSearchModule,
    NgxDropzoneModule,
    NgxUiLoaderModule,

  ],
  declarations: [
    SuccessSnackbarComponent,
    FailureSnackbarComponent,
    PaginationComponent,
    LoadingIndicatorComponent,
    ResizeColumnDirective,
    SelectAllDirective,
    VaMatTableComponent,
    CustomDropdownComponent,
    SaveAndCancelBannerComponent,
    SpyScrollContainerDirective,
    SpyAreaForDirective,
    SpyAnchorDirective,
    StickyDirective,
    DefaultFaviconDirective,
    DisableTooltipDirective,
    ConvertCsvPipe,
    FilterPipe,
    ChatsComponent,
    FeatureUnavailableModalComponent,
    ConcurrentLoginModalComponent,
    PasswordChangeModalComponent,
    ButtonToggleComponent,
    YesNoGeneralModalComponent,
    DateRangePickerComponent,
    SorterSelectComponent,
    DeleteConfirmationComponent,
    ChangePasswordComponent,
    CreateBulkUserInviteComponent,
    SecurlyInviteListComponent,
    SecurlyInvitePopupComponent,
    CreateUserInviteComponent,
    NotFoundComponent,
    ViewLogsComponent,
    ConfirmationDialogComponent,
    CustomMessageComponent,
    RoundPipe,
    CreateCohortComponent,
    ExistingCohortComponent,
    CreateCohortScreeningComponent,
    ExistingCohortScreeningComponent
  ],
  exports: [
    CustomDropdownComponent,
    SecurlyInviteListComponent,
    SecurlyInvitePopupComponent,
    SuccessSnackbarComponent,
    FailureSnackbarComponent,
    AngularMaterialModule,
    PaginationComponent,
    LoadingIndicatorComponent,
    DisableTooltipDirective,
    ResizeColumnDirective,
    SelectAllDirective,
    VaMatTableComponent,
    DateRangePickerComponent,
    SaveAndCancelBannerComponent,
    SpyScrollContainerDirective,
    SpyAreaForDirective,
    SpyAnchorDirective,
    ConvertCsvPipe,
    FilterPipe,
    ChatsComponent,
    ButtonToggleComponent,
    SorterSelectComponent,
    NgbModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    TreeModule,
    TreeSelectModule,
    DropdownModule,
    NgxDropzoneModule,
    DeleteConfirmationComponent,
    CreateBulkUserInviteComponent,
    SecurlyButtonModule,
    ChangePasswordComponent,
    NgxMatSelectSearchModule,
    CreateUserInviteComponent,
    NotFoundComponent,
    ViewLogsComponent,
    ConfirmationDialogComponent,
    CustomMessageComponent,
    NgxUiLoaderModule,
    RoundPipe,
    CreateCohortComponent,
    ExistingCohortComponent,
    CreateCohortScreeningComponent,
    ExistingCohortScreeningComponent
  ],
  providers: [
    ColumnSorterService,
    DatePipe,
    AnalyzerServiceProxy,
    DiscernAssistantServiceProxy,
    AuthServiceProxy,
    ClinicalFrameworkServiceProxy,
    RoleServiceProxy,
    UserServiceProxy,
    PusherCachingServiceProxy,
    AnalysisRequestServiceProxy,
    RealTimeUpdatePusherService,
    AccountServiceProxy,
    UserInviteServiceProxy,
    AnalysisRecurringRequestServiceProxy,
    AnalysisRecurringRequestLogServiceProxy,
    DashboardReportServiceProxy,
    PlanServiceProxy,
    AssistantChatSessionServiceProxy,
    FileServiceProxy,
    DashboardDataVizReportServiceProxy,
    CohortServiceProxy
    
  ],
})
export class SharedModule { }
