<div>

  <div class="heading-wrapper">
    <div class="heading-text">
      <h4 class="mb-0">Create User Invite</h4>
    </div>
    <div class="modal-close-btn ">
      <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove"></i></span>
    </div>
  </div>
  <div class="user-form-wrapper">
    <form [formGroup]="createInivteForm">
      <div class="row">
        <div class="col-6">

          <div class="form-group">
            <label class="label-text" for="firstName"> First Name <span class="required-star">*</span> </label>
            <input type="text" class="form-control" name="firstName" formControlName="firstName" id="firstName"
              aria-describedby="desc" placeholder="Enter First Name" />
          </div>
          <div class="error" *ngIf="f['firstName'].invalid && f['firstName'].touched">
            <div class="display" *ngIf="f['firstName'].errors && f['firstName'].errors['required']">
              <p>First Name is required </p>
            </div>
            <div class="display" *ngIf="f['firstName'].errors && f['firstName'].errors['pattern']">
              <p>Enter an correct First Name.</p>
            </div>
          </div>

        </div>
        <div class=" col-6">
          <div class="form-group">
            <label class="label-text" for="lastName"> Last Name<span class="required-star">*</span> </label>
            <input type="text" class="form-control" name="lastName" formControlName="lastName" id="lastName"
              aria-describedby="desc" placeholder="Enter Last Name" />
          </div>
          <div class="error" *ngIf="f['lastName'].invalid && f['lastName'].touched">
            <div class="display" *ngIf="f['lastName'].errors && f['lastName'].errors['required']">
              <p>Last Name is required</p>
            </div>
            <div class="display" *ngIf="f['lastName'].errors && f['lastName'].errors['pattern']">
              <p>Enter an correct Last Name.</p>
            </div>
          </div>
        </div>
        <div class=" col-6">
          <div class="form-group">
            <label class="label-text" for="email"> Email <span class="required-star">*</span></label>
            <input type="text" class="form-control" name="email" formControlName="email" id="email"
              aria-describedby="desc" placeholder="Enter email" />
          </div>
          <div class="error" *ngIf="f['email'].invalid && f['email'].touched">
            <div class="display" *ngIf="f['email'].errors && f['email'].errors['required']">
              <p>Email is required</p>
            </div>
            <div class="display" *ngIf="f['email'].errors && f['email'].errors['pattern']">
              <p>Enter an correct Email.</p>
            </div>
          </div>
        </div>
        <div *ngIf="this.userService.user?.role?.key !== eRole.CampusAdmin" class=" col-6">
          <div class="form-group">
            <label class="label-text" for="desc"> Role <span class="required-star">*</span></label>
            <mat-select class="form-control custom-mat-select" name="selectedRoleId"
              (selectionChange)="onRoleSelected()" formControlName="roleId" placeholder="Select Role">
              <mat-option *ngFor="let data of roleList" [value]="data">{{data.name}}</mat-option>
            </mat-select>
            <div class="error" *ngIf="f['roleId'].invalid && f['roleId'].touched">
              <div class="display" *ngIf="f['roleId'].errors && f['roleId'].errors['required']">
                <p>Select one role is mandatory</p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class=" col-6"
          *ngIf="(f['roleId'].value?.roleId == 2 || f['roleId'].value?.roleId == 3) && this.userService.user?.role?.name == eRole.ADMIN">
          <div class="form-group">
            <label class="label-text" for="selectedAccount">Account <span class="required-star">*</span></label>
            <mat-select class="form-control custom-mat-select" panelClass="custom-search-select" name="selectedAccount"
              formControlName="accountId" placeholder="Select Role">
              <mat-option>
                <ngx-mat-select-search [formControl]="customerSearchControl"
                  placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="filteredaccountsList?.length === 0" [value]="'no-entry'" disabled>
                No Entries Found
              </mat-option>
              <mat-option (click)="onAccountChange()" *ngFor="let data of filteredaccountsList"
                [value]="data">{{data?.name}}</mat-option>
            </mat-select>
            <div class="error mt-4" *ngIf="f['accountId'].invalid && f['accountId'].touched">
              <div class="display" *ngIf="f['accountId'].errors && f['accountId'].errors['required']">
                <p>Select one account is mandatory</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class=" col-6"
          *ngIf="(f['roleId'].value?.roleId == 3) && this.userService.user?.role?.key !== eRole.CampusAdmin">
          <div class="form-group">
            <label class="label-text" for="schoolIds">Campus(es) <span class="required-star">*</span></label>
            <mat-select class="form-control custom-mat-select" panelClass="custom-search-select" name="schoolIds"
              formControlName="schoolIds" placeholder="Select Campus(es)" multiple>
              <mat-option>
                <ngx-mat-select-search [formControl]="schoolSearchControl"
                  placeholderLabel="Search"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="filteredschoolList?.length === 0" [value]="'no-entry'" disabled>
                No Entries Found
              </mat-option>
              <mat-option *ngFor="let data of filteredschoolList" [value]="data.id">
                {{ data.name }}</mat-option>
            </mat-select>
            <div class="error mt-4" *ngIf="f['schoolIds'].invalid && f['schoolIds'].touched">
              <div class="display" *ngIf="f['schoolIds'].errors && f['schoolIds'].errors['required']">
                <p>Select one account is mandatory</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="btn-wrapper">
    <button class="button small btn-primary" (click)="dialogRef.close(false)">
      Close
    </button>
    <button class="button small btn-primary" (click)="onSave()" [disabled]="createInivteForm.invalid || isLoading">
      Confirm
    </button>
  </div>
</div>