import { createAsyncAction } from "@app/util";
import { createAction, props } from "@ngrx/store";

export const loadFrameworkDropdown = createAsyncAction(
    '[analysisBuilder] Load Framework Dropdown List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAllStudentsAsPaginated = createAsyncAction(
    '[analysisBuilder] load All Students List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);


export const loadKeywordSummary = createAsyncAction(
    '[analysisBuilder] load All Keyword sSummary List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadIntervalRecordsSummary = createAsyncAction(
    '[analysisBuilder] load All Interval Records Summary' ,
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadPromptPreview = createAsyncAction(
    '[analysisBuilder] load Prompt Preview' ,
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const checkAnalysisRequestExist = createAsyncAction(
    '[analysisBuilder] check Analysis Request Exist or not' ,
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const submitFrameworkRequest = createAsyncAction(
    '[analysisBuilder] submit Framework Request' ,
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

