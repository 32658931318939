
<div class="modal-container p-0">
    <div class="heading-wrapper">
        <div class="heading-text d-flex">
            <h3 class="mb-0 s-heading align-items-center" style="color: #DCBA07;"><img
                    src="assets/warning-sign-1.svg" width="29px" />Warning</h3>
        </div>
        <div class="modal-close-btn ">
            <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove fs-6"></i></span>
        </div>
    </div>
    <div class="modalContent p-0">
        <p class="modalsubHeader">
            {{message}}
        </p>
        <div class="text-end pt-2">
            <button type="submit" class="button -primary m-0" (click)="onMessageClose()">
                Ok
            </button>
        </div>
    </div>
</div>