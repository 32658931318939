import { AccountDtoListIDataResult, IResult, PrimaryViewDataByVariableDtoListIDataResult, SchoolYearDtoListIDataResult } from "@app/shared/service-proxies/service-proxies";
import { DownloadFrameworkTypeReport, downloadStudentLevelFrameworkReport, loadAccounts, loadAnalyzedClinicalFrameWorks, loadAnalyzedClinicalFrameWorksPersonalized, loadAnalyzedClinicalFrameWorksScreening, loadDashboardMetricsView, loadGraphPrimaryViewReportByFrameworkV3, loadGraphPrimaryViewReportByFrameworkV3Personalized, loadListPrimaryViewReportByFrameworkV3, loadListPrimaryViewReportByFrameworkV3Personalized, loadPrevalenceDrillDownList, loadPrimaryViewReport, loadSchoolYearList, loadScreenCohortViewDillDownReport, loadScreenCohortViewReportByFrameworkV3, loadStudentDrillDownMeasureViz, loadStudentSummaryAndOnlineActivitiesWithDateFilter } from "./home.actions";
import { isJSDocNullableType } from "typescript";

export interface State {
    primaryViewReportListPayload: any;
    primaryViewReportList: PrimaryViewDataByVariableDtoListIDataResult;

    accountsListPayload: any;
    accountsList: AccountDtoListIDataResult;

    schoolYearListPayload: any;
    schoolYearList: SchoolYearDtoListIDataResult;

    downloadFrameworkTypeOverviewReportPayload: any;
    downloadFrameworkTypeOverviewReport: any;

    downloadStdLevelFrameworkReportPayload: any;
    downloadStdLevelFrameworkReport: any;

    loadAnalyzedClinicalFrameWorksPayload: any;
    loadAnalyzedClinicalFrameWorksReport: any;

    loadAnalyzedClinicalFrameWorksScreenPayload: any;
    loadAnalyzedClinicalFrameWorksScreenReport: any;

    loadAnalyzedClinicalFrameWorksPersonalizePayload: any;
    loadAnalyzedClinicalFrameWorksPersonalizeReport: any;

    loadGraphPrimaryViewReportByFrameworkV3Payload: any;
    loadGraphPrimaryViewReportByFrameworkV3: any;

    loadListPrimaryViewReportByFrameworkV3PersonalizedPayload: any;
    loadListPrimaryViewReportByFrameworkV3Personalized: any;

    loadGraphPrimaryViewReportByFrameworkV3PersonalizedPayload: any;
    loadGraphPrimaryViewReportByFrameworkV3Personalized: any;

    loadListPrimaryViewReportByFrameworkV3Payload: any;
    loadListPrimaryViewReportByFrameworkV3: any;

    loadDashboardMetricsViewPayload: any;
    loadDashboardMetricsView: any;

    loadScreenCohortViewReportByFrameworkV3Payload: any;
    loadScreenCohortViewReportByFrameworkV3: any;

    loadScreenCohortViewDillDownReportPayload: any;
    loadScreenCohortViewDillDownReport: any;

    studentDrillDownMeasureVizPayload: any;
    studentDrillDownMeasureViz: any;

    prevalenceDrillDownVizPayload: any;
    prevalenceDrillDownViz: any;

    studentSummaryWithDateFilterPayload:any;
    studentSummaryWithDateFilter:any;
}

const initialState: State = {
    primaryViewReportListPayload: null,
    primaryViewReportList: null,

    accountsListPayload: null,
    accountsList: null,

    schoolYearListPayload: null,
    schoolYearList: null,

    downloadFrameworkTypeOverviewReportPayload: null,
    downloadFrameworkTypeOverviewReport: null,

    downloadStdLevelFrameworkReportPayload: null,
    downloadStdLevelFrameworkReport: null,

    loadAnalyzedClinicalFrameWorksPayload: null,
    loadAnalyzedClinicalFrameWorksReport: null,

    loadAnalyzedClinicalFrameWorksScreenPayload: null,
    loadAnalyzedClinicalFrameWorksScreenReport: null,

    loadAnalyzedClinicalFrameWorksPersonalizePayload: null,
    loadAnalyzedClinicalFrameWorksPersonalizeReport: null,

    loadGraphPrimaryViewReportByFrameworkV3Payload: null,
    loadGraphPrimaryViewReportByFrameworkV3: null,

    loadListPrimaryViewReportByFrameworkV3Payload: null,
    loadListPrimaryViewReportByFrameworkV3: null,

    loadListPrimaryViewReportByFrameworkV3PersonalizedPayload: null,
    loadListPrimaryViewReportByFrameworkV3Personalized: null,

    loadGraphPrimaryViewReportByFrameworkV3PersonalizedPayload: null,
    loadGraphPrimaryViewReportByFrameworkV3Personalized: null,

    loadDashboardMetricsViewPayload: null,
    loadDashboardMetricsView: null,

    loadScreenCohortViewReportByFrameworkV3Payload: null,
    loadScreenCohortViewReportByFrameworkV3: null,

    loadScreenCohortViewDillDownReportPayload: null,
    loadScreenCohortViewDillDownReport: null,

    studentDrillDownMeasureVizPayload: null,
    studentDrillDownMeasureViz: null,

    prevalenceDrillDownVizPayload: null,
    prevalenceDrillDownViz: null,

    studentSummaryWithDateFilterPayload:null,
    studentSummaryWithDateFilter:null,

};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {
        case loadPrimaryViewReport.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                primaryViewReportListPayload: requestPayload,
            });
        }
        case loadPrimaryViewReport.success.type: {

            return Object.assign({}, state, {
                primaryViewReportList: action,
            });
        }

        case loadAccounts.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                accountsListPayload: requestPayload,
            });
        }
        case loadAccounts.success.type: {

            return Object.assign({}, state, {
                accountsList: action,
            })
        }

        case loadSchoolYearList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                schoolYearListPayload: requestPayload,
            });
        }
        case loadSchoolYearList.success.type: {

            return Object.assign({}, state, {
                schoolYearList: action,
            })
        }
        case DownloadFrameworkTypeReport.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                downloadFrameworkTypeOverviewReportPayload: requestPayload,
            });
        }
        case DownloadFrameworkTypeReport.success.type: {
            return Object.assign({}, state, {
                downloadFrameworkTypeOverviewReport: action,
            })
        }
        case downloadStudentLevelFrameworkReport.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                downloadStdLevelFrameworkReportPayload: requestPayload,
            });
        }
        case downloadStudentLevelFrameworkReport.success.type: {
            return Object.assign({}, state, {
                downloadStdLevelFrameworkReport: action,
            })
        }
        case loadAnalyzedClinicalFrameWorks.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadAnalyzedClinicalFrameWorksPayload: requestPayload,
            });
        }
        case loadAnalyzedClinicalFrameWorks.success.type: {
            return Object.assign({}, state, {
                loadAnalyzedClinicalFrameWorksReport: action,
            })
        }

        case loadAnalyzedClinicalFrameWorksPersonalized.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadAnalyzedClinicalFrameWorksPersonalizePayload: requestPayload,
            });
        }
        case loadAnalyzedClinicalFrameWorksPersonalized.success.type: {
            return Object.assign({}, state, {
                loadAnalyzedClinicalFrameWorksPersonalizeReport: action,
            })
        }

        case loadAnalyzedClinicalFrameWorksScreening.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadAnalyzedClinicalFrameWorksScreenPayload: requestPayload,
            });
        }
        case loadAnalyzedClinicalFrameWorksScreening.success.type: {
            return Object.assign({}, state, {
                loadAnalyzedClinicalFrameWorksScreenReport: action,
            })
        }

        case loadListPrimaryViewReportByFrameworkV3.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadListPrimaryViewReportByFrameworkV3Payload: requestPayload,
            });
        }
        case loadListPrimaryViewReportByFrameworkV3.success.type: {
            return Object.assign({}, state, {
                loadListPrimaryViewReportByFrameworkV3: action,
            })
        }

        case loadGraphPrimaryViewReportByFrameworkV3.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadGraphPrimaryViewReportByFrameworkV3Payload: requestPayload,
            });
        }
        case loadGraphPrimaryViewReportByFrameworkV3.success.type: {
            return Object.assign({}, state, {
                loadGraphPrimaryViewReportByFrameworkV3: action,
            })
        }


        case loadListPrimaryViewReportByFrameworkV3Personalized.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadListPrimaryViewReportByFrameworkV3PersonalizedPayload: requestPayload,
            });
        }
        case loadListPrimaryViewReportByFrameworkV3Personalized.success.type: {
            return Object.assign({}, state, {
                loadListPrimaryViewReportByFrameworkV3Personalized: action,
            })
        }

        case loadGraphPrimaryViewReportByFrameworkV3Personalized.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadGraphPrimaryViewReportByFrameworkV3PersonalizedPayload: requestPayload,
            });
        }
        case loadGraphPrimaryViewReportByFrameworkV3Personalized.success.type: {
            return Object.assign({}, state, {
                loadGraphPrimaryViewReportByFrameworkV3Personalized: action,
            })
        }


        case loadDashboardMetricsView.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadDashboardMetricsViewPayload: requestPayload,
            });
        }
        case loadDashboardMetricsView.success.type: {
            return Object.assign({}, state, {
                loadDashboardMetricsView: action,
            })
        }

        case loadScreenCohortViewReportByFrameworkV3.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadScreenCohortViewReportByFrameworkV3Payload: requestPayload,
            });
        }
        case loadScreenCohortViewReportByFrameworkV3.success.type: {
            return Object.assign({}, state, {
                loadScreenCohortViewReportByFrameworkV3: action,
            })
        }

        case loadScreenCohortViewDillDownReport.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                loadScreenCohortViewDillDownReportPayload: requestPayload,
            });
        }
        case loadScreenCohortViewDillDownReport.success.type: {
            return Object.assign({}, state, {
                loadScreenCohortViewDillDownReport: action,
            })
        }
        // Student Drill Down MeasureViz
        case loadStudentDrillDownMeasureViz.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                studentDrillDownMeasureVizPayload: requestPayload,
            });
        }
        case loadStudentDrillDownMeasureViz.success.type: {
            return Object.assign({}, state, {
                studentDrillDownMeasureViz: action,
            })
        }

        // Prevalence Drill Down
        case loadPrevalenceDrillDownList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                prevalenceDrillDownVizPayload: requestPayload,
            });
        }
        case loadPrevalenceDrillDownList.success.type: {
            return Object.assign({}, state, {
                prevalenceDrillDownViz: action,
            })
        }

        // Student Summary And Online Activities With Date Filter
        case loadStudentSummaryAndOnlineActivitiesWithDateFilter.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                studentSummaryWithDateFilterPayload: requestPayload,
            });
        }
        case loadStudentSummaryAndOnlineActivitiesWithDateFilter.success.type: {
            return Object.assign({}, state, {
                studentSummaryWithDateFilter: action,
            })
        }


        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getPrimaryViewReport = (state: State) => state.primaryViewReportList;
export const getAccountsList = (state: State) => state.accountsList;
export const getSchoolYearList = (state: State) => state.schoolYearList;
export const downloadFrameworkTypeReport = (state: State) => state.downloadFrameworkTypeOverviewReport;
export const downloadStdLevelFrameworkReports = (state: State) => state.downloadStdLevelFrameworkReport;
export const getAnalyzedClinicalFrameWorks = (state: State) => state.loadAnalyzedClinicalFrameWorksReport;
export const getAnalyzedClinicalFrameWorksPersonalize = (state: State) => state.loadAnalyzedClinicalFrameWorksPersonalizeReport;
export const getAnalyzedClinicalFrameWorksScreen = (state: State) => state.loadAnalyzedClinicalFrameWorksScreenReport;
export const getGraphPrimaryViewReportByFrameworkV3 = (state: State) => state.loadGraphPrimaryViewReportByFrameworkV3;
export const getListPrimaryViewReportByFrameworkV3 = (state: State) => state.loadListPrimaryViewReportByFrameworkV3;
export const getGraphPrimaryViewReportByFrameworkV3Personalized = (state: State) => state.loadGraphPrimaryViewReportByFrameworkV3Personalized;
export const getListPrimaryViewReportByFrameworkV3Personalized = (state: State) => state.loadListPrimaryViewReportByFrameworkV3Personalized;
export const getDashboardMetricsView = (state: State) => state.loadDashboardMetricsView;
export const getScreenCohortViewReportByFrameworkV3 = (state: State) => state.loadScreenCohortViewReportByFrameworkV3;
export const getScreenCohortViewDillDownReport = (state: State) => state.loadScreenCohortViewDillDownReport;
export const getStudentDrillDownMeasureViz = (state: State) => state.studentDrillDownMeasureViz;
export const getPrevalencetDrillDownViz = (state: State) => state.prevalenceDrillDownViz;
export const getStudentSummaryWithDateFilter = (state: State) => state.studentSummaryWithDateFilter;