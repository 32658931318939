<div *ngIf="!linkExpired && linkExpired != undefined" class="h-100vh pt-50 pb-50">
    <h3 translate></h3>
    <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
    <div class="form-style">
        <form [formGroup]="registerUserForm">

            <div class="form-group">
                <label class="d-block mb-12 fw-semibold gray-color">First Name</label>
                <input matInput class="form-control" formControlName="firstname" placeholder="Enter first name"
                    readonly>
            </div>
            <div class="form-group">
                <label class="d-block mb-12 fw-semibold gray-color">Last Name </label>
                <input matInput class="form-control" formControlName="lastname" placeholder="Enter last name" readonly>
            </div>
            <div class="form-group">
                <label class="d-block mb-12 fw-semibold gray-color">Email Address </label>
                <input matInput class="form-control" formControlName="email" placeholder="Enter email address" readonly>
            </div>
            <div class="form-group">
                <div class="icon-input-wrapper">
                    <label class="d-block mb-12 fw-semibold gray-color">Password </label>
                    <input matInput class="form-control" id="pass" name="password" formControlName="password"
                        placeholder="Enter your password" [type]="hide ? 'password' : 'text'"
                        (keydown.enter)="onEnterKeyPressed($event)">
                    <div class="mat-form-field-icon">
                        <button mat-icon-button matSuffix (click)="autoGeneratePassword()"
                            aria-label="Generate password">
                            <mat-icon>autorenew</mat-icon>
                        </button>
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="error" *ngIf="f['password'].invalid && (!f['password'].dirty && f['password'].touched)">
                    <div class="display" *ngIf="f['password'].errors && f['password'].errors['required']">
                        <p>Password is required</p>
                    </div>
                </div>
                <div class="error" *ngIf="f['password'].invalid && (f['password'].dirty || f['password'].touched)">
                    <div class="display" *ngIf="f['password'].errors && !f['password'].errors['required']">
                        <p>It must be at least 8 characters long and must contain at least one uppercase,
                            one lowercase, one digit, one special character from the set [#?!@$%^&*-].</p>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="icon-input-wrapper">

                    <label class="d-block mb-12 fw-semibold gray-color">Confirm Password </label>
                    <input matInput class="form-control" id="confirmPass" name="confirmpassword"
                        formControlName="confirmpassword" placeholder="Enter your password"
                        [type]="hide ? 'password' : 'text'">
                    <div class="mat-form-field-icon">
                        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                            [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="error"
                    *ngIf="f['confirmpassword'].invalid && (f['confirmpassword'].dirty || f['confirmpassword'].touched)">
                    <div class="display" *ngIf="f['confirmpassword'].errors && f['confirmpassword'].errors['required']">
                        <p>Confirm Password is required</p>
                    </div>
                </div>
                <div class="error"
                    *ngIf="f['confirmpassword'].invalid && (f['confirmpassword'].dirty || f['confirmpassword'].touched)">
                    <div class="display"
                        *ngIf="f['confirmpassword'].errors && !f['confirmpassword'].errors['required']">
                        <p>It must be at least 8 characters long and must contain at least one uppercase,
                            one lowercase, one digit, one special character from the set [#?!@$%^&*-].</p>
                    </div>
                </div>
                <div class="error" *ngIf="registerUserForm.hasError('notSame')">
                    <div class="display">
                        <p>Password & Confirm Password must be same</p>
                    </div>
                </div>

            </div>
            <button [disabled]="registerUserForm.invalid || isLoading" class="btn btn-primary w-100 text-white"
                (click)="register()">Accept</button>
        </form>
    </div>
</div>

<div *ngIf="linkExpired && linkExpired != undefined">
    <div class="error-massage-wrapper">
        <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
        <img src="assets/warning-sign.svg" class="img-fluid py-3" style="width:15%; padding: 0 !important;" />
        <h5 class="">Error !</h5>
        <p style="font-size: large;">{{errorMsg}}</p>
        <button routerLink="/auth/loginemail" class="btn btn-primary w-100 text-white">Back To Login</button>
    </div>
</div>