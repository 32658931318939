<div>
    <div class="heading-wrapper">
        <div class="heading-text">
            <h4 class="mb-0">Bulk User Invite</h4>
        </div>
        <div class="modal-close-btn ">
            <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove"></i></span>
        </div>
    </div>
    <mat-dialog-content>
        <div>
            <div *ngIf="!dialogData.fromAccountPage && this.userService.user?.role?.name == this.eRole.ADMIN"
                class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">Account</label>

                    <mat-select panelClass="custom-search-select" class="form-control custom-mat-select"
                        [(ngModel)]="selectedAccount" name="selectedAccount" (selectionChange)="onAccountSelected()">

                        <mat-option>
                            <ngx-mat-select-search [formControl]="customerSearchControl"
                                placeholderLabel="Search"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="filteredaccountsList?.length === 0" [value]="'no-entry'" disabled>
                            No Entries Found
                        </mat-option>
                        <mat-option *ngFor="let data of filteredaccountsList" [value]="data">{{data.name}}</mat-option>
                    </mat-select>

                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="form-group">
                    <mat-checkbox class="gray-color" (change)="checkForEnable()"
                        [(ngModel)]="isCsvContainCampusAdminUser">Is
                        for multiple roles ?</mat-checkbox>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isCsvContainCampusAdminUser">
                <div class="form-group without-icon">
                    <label class="d-block mb-12 fw-semibold gray-color">Campus(es)</label>

                    <mat-select panelClass="custom-search-select" class="form-control custom-mat-select"
                        [(ngModel)]="schoolIds" name="schoolIds" multiple (selectionChange)="checkForEnable()">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="schoolSearchControl"
                                placeholderLabel="Search"></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngIf="filteredschoolList?.length === 0" [value]="'no-entry'" disabled>
                            No Entries Found
                        </mat-option>
                        <mat-option *ngFor="let data of filteredschoolList" [value]="data.id">{{ data.name
                            }}</mat-option>
                    </mat-select>
                </div>
            </div>


            <div class="col-lg-12 col-md-12">
                <div class="card form-group">
                    <div>
                        <ngx-dropzone [multiple]="false" class="dropzone" (change)="onSelect($event)">
                            <ngx-dropzone-label class="fw-semibold">Drop files here or click to
                                upload.</ngx-dropzone-label>
                            <ngx-dropzone-preview class="dropzone-preview" *ngFor="let f of files" [removable]="true"
                                (removed)="onRemove(f)">
                                <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <u>
                    <a class="text-primary" (click)="downloadSampleFile()">
                        Download Sample .CSV File
                    </a>
                </u>
            </div>
        </div>
    </mat-dialog-content>
    <div class="btn-wrapper">
        <button class="button small btn-primary" (click)="onClose()">
            Close
        </button>
        <button class="button small btn-primary" (click)="onSave()" [disabled]="confirmBtnDisable">
            Confirm
        </button>
    </div>

</div>