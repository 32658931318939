import { AppConsts } from './shared/AppConsts';
import { Injectable, Injector } from '@angular/core';
import { PlatformLocation, registerLocaleData } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { UserServiceProxy, UserToListDto } from './shared/service-proxies/service-proxies';
import { XmlHttpRequestHelper } from './shared/helpers/XmlHttpRequestHelper';
import { UserService } from './shared/services/user.service';
import { Store } from '@ngrx/store';
import * as reducers from './../../src/app/reducers';
import { loadCustomerList } from './my-analysis/my-analysis.actions';
import { CredentialsService } from './auth/credentials.service';
@Injectable({
    providedIn: 'root',
})
export class AppInitializer {
    constructor(
        private _injector: Injector,
        private _platformLocation: PlatformLocation,
        private _httpClient: HttpClient,
        private _userServiceProxy: UserServiceProxy,
        private _credentialsService: CredentialsService,
        private _userService: UserService,
        private store: Store<reducers.State>,
    ) {

    }

    init(): () => Promise<boolean> {

        return () => {
            return new Promise<boolean>((resolve, reject) => {

                var baseUrl = window.location.origin + '/';
                const appBaseHref = this.getBaseHref(this._platformLocation);
                let appBaseUrl = this.getDocumentOrigin() + appBaseHref;
                let type = 'GET';
                let url = appBaseUrl + 'assets/' + 'appconfig.json';
                XmlHttpRequestHelper.ajax(false, type, url, null, null, (result: any) => {
                    AppConsts.appBaseUrl = result.appBaseUrl;
                    AppConsts.apiBaseUrl = result.apiBaseUrl;
                    AppConsts.gainSightKey = result.gainSightKey;
                    AppConsts.appEnvironmentName = result.appEnvironmentName;
                    if (result.securlySso != undefined) {
                        AppConsts.securlySso.baseUrl = result.securlySso.baseUrl;
                        AppConsts.securlySso.clientId = result.securlySso.clientId;
                    }

                    if (localStorage.getItem('accessToken') || (this._credentialsService.getCookie('securly_token') && this._credentialsService.getCookie('DiscernUserCookieInfo'))) {

                        if (!window.location.pathname.includes('authentication') /* && !(window.location.pathname == '/') */) {
                            this._userServiceProxy.profile().subscribe((response: any) => {
                                this._credentialsService.setCookie('DiscernUserCookieInfo', JSON.stringify(response?.data as UserToListDto));
                                this._userService.setuser(response.data);
                                resolve(true)
                            })
                        } else {

                            resolve(true)
                        }

                    } else {
                        resolve(true)
                    }

                })


            });
        };
    }
    private getBaseHref(platformLocation: PlatformLocation): string {

        const baseUrl = platformLocation.getBaseHrefFromDOM();
        if (baseUrl) {
            return baseUrl;
        }

        return '/';
    }

    private getDocumentOrigin(): string {
        if (!document.location.origin) {
            const port = document.location.port ? ':' + document.location.port : '';
            return (
                document.location.protocol + '//' + document.location.hostname + port
            );
        }

        return document.location.origin;
    }


}
