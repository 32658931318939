import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { AccountServiceProxy, AnalyzerServiceProxy, AppVersion, FileServiceProxy, RoleServiceProxy, UserInviteServiceProxy, UserServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { createBulkInviteBySecurlySync, createUserInvitationsByCsv, createUserInvite, downloadSampleFile, loadAccountList, loadAdminsByCustomer, loadSchoolList, loadUserInviteList, loadUserRoles, resendInvite, revokeInvite } from "./invites.actions";

@Injectable()
export class InvitesEffects {
    constructor(
        private actions$: Actions,
        private _userInviteServiceProxy: UserInviteServiceProxy,
        private _userServiceProxy: UserServiceProxy,
        private _roleServiceProxyMountain: RoleServiceProxy,
        private _accountServiceProxy: AccountServiceProxy,
        private _fileServiceProxy: FileServiceProxy,
        private _analyzerServiceProxy: AnalyzerServiceProxy,
    ) { }
    loadUserList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUserInviteList.init),
            map((action: any) => action),

            mergeMap((payload: any) => {
                return this._userInviteServiceProxy.paginate(
                    payload.accountId,
                    payload.pageIndex,
                    payload.pageSize,
                    payload.selectedUserRole,
                    payload.selectedUserInivte)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                return loadUserInviteList.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get all Users Invite List.',
                                errorMapping: {},
                                failureAction: loadUserInviteList.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load all Users Invite List.',
                            })
                        })
                    )
            })
        )
    })
    loadUserRoles$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUserRoles.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._roleServiceProxyMountain.getAll().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadUserRoles.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all UserRoles List.',
                            errorMapping: {},
                            failureAction: loadUserRoles.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all UserRoles List.',
                        })
                    })
                )
            })
        )
    })

    loadAccountList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAccountList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._accountServiceProxy.getAllAccounts(payload.isForSecurlySync).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAccountList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Accounts List.',
                            errorMapping: {},
                            failureAction: loadAccountList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Accounts List.',
                        })
                    })
                )
            })
        )
    })
    loadSchoolList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadSchoolList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analyzerServiceProxy.getAllSchools(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadSchoolList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get School List.',
                            errorMapping: {},
                            failureAction: loadSchoolList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all School List.',
                        })
                    })
                )
            })
        )
    })
    createUserInvitationsByCsv$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(createUserInvitationsByCsv.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userServiceProxy.uploadUserInvitationsByCsv(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return createUserInvitationsByCsv.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Create User Invitations By Csv.',
                            errorMapping: {},
                            failureAction: createUserInvitationsByCsv.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Create User Invitations By Csv.',
                        })
                    })
                )
            })
        )
    })
    createBulkInviteBySecurlySync$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(createBulkInviteBySecurlySync.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userInviteServiceProxy.insertBulkUsersInvitationBySecurlySync(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return createBulkInviteBySecurlySync.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Create Bulk Invite By Securly Sync.',
                            errorMapping: {},
                            failureAction: createBulkInviteBySecurlySync.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Create Bulk Invite By Securly Sync.',
                        })
                    })
                )
            })
        )
    })
    createUserInvite$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(createUserInvite.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userInviteServiceProxy.createUserInvite(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return createUserInvite.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Create User Invite.',
                            errorMapping: {},
                            failureAction: createUserInvite.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Create User Invite.',
                        })
                    })
                )
            })
        )
    })
    resendInvite$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(resendInvite.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userInviteServiceProxy.resendUserInvite(payload.id,AppVersion.AppBaseUrlV2).pipe(
                    map((res: any) => {
                        if (res) {
                            return resendInvite.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to resend invite.',
                            errorMapping: {},
                            failureAction: resendInvite.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to resend invite.',
                        })
                    })
                )
            })
        )
    })
    revokeInvite$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(revokeInvite.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userInviteServiceProxy.revokeUserInvite(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return revokeInvite.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to revoke invite.',
                            errorMapping: {},
                            failureAction: revokeInvite.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to revoke invite.',
                        })
                    })
                )
            })
        )
    })
    downloadSampleFile$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(downloadSampleFile.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._fileServiceProxy.downloadDemoCsv().pipe(
                    map((res: any) => {
                        if (res) {
                            return downloadSampleFile.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Download Sample File.',
                            errorMapping: {},
                            failureAction: downloadSampleFile.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Download Sample File.',
                        })
                    })
                )
            })
        )
    })
    loadAdminsByCustomer$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAdminsByCustomer.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userInviteServiceProxy.getPaginatedAdministratorsByCustomer(payload.selectedAccountId, payload.pageIndex, payload.pageSize, payload.schoolIds, payload.search).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAdminsByCustomer.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Load Admins By Customer.',
                            errorMapping: {},
                            failureAction: loadAdminsByCustomer.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Load Admins By Customer.',
                        })
                    })
                )
            })
        )
    })
}