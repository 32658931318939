
export enum PusherUserChannelEventEnum {
    OnMessageReceived = "user-on-message-received",
}

export enum PusherAppChannelEventEnum {
    RefreshAppNow = "app-refresh-app-now"
}

export enum PusherRequestChannelEventEnum {
    OnProgressReceived = "request-on-progress-received"
}

export enum PusherChatChannelEventEnum {
    OnMessageReceived = "chat-on-message-received",
}