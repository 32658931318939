export const icons = [
  { name: 'add-icon', url: './../assets/scss/icons/icon-add.svg' },
  { name: 'list-icon', url: '/assets/scss/icons/icon-listview.svg' },
  { name: 'grid-icon', url: '/assets/scss/icons/icon-gridview.svg' },
  {
    name: 'back-arrow-icon',
    url: './.ssets/scss/icons/icon-go-back-arrow.svg',
  },
  { name: 'info-icon', url: './../assets/scss/icons/icon-info.svg' },
  { name: 'more-icon', url: '/assets/scss/icons/icon-more.svg' },
  {
    name: 'site-color',
    url: './../assets/scss/icons/icon-24-px-site-color.svg',
  },
  {
    name: 'students-color',
    url: './../assets/scss/icons/icon-24-px-students-color.svg',
  },
  {
    name: 'sound-color',
    url: './../assets/scss/icons/icon-24-px-sound-color.svg',
  },
  {
    name: 'chat-color',
    url: './../assets/scss/icons/icon-24-px-chat-color.svg',
  },
  {
    name: 'allow-icon',
    url: './../assets/scss/icons/icon-allowed-checkbox.svg',
  },
  { name: 'add-link', url: './../assets/scss/icons/icon-add-link.svg' },
  { name: 'icon-link', url: './../assets/scss/icons/icon-link.svg' },
  {
    name: 'icon-collection',
    url: './../assets/scss/icons/icon-collection.svg',
  },
  {
    name: 'add-collection',
    url: './../assets/scss/icons/add-collection.svg',
  },
  { name: 'print-icon', url: './../assets/scss/icons/icon-print.svg' },
  {
    name: 'save-screen-icon',
    url: './../assets/scss/icons/icon-save-screenshot.svg',
  },
  { name: 'expand-icon', url: './../assets/scss/icons/icon-expand.svg' },
  {
    name: 'waiting-img',
    url: './../assets/scss/icons/icon-browser-notopen.svg',
  },
  {
    name: 'student-not-online-icon',
    url: './../assets/scss/icons/icon-student-not-online.svg',
  },
  { name: 'zoomin-icon', url: './../assets/scss/icons/icon-zoom-in.svg' },
  {
    name: 'student-excluded-icon',
    url: './../assets/scss/icons/icon-student-excluded.svg',
  },
  {
    name: 'waiting-device-icon',
    url: './../assets/scss/icons/icon-waiting-on-device.svg',
  },
  {
    name: 'zoom-out-icon',
    url: './../assets/scss/icons/icon-zoom-out.svg',
  },
  {
    name: 'screen-lock-icon',
    url: './../assets/scss/images/class-icons/screen-lock.svg',
  },
  {
    name: 'site-lock-icon',
    url: './../assets/scss/images/class-icons/site-lock.svg',
  },
  {
    name: 'push-url-icon',
    url: './../assets/scss/images/class-icons/push-url.svg',
  },
  {
    name: 'screen-share-icon',
    url: './../assets/scss/images/class-icons/share-screen.svg',
  },
  {
    name: 'stop-screen-share-icon',
    url: './../assets/scss/images/class-icons/stop_screenshare.svg',
  },
  {
    name: 'sound-icon',
    url: './../assets/scss/images/class-icons/sound.svg',
  },
  {
    name: 'lockon-icon',
    url: './../assets/scss/images/class-icons/lockon.svg',
  },
  {
    name: 'active-message-icon',
    url: './../assets/scss/icons/icon-active-message.svg',
  },
  { name: 'message-icon', url: './../assets/scss/icons/icon-message.svg' },
  {
    name: 'screenlock-filled-icon',
    url: './../assets/scss/icons/icon-screenlock-filled.svg',
  },
  {
    name: 'raisehand-filled-icon',
    url: './../assets/scss/icons/icon-raisehand-filled.svg',
  },
  {
    name: 'announcement-filled-icon',
    url: './../assets/scss/icons/icon-announcement-filled.svg',
  },
  {
    name: 'sitelock-filled-icon',
    url: './../assets/scss/icons/icon-sitelock-filled.svg',
  },
  {
    name: 'chat-icon',
    url: './../assets/scss/images/class-icons/message.svg',
  },
  {
    name: 'settings-icon',
    url: './../assets/scss/icons/icon-menu-setup.svg',
  },
  {
    name: 'user-icon',
    url: '/assets/scss/icons/icon-menu-setup.svg',
  },
  {
    name: 'device-icon',
    url: './../assets/scss/icons/icon-menu-devices.svg',
  },
  {
    name: 'class-icon',
    url: './../assets/scss/icons/icon-menu-classes.svg',
  },
  {
    name: 'weblink-icon',
    url: './../assets/scss/icons/icon-menu-weblinks.svg',
  },
  {
    name: 'clone-icon',
    url: './../assets/scss/icons/icon-clone.svg',
  },
  {
    name: 'calendar-icon',
    url: './../../assets/scss/icons/icon-calendar.svg',
  },
  {
    name: 'warning-icon',
    url: './../assets/scss/images/warning-notice.svg',
  },
  {
    name: 'add-circle-icon',
    url: './../assets/scss/images/class-icons/icon-add-new.svg',
  },
  {
    name: 'stop-icon',
    url: './../assets/scss/images/class-icons/icon-stop.svg',
  },
  {
    name: 'online-icon',
    url: './../../assets/scss/icons/icon-8-px-dot.svg',
  },
  {
    name: 'offline-icon',
    url: './../../assets/scss/icons/icon-8-px-offline.svg',
  },
  {
    name: 'delete-icon',
    url: './../assets/scss/icons/icon-delete.svg',
  },
  {
    name: 'questions-icon',
    url: './../assets/scss/icons/icon-question.svg',
  },
  {
    name: 'sync-icon',
    url: './../assets/scss/icons/icon-sync.svg',
  },
  {
    name: 'guide-icon',
    url: './../assets/scss/icons/icon-guide.svg',
  },
  {
    name: 'icon-share',
    url: './../assets/scss/icons/icon-share.svg',
  },
  {
    name: 'info-icon',
    url: './../assets/scss/icons/icon-info.svg',
  },
  {
    name: 'smile-icon',
    url: './../assets/scss/icons/smile.svg',
  },
  {
    name: 'now-sharing-green-icon',
    url: './../assets/scss/icons/now-sharing-green.svg',
  },
  {
    name: 'student-screen-share-icon',
    url: './../assets/scss/icons/icon-student-screen-share.svg',
  },
  {
    name: 'notice-icon',
    url: './../assets/scss/icons/icon-notice.svg',
  },
  {
    name: 'securly-logo',
    url: '/assets/securly-logo.svg',
  },
  {
    name: 'rhithm-logo',
    url: '/assets/rhithm-logo.svg',
  },
  {
    name: 'icon-file',
    url: '/assets/scss/icons/icon-file.svg',
  },
  {
    name: 'icon-dashboard',
    url: '/assets/scss/icons/icon-dashboard.svg',
  },
  {
    name: 'icon-calendar',
    url: '/assets/scss/icons/icon-calendar.svg',
  },
  {
    name: 'icon-hearts-suit',
    url: '/assets/scss/icons/icon-hearts-suit.svg',
  },
  {
    name: 'icon-settings',
    url: '/assets/scss/icons/icon-settings.svg',
  },
  {
    name: 'icon-angledown',
    url: '/assets/scss/icons/icon-dropdown.svg',
  },
  {
    name: 'icon-border-user',
    url: '/assets/scss/icons/icon-border-User.svg',
  },
  {
    name: 'icon-share-user',
    url: '/assets/scss/icons/icon-share-user.svg',
  },
  {
    name: 'icon-share-user-g',
    url: '/assets/scss/icons/icon-share-user-g.svg',
  },
  {
    name: 'icon-email',
    url: '/assets/scss/icons/icon-email.svg',
  },
  {
    name: 'icon-border-question',
    url: '/assets/scss/icons/icon-border-Question.svg',
  },
  {
    name: 'icon-app-switcher',
    url: '/assets/scss/icons/icon-App-switcher.svg',
  },
  {
    name: 'icon-table',
    url: '/assets/scss/icons/icon-table.svg',
  },
  {
    name: 'icon-download-new',
    url: '/assets/scss/icons/icon-download-new.svg',
  },
  {
    name: 'icon-playlist',
    url: '/assets/scss/icons/icon-playlist.svg',
  },
  {
    name: 'icon-close',
    url: '/assets/scss/icons/icon-close.svg',
  },
  {
    name: 'icon-add-new',
    url: '/assets/scss/icons/icon-add-new.svg',
  },
  {
    name: 'icon-arr-blue',
    url: '/assets/scss/icons/icon-arr-blue.svg',
  },
  {
    name: 'icon-search',
    url: '/assets/scss/icons/icon-search.svg',
  },
  {
    name: 'icon-arr-back',
    url: '/assets/scss/icons/icon-arr-back.svg',
  },
  {
    name: 'icon-user-bg',
    url: '/assets/scss/icons/icon-user-bg.svg',
  },
  {
    name: 'icon-sheet-bg',
    url: '/assets/scss/icons/icon-sheet-bg.svg',
  },
  {
    name: 'icon-load-bg',
    url: '/assets/scss/icons/icon-load-bg.svg',
  },
  {
    name: 'icon-bin-bg',
    url: '/assets/scss/icons/icon-bin-bg.svg',
  },
  {
    name: 'icon-dollar-bg',
    url: '/assets/scss/icons/icon-dollar-bg.svg',
  },
  {
    name: 'icon-gfill-up-arrow',
    url: '/assets/scss/icons/icon-gfill-up-arrow.svg',
  },
  {
    name: 'icon-rfill-up-arrow',
    url: '/assets/scss/icons/icon-rfill-up-arrow.svg',
  },
  {
    name: 'icon-pin',
    url: '/assets/scss/icons/icon-pin.svg',
  },
  {
    name: 'icon-reset',
    url: '/assets/scss/icons/icon-reset.svg',
  },
  {
    name: 'edit-icon',
    url: '/assets/scss/icons/edit-icon.svg',
  },
  {
    name: 'check-icon',
    url: '/assets/scss/icons/check-icon.svg',
  },
  {
    name: 'icon-out',
    url: '/assets/scss/icons/icon-out.svg',
  },
  {
    name: 'icon-user-edit',
    url: '/assets/scss/icons/icon-user-edit.svg',
  },
  {
    name: 'search-icon',
    url: '/assets/scss/icons/search-icon.svg',
  },
  {
    name: 'icon-play-small',
    url: '/assets/scss/icons/icon-play-small.svg',
  },
  {
    name: 'close-icon',
    url: '/assets/scss/icons/close-icon.svg',
  },

  {
    name: 'next-arr-icon',
    url: '/assets/scss/icons/next-arr-icon.svg',
  },
  {
    name: 'back-arr-icon',
    url: '/assets/scss/icons/back-arr-icon.svg',
  },
  {
    name: 'icon-note-text',
    url: '/assets/scss/icons/icon-note-text.svg',
  },
  {
    name: 'icon-dashboard-w',
    url: '/assets/scss/icons/icon-dashboard-w.svg',
  },
  {
    name: 'icon-share-user-w',
    url: '/assets/scss/icons/icon-share-user-w.svg',
  },

  {
    name: 'SecurlyDiscern',
    url: '/assets/scss/icons/SecurlyDiscern.svg',
  },
  {
    name: 'homeline',
    url: '/assets/scss/icons/homeline.svg',
  },
  {
    name: 'frameworks',
    url: '/assets/scss/icons/frameworks.svg',
  },
  {
    name: 'tools-fill',
    url: '/assets/scss/icons/tools-fill.svg',
  },
  {
    name: 'eye-line',
    url: '/assets/scss/icons/eye-line.svg',
  },
  {
    name: 'line-chart-line',
    url: '/assets/scss/icons/line-chart-line.svg',
  },
  {
    name: 'user-line',
    url: '/assets/scss/icons/user-line.svg',
  },
  {
    name: 'mail-open-line',
    url: '/assets/scss/icons/mail-open-line.svg',
  },
  {
    name: 'group-line',
    url: '/assets/scss/icons/group-line.svg',
  },
  {
    name: 'chat-line',
    url: '/assets/scss/icons/chat-line.svg',
  },
  {
    name: 'account-box-line',
    url: '/assets/scss/icons/account-box-line.svg',
  },
  {
    name: 'icon-add-user',
    url: '/assets/scss/icons/icon-add-user.svg',
  },
  {
    name: 'more-fill',
    url: '/assets/scss/icons/more-fill.svg',
  },
  {
    name: 'Securly-Discern-logo',
    url: '/assets/scss/icons/Securly-Discern-logo.svg',
  },
  {
    name: 'key-line',
    url: '/assets/scss/icons/key-line.svg',
  },
  {
    name: 'delete-bin-line',
    url: '/assets/scss/icons/delete-bin-line.svg',
  },
  {
    name: 'refresh-line',
    url: '/assets/scss/icons/refresh-line.svg',
  },
  {
    name: 'Securly-Discern-logo-blue',
    url: '/assets/scss/icons/Securly-Discern-logo-blue.svg',
  },
  {
    name: 'Sync-new',
    url: '/assets/scss/icons/Sync-new.svg',
  },

  {
    name: 'profile',
    url: '/assets/scss/icons/profile.svg',
  },
  {
    name: 'cohort',
    url: '/assets/scss/icons/cohort.svg',
  },
  {
    name: 'measuretrack',
    url: '/assets/scss/icons/measuretrack.svg',
  },
  {
    name: 'personalize',
    url: '/assets/scss/icons/personalize.svg',
  },
  {
    name: 'screen',
    url: '/assets/scss/icons/screen.svg',
  },

];
