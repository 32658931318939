import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomMessageComponent } from '../custom-message/custom-message.component';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {

    message: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public dataObj: {
            message: any
        },

        public dialogRef: MatDialogRef<CustomMessageComponent>
    ) {

        this.message = dataObj.message
    }
    @Output() close: EventEmitter<any> = new EventEmitter<any>();


    success() {

        this.dialogRef.close({
            clicked: 'submit',
        });
    }



    onClose() {
        this.close.emit("")
        this.dialogRef.close({
            clicked: 'cancel',
        });
    }

    onMessageClose() {
        this.dialogRef.close({
            clicked: 'submit',
        });
        this.close.emit("")
    }


}