<div class="">
  <div style="display:flex; flex-direction: row-reverse;">
    <button
      class="modalCancelBtn closeBtn"
      (click)="closeModal('no')"
      mat-dialog-close
      align="end"
    >
      <i class="icon icon-e-remove"></i>
    </button>
  </div>
    
    <!-- TODO: Update image -->

    <div class="">

      <h4 class="header">Delete</h4>
      <ng-container *ngIf="!isAccountDeleted">
        <p> Do you want to delete this record ? </p> 
      </ng-container>

      <ng-container *ngIf="isAccountDeleted">
        <p> Are you sure you want to delete this account ? </p>
        <p>This action is permanent and will remove all related data.</p> 
      </ng-container>
    </div>

    <div class="dialog-footer" style="display: flex; flex-direction: row-reverse;">        
      <button (click)="closeModal('yes')" class="button -primary customBtn">Yes</button>
      <button (click)="closeModal('no')" class="button -disabled -secondary customBtn">No</button>
    </div>
  