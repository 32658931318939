import { Injectable } from '@angular/core';
import { Observable, catchError, delay, mergeMap, of, retryWhen } from 'rxjs';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { AppConsts } from '../AppConsts';
import { environment } from '@env/environment';
import { AuthenticationService } from '../services/authentication.service';


@Injectable()
export class RhithmHttpInterceptor implements HttpInterceptor {
    feature_RHIT3900: boolean = false;
    constructor(
        private _appAuthService: AuthenticationService
    ) {
        this.feature_RHIT3900 = true;
        /* AppConsts.isFeatureEnabled(FeatureFlagEnum.Feature_RHIT3900) */
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let envUrl: any[] = []
        envUrl = request.url.split('')
        const assetRegexPattern = /\/assets\//;
        let isAssest = assetRegexPattern.test(request.url)
        if (!/^(http|https):/i.test(request.url) && !isAssest) {
            request = request.clone({ url: AppConsts.appBaseUrl + request.url });
        }
        ;
        const tenantId = this.getCookie('Abp.TenantId');


        // Add tenantId to headers
        if (tenantId !== null && tenantId !== "null") {
            request = request.clone({
                setHeaders: {
                    'Abp.TenantId': tenantId,
                },
            });
        }
        const token = this.getCookie('securly_token');
        const authToken = token;
        if (authToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
        }

        // for Backend user friendly error
        const maxRetries = 1;
        const delayMs = 2000;
        var modifiedRequest = AppConsts.useSecurlySso ? this.normalizeRequestHeaders(request) : request;
        return next.handle(modifiedRequest).pipe(
            retryWhen((error) => {

                return error.pipe(
                    mergeMap(async (error, index) => {
                        if (index < maxRetries && error.status == 500) {
                            let jsonString = JSON.parse(await error.error.text()).error.message;
                            alert(jsonString)

                            return of(jsonString).pipe(delay(delayMs));
                        }

                        throw error;
                    })
                )
            })
        )
    }

    protected normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (AppConsts['useSecurlySso']) {
            var headers = this.feature_RHIT3900 ? request.headers : new HttpHeaders();
            var modifiedHeaders = this.addHeaderRhithmUserInfoFromCookie(headers, request.body);
            return request.clone({
                headers: modifiedHeaders
            });
        } else {
            return request;
        }
    }

    protected addHeaderRhithmUserInfoFromCookie(headers: HttpHeaders, body: any): HttpHeaders {
        let cookieValue = this._appAuthService.getRhithmloginInfoFromCookie();
        if (AppConsts.isTenant) {
            headers = headers.set('Content-Type', 'application/json;');
            headers = headers.set("Accept", "text/plain");
        }
        if (headers) {
            if (!headers.has("r.lInfo")) {
                headers = headers.set("r.lInfo", JSON.stringify(cookieValue))
                if (!AppConsts.uploadRequest && cookieValue != null) {
                        headers = headers.set('Content-Type', 'application/json;');
                        headers = headers.set("Accept", "text/plain");
                }
                else {
                    headers = headers.set("Accept", "text/plain");
                    return;
                }
                headers = headers.set('Cache-Control', 'no-cache, no-store, must-revalidate, max-age=0')
            }
        }
        return headers;
    }
    private getCookie(name: string): string | null {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop()?.split(';').shift() || null;
        return null;
    }

}
