import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountServiceProxy, AppVersion, ForgotPasswordRequestDto } from '@app/shared/service-proxies/service-proxies';
import { CustomizerSettingsService } from '@app/shared/services/customizer-settings.service';
import * as reducers from '../../reducers';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject, skip, takeUntil } from 'rxjs';
import { forgetPassword } from '../auth.actions';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {


  emailAddress: any;
  errorVisible: boolean = false;
  isLoading: boolean = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  error$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    public themeService: CustomizerSettingsService,
    private accountServiceProxy: AccountServiceProxy,
    private router: Router,
    private store: Store<reducers.State>,
    private _snackBarService: SecurlySnackBarService,
  ) { }
  sendResetLink() {
    this.isLoading = true;
    if (this.emailAddress) {
      let body = {
        email: this.emailAddress,
        appVersion: AppVersion.AppBaseUrlV2
      } as ForgotPasswordRequestDto
      this.store.dispatch(forgetPassword.init({ ...body }));
      this.store.select(reducers.userForgetPassword).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: any) => {
        if (res.success) {
          this.error$.next(false);
          this.isLoading = false;
          this.errorVisible = false;
          this.router.navigateByUrl('auth/forgot-password/confirm-mail', { state: { email: this.emailAddress } })
          // this._snackBarService.open(res.message, SecurlySnackBarType.Success)
        } else {
          this.isLoading = false;
          this.errorVisible = true;
          this.error$.next(true);
        }
      });
    }

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
