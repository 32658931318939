<div class="main-conatiner">
    <div class="heading-wrapper">
        <div class="heading-text">
            <h4 class="mb-0">Add to Existing Cohort</h4>
        </div>
        <div class="modal-close-btn ">
            <span class="pointer" (click)="close()"><i class="icon icon-e-remove"></i></span>
        </div>
    </div>

    <mat-dialog-content>
        <div class="cohort-form-wrapper">
            <div class="cohort-form-container">
                <form [formGroup]="cohortForm">
                    <div class="row">

                        <div class="col-12">
                            <div class="form-group">
                                <label class="label-text" for="desc"> Select Cohort<span
                                        class="text-danger required-field">*</span></label>

                                <mat-select [disableOptionCentering]="true" panelClass="custom-search-select"
                                    placeholder="Select Cohort Name" class="form-control d-user-role-flter  w-100" name="cohort"
                                    id="" formControlName="cohort" (selectionChange)="onCohortNameSelected()">

                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="cohortNameSearchControl"
                                            placeholderLabel="Search"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngIf="filteredCohortNameList?.length === 0" [value]="'no-entry'"
                                        disabled>
                                        No Entries Found
                                    </mat-option>
                                    <mat-option *ngFor="let data of filteredCohortNameList"
                                        [value]="data">{{data?.name}}</mat-option>
                                </mat-select>

                                <div class="error" *ngIf="f['cohort'].invalid && f['cohort'].touched">
                                    <div class="display mt-4"
                                        *ngIf="f['cohort'].errors && f['cohort'].errors['required']">
                                        <p>Select Cohort Name</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class=" col-6">
                            <div class="form-group">
                                <label class="label-text" for="clinicalFrameworkName">Clinical Framework<span
                                        class="text-danger required-field">*</span></label>
                                <input type="text" class="form-control" name="clinicalFrameworkName"
                                    formControlName="clinicalFrameworkName" placeholder="Framework Name" />
                            </div>
                            <div class="error mt-2"
                                *ngIf="f['clinicalFrameworkName'].invalid && f['clinicalFrameworkName'].touched">
                                <div class="display"
                                    *ngIf="f['clinicalFrameworkName'].errors && f['clinicalFrameworkName'].errors['required']">
                                    <p>Framework is required</p>
                                </div>
                            </div>
                        </div>

                        <div class=" col-6">
                            <div class="form-group">
                                <label class="label-text" for="clinicalFrameworkVariables">Clinical Framework
                                    Variables<span class="text-danger required-field">*</span></label>
                                <mat-select panelClass="myPanelClass" class="form-control custom-mat-select"
                                    (selectionChange)="onSelectVariables()" formControlName="clinicalFrameworkVariables"
                                    placeholder="Select Account" multiple>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="variablesSearchControl"
                                            placeholderLabel="Search"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngIf="filteredVariablesList?.length === 0" [value]="'no-entry'">
                                        No Entries Found
                                    </mat-option>
                                    <mat-option *ngFor="let data of filteredVariablesList"
                                        [value]="data.name">{{data.name}}</mat-option>
                                </mat-select>
                            </div>

                        </div>

                        <div class=" col-6">
                            <!-- <div class="form-group">
                                <label class="label-text" for="summary"> Summary </label>
                                <textarea class="form-control-1" id="summary" aria-describedby="desc"
                                    formControlName="summary" matInput rows="4"></textarea>
                            </div> -->
                        </div>

                        <div class="col-6 filter-button">
                            <button class="button small btn-primary" (click)="getStudents()">
                                Filter Students
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- <div class="row filter-button">
            <div class="search-student col-4">
                <div class="srh-btn"> <mat-icon svgIcon="search-icon"></mat-icon> </div>
                <input type="text" class="form-control filterControl" id="search" aria-describedby="desc"
                    [formControl]="searchControl" placeholder="Search" />
            </div>
        </div> -->

        <div class="row mt-2 d-flex">
            <div class="col-6">
                <mat-radio-group [(ngModel)]="filterSelected">
                    <mat-radio-button *ngFor="let option of filterOptions" [value]="option.value"
                        (click)="onFilterSelection(option.value)">
                        {{ option.label }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div class="col-6 search-box">
                <div class="search-student">
                    <div class="srh-btn"> <mat-icon svgIcon="search-icon"></mat-icon> </div>
                    <input type="text" class="form-control filterControl" id="search" aria-describedby="desc"
                        [formControl]="searchControl" placeholder="Search" />
                </div>
            </div>
        </div>

        <div class="table-container">
            <table mat-table [dataSource]="cohortList" matSort [id]="tableName" class="customtable">
                <ng-container matColumnDef="checkBox" justify="start">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col"
                        mat-sort-header="checkBox">
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="user-info d-flex align-items-center">
                            <mat-checkbox class="gray-color" (ngModelChange)="onCheckBoxClick(element)"
                                [(ngModel)]="element.check" [checked]="element.check"></mat-checkbox>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="email" justify="start">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col"
                        mat-sort-header="email">
                        Student Email </th>
                    <td mat-cell *matCellDef="let element"><span>{{element?.studentTextId}}</span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="name" justify="start">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col"
                        mat-sort-header="name">
                        Student Name </th>
                    <td mat-cell *matCellDef="let element">{{element?.studentName}} </td>
                </ng-container>
                <ng-container matColumnDef="schools" justify="start">
                    <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col"
                        mat-sort-header="schools">
                        Schools </th>
                    <td mat-cell *matCellDef="let element"> {{element?.schoolName}}</td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div class="no_data" *ngIf="cohortList?.length === 0">
            {{"No Data"}}
        </div>
        <div class="text-left material-pagination">
            <mat-paginator #paginatorExistCohort [disabled]="cohortList?.length === 0" showFirstLastButtons
                [length]="totalRecordCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
                (page)="onPageChange($event)">
            </mat-paginator>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="btn-wrapper">
            <button class="button small btn-primary" (click)="onClose()">
                Cancel
            </button>
            <button class="button small btn-primary" (click)="save()"
                [disabled]="cohortForm.invalid || this.selectedUsers.length <= 0 ">
                Save
            </button>
        </div>
    </mat-dialog-actions>

</div>