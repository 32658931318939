import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { loadAnalysisRecurringRequestCohorts, loadAnalysisRecurringRequestLogs, loadAnalysisRecurringRequestSchools, loadAnalysisRecurringRequestStudents, loadAnalysisRequestCohorts, loadAnalysisRequestSchools, loadAnalysisRequestStudents, loadCustomerList, loadMyAnalyses, loadRecurringAnalyses, loadTotalKeywordUsageCounts, makeRecurring, toggleAnalysisRecurringRequestStatus, updateRecurringAnalysisRequest, updatedAnalysisRequest } from "./my-analysis.actions";
import { AnalysisRecurringRequestServiceProxy, AnalysisRequestServiceProxy, AnalyzerServiceProxy } from "@app/shared/service-proxies/service-proxies";

@Injectable()
export class MyAnalysisEffects {
    constructor(
        private actions$: Actions,
        private analyzerService: AnalyzerServiceProxy,
        private _analysisRequestServiceProxy: AnalysisRequestServiceProxy,
        private _analysisRecurringRequestServiceProxy: AnalysisRecurringRequestServiceProxy,
    ) { }
    loadCustomerList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadCustomerList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.analyzerService.getMyCustomers().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadCustomerList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Customer List.',
                            errorMapping: {},
                            failureAction: loadCustomerList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Customer List.',
                        })
                    })
                )
            })
        )
    })
    loadAnalysisRecurringRequestStudents$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRecurringRequestStudents.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.getAnalysisRecurringRequestStudentList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRecurringRequestStudents.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Recurring Request Students.',
                            errorMapping: {},
                            failureAction: loadAnalysisRecurringRequestStudents.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Recurring Request Students.',
                        })
                    })
                )
            })
        )
    })
    loadAnalysisRequestStudents$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRequestStudents.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analysisRequestServiceProxy.getAnalysisRequestStudentList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRequestStudents.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Request Students.',
                            errorMapping: {},
                            failureAction: loadAnalysisRequestStudents.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Request Students.',
                        })
                    })
                )
            })
        )
    })
    loadAnalysisRecurringRequestSchools$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRecurringRequestSchools.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.getAnalysisRecurringRequestSchoolList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRecurringRequestSchools.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Recurring Request Schools.',
                            errorMapping: {},
                            failureAction: loadAnalysisRecurringRequestSchools.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Recurring Request Schools.',
                        })
                    })
                )
            })
        )
    })
    loadAnalysisRequestSchools$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRequestSchools.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRequestServiceProxy.getAnalysisRequestSchoolList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRequestSchools.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Request Schools.',
                            errorMapping: {},
                            failureAction: loadAnalysisRequestSchools.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Request Schools.',
                        })
                    })
                )
            })
        )
    })
    loadAnalysisRecurringRequestLogs$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRecurringRequestLogs.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.getAnalysisRecurringRequestLogList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRecurringRequestLogs.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Recurring Request Logs.',
                            errorMapping: {},
                            failureAction: loadAnalysisRecurringRequestLogs.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Recurring Request Logs.',
                        })
                    })
                )
            })
        )
    })
    loadTotalKeywordUsageCounts$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadTotalKeywordUsageCounts.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRequestServiceProxy.getTotalKeywordUsageCount().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadTotalKeywordUsageCounts.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Total Keyword Usage Counts.',
                            errorMapping: {},
                            failureAction: loadTotalKeywordUsageCounts.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Total Keyword Usage Counts.',
                        })
                    })
                )
            })
        )
    })
    updatedAnalysisRequest$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(updatedAnalysisRequest.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRequestServiceProxy.updatedAnalysisRequest(payload.id, payload.name).pipe(
                    map((res: any) => {
                        if (res) {
                            return updatedAnalysisRequest.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Updated Analysis Request.',
                            errorMapping: {},
                            failureAction: updatedAnalysisRequest.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Updated Analysis Request.',
                        })
                    })
                )
            })
        )
    })
    updateRecurringAnalysisRequest$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(updateRecurringAnalysisRequest.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.updateRecurringAnalysisRequest(payload.id, payload.name).pipe(
                    map((res: any) => {
                        if (res) {
                            return updateRecurringAnalysisRequest.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Updated Recurring Analysis Request.',
                            errorMapping: {},
                            failureAction: updateRecurringAnalysisRequest.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Updated Recurring Analysis Request.',
                        })
                    })
                )
            })
        )
    })
    makeRecurring$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(makeRecurring.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.createRecurringAnalysisFromAnalysisRequest(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return makeRecurring.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to make Analysis Recurring.',
                            errorMapping: {},
                            failureAction: makeRecurring.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to make Analysis Recurring.',
                        })
                    })
                )
            })
        )
    })
    loadMyAnalyses$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadMyAnalyses.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analysisRequestServiceProxy.paginate(
                    payload.pageIndex,
                    payload.pageSize,
                    payload.customerId,
                    payload.clinicalFrameworkId,
                    payload.startDate,
                    payload.endDate,
                    payload.selectedStatus
                ).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadMyAnalyses.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all My Analyses List.',
                            errorMapping: {},
                            failureAction: loadMyAnalyses.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all My Analyses List.',
                        })
                    })
                )
            })
        )
    })
    loadRecurringAnalyses$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadRecurringAnalyses.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.paginate(
                    payload.pageIndex,
                    payload.pageSize
                ).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadRecurringAnalyses.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all My Analyses List.',
                            errorMapping: {},
                            failureAction: loadRecurringAnalyses.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all My Analyses List.',
                        })
                    })
                )
            })
        )
    })
    toggleAnalysisRecurringRequestStatus$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(toggleAnalysisRecurringRequestStatus.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.toggleAnalysisRecurringRequestStatus(
                    payload.analysisRecurringRequestId,
                    payload.isDisabled
                ).pipe(
                    map((res: any) => {
                        if (res) {
                            return toggleAnalysisRecurringRequestStatus.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to toggle Analysis Recurring Request Status.',
                            errorMapping: {},
                            failureAction: toggleAnalysisRecurringRequestStatus.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to toggle Analysis Recurring Request Status.',
                        })
                    })
                )
            })
        )
    })


    loadAnalysisRecurringRequestCohorts$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRecurringRequestCohorts.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRecurringRequestServiceProxy.getAnalysisRecurringRequestCohortList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRecurringRequestCohorts.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Recurring Request Cohorts.',
                            errorMapping: {},
                            failureAction: loadAnalysisRecurringRequestCohorts.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Recurring Request Cohorts.',
                        })
                    })
                )
            })
        )
    })
    loadAnalysisRequestCohorts$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalysisRequestCohorts.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._analysisRequestServiceProxy.getAnalysisRequestCohortList(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalysisRequestCohorts.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Analysis Request Cohorts.',
                            errorMapping: {},
                            failureAction: loadAnalysisRequestCohorts.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Analysis Request Cohorts.',
                        })
                    })
                )
            })
        )
    })
}