import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-mail',
  templateUrl: './confirm-mail.component.html',
  styleUrls: ['./confirm-mail.component.scss']
})
export class ConfirmMailComponent {

  emailAddress: string = '' ;
  routeState: any;
  constructor(
      private router: Router,
      
  ) {
      
      this.routeState = this.router.getCurrentNavigation()?.extras.state;
      if(this.routeState?.email){

          this.emailAddress = this.routeState?.email
      }


  }
}
