<div class="main-conatiner">
  <div class="heading-wrapper">
    <div class="heading-text">
      <h4 class="mb-0">Invite Users by SIS</h4>
    </div>
    <div class="modal-close-btn ">
      <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove"></i></span>
    </div>
  </div>
  <div class="row sub-header-item">
    <div class="col-4">
      <div class="form-group search-input-wrapper">
        <div class="srh-btn">
          <mat-icon svgIcon="icon-search" (click)="onFilterChange()" title="search"></mat-icon>
        </div>
        <input matInput type="text" id="search" class="form-control filterControl reportSearch pl-4" name="searchFilter"
          placeholder="Search" [(ngModel)]="searchFilter" (keydown.enter)="onFilterChange()" (input)="onSearch()"
          aria-describedby="helpId">
      </div>
    </div>
    <div class="col-4 form-group">
      <mat-select class="form-control" panelClass="custom-search-select" [(ngModel)]="schoolIdsFilter" name="schoolIdsFilter"
        placeholder="Select Campus(es)" multiple (selectionChange)="onFilterChange()">
        <mat-option>
          <ngx-mat-select-search [formControl]="schoolSearchControl" placeholderLabel="Search"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngIf="filteredschoolList?.length === 0" [value]="'no-entry'" disabled>
          No Entries Found
        </mat-option>
        <mat-option *ngFor="let data of filteredschoolList" [value]="data.id">{{ data.name
          }}</mat-option>
      </mat-select>
    </div>
  </div>
  <mat-radio-group [(ngModel)]="filterSelected">
    <mat-radio-button *ngFor="let option of filterOptions" [value]="option.value"
      (click)="onFilterSelection(option.value)">
      {{ option.label }}
    </mat-radio-button>
  </mat-radio-group>
    <div class="table-container">
      <table mat-table [dataSource]="userInviteSecurlySync" matSort [id]="tableName" class="customtable">
        <ng-container matColumnDef="checkBox" justify="start">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col"
            mat-sort-header="checkBox">
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="user-info d-flex align-items-center">
              <mat-checkbox class="gray-color" (ngModelChange)="onCheckBoxClick(element)" [(ngModel)]="element.check" [checked]="element.check"
                [disabled]="element?.status == 'Pending' || element?.status == 'Accepted'"></mat-checkbox>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="name" justify="start">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col" mat-sort-header="name">
            Name </th>
          <td mat-cell *matCellDef="let element"><span>{{element?.firstName}} {{element?.lastName}}</span> </td>
        </ng-container>
        <ng-container matColumnDef="email" justify="start">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col" mat-sort-header="email">
            Email </th>
          <td mat-cell *matCellDef="let element"> {{element?.email}} </td>
        </ng-container>
        <ng-container matColumnDef="role" justify="start">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col" mat-sort-header="role">
            Role </th>
          <td mat-cell *matCellDef="let element"> {{element?.role}} </td>
        </ng-container>
        <ng-container matColumnDef="campuses" justify="start">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col"
            mat-sort-header="campuses">
            Campus(es) </th>
          <td mat-cell *matCellDef="let element">
            <a class="fw-medium" style="margin-bottom: 5px;" *ngFor="let item of element?.schoolList">{{item?.name}}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="status" justify="start">
          <th mat-header-cell *matHeaderCellDef [resizeColumn]="true" disableClear scope="col" mat-sort-header="status">
            Status </th>
          <td mat-cell *matCellDef="let element"> {{element?.status}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  <div class="no_data" *ngIf="userInviteSecurlySync?.length === 0">
    {{"No Data"}}
  </div>
  <div class="text-left material-pagination">
    <mat-paginator [disabled]="userInviteSecurlySync?.length === 0" showFirstLastButtons
      [length]="paginatorConfig.length" [pageSize]="paginatorConfig.pageSize"
      [pageSizeOptions]="paginatorConfig.pageSizeOptions" (page)="getPaginatedsync($event)">
    </mat-paginator>
  </div>
  <mat-dialog-actions>
    <div class="btn-wrapper">
      <button class="button small btn-primary" (click)="onClose()">
        Close
      </button>
      <button class="button small btn-primary" (click)="sendInvite()"
        [disabled]="this.selectedUsers.length <= 0">
        Send Invite(s)
      </button>
    </div>
  </mat-dialog-actions>
</div>