import { Injectable } from "@angular/core";
import Pusher, { Channel } from "pusher-js";
import { PusherMessage } from "./pusher-message.model";
import { Observable } from "rxjs";
import { AppConsts } from "../../../AppConsts";
import { PusherCachedPayloadDto, PusherCachingServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { EventService } from "@app/shared/services/event.service";
import { PusherAppChannelEventEnum, PusherChatChannelEventEnum, PusherRequestChannelEventEnum, PusherUserChannelEventEnum } from "@app/shared/AppEnums copy";

@Injectable()
export class RealTimeUpdatePusherService {
    private pusherClient: Pusher;
    private userChannel: Channel;
    private appChannel: Channel;
    private requestChannels: Channel[] = [];
    private userId: number;


    constructor(
        private pusherCachingServiceProxy: PusherCachingServiceProxy,
        private eventService: EventService
    ) {
        this.pusherClient = new Pusher(AppConsts.pusher.app_key, {
            cluster: AppConsts.pusher.cluster,
        });


    }


    registerEvents(): void {

        this.eventService.on(PusherAppChannelEventEnum.RefreshAppNow, (data) => {
        });

        this.eventService.on(PusherUserChannelEventEnum.OnMessageReceived, (data) => {
        });



        this.subscribeAppChannel();
    }


    subscribeRequestChannel(requestKeys: string[]): void {
        if (requestKeys.length === 0) {
            return;
        }

        for (const key of requestKeys) {

            const channelName = "request-" + key;
            const existingChannel = this.requestChannels.find(channel => channel.name === channelName);

            if (existingChannel) {
                // Channel with the same name already exists, skip subscribing
                continue;
            }

            var channel = this.pusherClient.subscribe(channelName);

            // userChannel
            for (const enumValue of Object.values(PusherRequestChannelEventEnum)) {
                channel.bind(
                    enumValue,
                    (message: any) => {
                        var _msg: PusherMessage = JSON.parse(message);
                        this.processPusherMessage(_msg, enumValue);
                    }
                );
            }

            this.requestChannels.push(channel);
        }


    }

    subscribeChatChannel(threadId: string): void {
        const channelName = "chat-" + threadId;
        const existingChannel = this.requestChannels.find(channel => channel.name === channelName);

        if (existingChannel) {
            // Channel with the same name already exists, skip subscribing
            return;
        }

        var channel = this.pusherClient.subscribe(channelName);
        // userChannel
        for (const enumValue of Object.values(PusherChatChannelEventEnum)) {
            channel.bind(
                enumValue,
                (message: any) => {
                    var _msg: PusherMessage = JSON.parse(message);
                    this.processPusherMessage(_msg, enumValue);
                }
            );
        }

        this.requestChannels.push(channel);
    }


    subscribeUserChannel(userId: number): void {

        if (userId == 0)
            return;

        this.userId = userId;
        this.userChannel = this.pusherClient.subscribe("user-" + userId);

        // userChannel
        for (const enumValue of Object.values(PusherUserChannelEventEnum)) {
            this.userChannel.bind(
                enumValue,
                (message: any) => {
                    var _msg: PusherMessage = JSON.parse(message);
                    if (_msg.recipientIds.indexOf(this.userId) > -1) {
                        this.processPusherMessage(_msg, enumValue);
                    }
                }
            );
        }
    }


    private subscribeAppChannel() {

        this.appChannel = this.pusherClient.subscribe("discern-app");

        // appChannel
        for (const enumValue of Object.values(PusherAppChannelEventEnum)) {
            this.appChannel.bind(
                enumValue,
                (message: any) => {
                    var _msg: PusherMessage = JSON.parse(message);
                    this.processPusherMessage(_msg, enumValue);
                }
            );
        }
    }

    private processPusherMessage(
        message: PusherMessage,
        eventName: string
    ): void {
        if (message.heavyPayLoad == true) {
            this.getPayLoadFromRedisCache(message.payloadId).subscribe(
                (result: PusherCachedPayloadDto) => {

                    if (result.payload) {
                        let payload = result.payload;
                        let response = JSON.parse(payload);
                        if (response) {
                            this.eventService.trigger(eventName, response);
                        }
                    }
                },
                (err) => {
                    console.error(
                        "Unable to get payload from redis for payload id: " +
                        message.payloadId,
                        err
                    );
                }
            );
        } else {
            let payload = JSON.parse(message.payLoadJson);
            if (payload) {
                this.eventService.trigger(eventName, payload);
            }
        }


        // }
    }


    private getPayLoadFromRedisCache(payloadId: string): Observable<PusherCachedPayloadDto> {
        return this.pusherCachingServiceProxy.getPayLoad(payloadId);
    }
}
