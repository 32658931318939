import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { loadChangeStatusAPIResponse, loadClinicalFramework, loadCloneAPIResponse, loadCreateAPIResponse, loadDeleteAPIResponse, loadDetailAPIResponse, loadUpdateAPIResponse } from "./frameworks.actions";
import { ClinicalFrameworkServiceProxy, ClinicalFrameworkStatusEnum, ClinicalFrameworkToAddDto } from "@app/shared/service-proxies/service-proxies";

@Injectable()
export class FrameworkEffects {
    constructor(
        private actions$: Actions,
        private _clinicalFrameworkService: ClinicalFrameworkServiceProxy
    ) { }
    loadAllStartedUserSessions$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadClinicalFramework.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.getClinicalFrameworkList(
                    payload.payload.searchFilter,
                    payload.payload.clinicalFrameworkStatus).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadClinicalFramework.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all clinical frameworks.',
                            errorMapping: {},
                            failureAction: loadClinicalFramework.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all clinical frameworks.',
                        })
                    })
                )
            })
        )
    })


    deleteFramework$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadDeleteAPIResponse.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.deleteClinicalFramework(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDeleteAPIResponse.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to delete clinical framework.',
                            errorMapping: {},
                            failureAction: loadDeleteAPIResponse.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to delete this clinical framework.',
                        })
                    })
                )
            })
        )
    })

    cloneFramework$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadCloneAPIResponse.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.cloneClinicalFramework(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadCloneAPIResponse.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to clone clinical framework.',
                            errorMapping: {},
                            failureAction: loadCloneAPIResponse.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to clone this clinical framework.',
                        })
                    })
                )
            })
        )
    })

    changeStatusFramework$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadChangeStatusAPIResponse.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.setClinicalFrameworkStatus(payload.id, payload.status).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadChangeStatusAPIResponse.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to change status of this clinical framework.',
                            errorMapping: {},
                            failureAction: loadChangeStatusAPIResponse.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to change status of this clinical framework.',
                        })
                    })
                )
            })
        )
    })


    createFramework$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadCreateAPIResponse.init),
            map((action: any) => action),

            mergeMap((payload) => {
          
                return this._clinicalFrameworkService.createClinicalFramework(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadCreateAPIResponse.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to create clinical framework.',
                            errorMapping: {},
                            failureAction: loadCreateAPIResponse.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to create clinical framework.',
                        })
                    })
                )
            })
        )
    })

    updateFramework$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUpdateAPIResponse.init),
            map((action: any) => action),

            mergeMap((payload) => {
          
                return this._clinicalFrameworkService.updateClinicalFramework(payload.id, payload.data).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadUpdateAPIResponse.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to update clinical framework.',
                            errorMapping: {},
                            failureAction: loadUpdateAPIResponse.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to update clinical framework.',
                        })
                    })
                )
            })
        )
    })

    getFrameworkDetail$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadDetailAPIResponse.init),
            map((action: any) => action),

            mergeMap((payload) => {
                console.log(payload)
          
                return this._clinicalFrameworkService.getById(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDetailAPIResponse.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to load clinical framework details.',
                            errorMapping: {},
                            failureAction: loadDetailAPIResponse.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load clinical framework details.',
                        })
                    })
                )
            })
        )
    })
}