import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.scss']
})
export class DeleteConfirmationComponent implements OnInit {
  isAccountDeleted:boolean = false;
  constructor(   
     @Inject(MAT_DIALOG_DATA) public data: any,
  private dialogRef: MatDialogRef<DeleteConfirmationComponent>) { }

  ngOnInit(): void {
    this.isAccountDeleted = this.data?.isAccount;
  }

  closeModal(res: any) {
    this.dialogRef.close(res);
  }
}
