<div class="create-dialog-box">
    <div class="heading-wrapper">
        <div class="heading-text">
            <h4 class="mb-0">Recurring Analysis Logs</h4>
        </div>
        <div class="modal-close-btn ">
            <span class="pointer" (click)="dialogRef.close(false)"><i class="icon icon-e-remove"></i></span>
        </div>
    </div>


    <!-- <div style="padding:20px" class="col-lg-12 col-md-12 col-sm-12">
        <div class="tagus-form-group without-icon">
            <mat-form-field appearance="fill">
                <textarea disabled [(ngModel)]="searchKeywords" name="searchKeywords"
                    matInput rows="12"></textarea>
            </mat-form-field>
        </div>
    </div> -->
    <div class="tags-list text-start keywordStyle">

        <table class="table">
            <thead>
                <tr>
                    <!-- <th class="fw-semibold main-color">View</th>                                 -->
                    <th class="fw-semibold text-center">Triggered On</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of studentList">
                    <td class=" text-center">{{item.triggeredOn | date}}</td>

                </tr>
            </tbody>
        </table>
        <!-- <a class="fw-medium" style="margin-bottom: 5px;"
            *ngFor="let item of studentList">{{item.triggeredOn}}</a> -->
    </div>
</div>