import { AnalysisRecurringRequestCohortToListDtoListIDataResult, AnalysisRecurringRequestLogToListDtoListIDataResult, AnalysisRecurringRequestSchoolToListDtoListIDataResult, AnalysisRecurringRequestStudentToListDtoListIDataResult, AnalysisRecurringRequestToListDtoPaginatedListIDataResult, AnalysisRequestCohortToListDtoListIDataResult, AnalysisRequestKeywordDtoIDataResult, AnalysisRequestSchoolToListDtoListIDataResult, AnalysisRequestStudentToListDtoListIDataResult, AnalysisRequestToListDtoPaginatedListIDataResult, GetMyCustomersResponse, IResult } from "@app/shared/service-proxies/service-proxies";
import { loadAnalysisRecurringRequestCohorts, loadAnalysisRecurringRequestLogs, loadAnalysisRecurringRequestSchools, loadAnalysisRecurringRequestStudents, loadAnalysisRequestCohorts, loadAnalysisRequestSchools, loadAnalysisRequestStudents, loadCustomerList, loadMyAnalyses, loadRecurringAnalyses, loadTotalKeywordUsageCounts, makeRecurring, toggleAnalysisRecurringRequestStatus, updateRecurringAnalysisRequest, updatedAnalysisRequest } from "./my-analysis.actions";

export interface State {
    customerListPayload: any;
    customerList: GetMyCustomersResponse;
    myAnalysesPayload: any;
    myAnalysesList: AnalysisRequestToListDtoPaginatedListIDataResult;
    RecurringAnalysesPayload: any;
    RecurringAnalyses: AnalysisRecurringRequestToListDtoPaginatedListIDataResult;
    AnalysisRecurringRequestStudentsPayload: any;
    AnalysisRecurringRequestStudents: AnalysisRecurringRequestStudentToListDtoListIDataResult;
    AnalysisRequestStudentsPayload: any;
    AnalysisRequestStudents: AnalysisRequestStudentToListDtoListIDataResult;
    AnalysisRecurringRequestSchoolsPayload: any;
    AnalysisRecurringRequestSchools: AnalysisRecurringRequestSchoolToListDtoListIDataResult;
    AnalysisRequestSchoolsPayload: any;
    AnalysisRequestSchools: AnalysisRequestSchoolToListDtoListIDataResult;
    makeRecurringPayload: any;
    makeRecurring: IResult;
    AnalysisRecurringRequestLogsPayload: any;
    AnalysisRecurringRequestLogs: AnalysisRecurringRequestLogToListDtoListIDataResult;
    TotalKeywordUsageCountsPayload: any;
    TotalKeywordUsageCounts: AnalysisRequestKeywordDtoIDataResult;
    updatedAnalysisRequestPayload: any;
    updatedAnalysisRequest: IResult;
    updateRecurringAnalysisRequestPayload: any;
    updateRecurringAnalysisRequest: IResult;
    toggleAnalysisRecurringRequestStatusPayload: any;
    toggleAnalysisRecurringRequestStatus: IResult;

    AnalysisRecurringRequestCohortPayload: any;
    AnalysisRecurringRequestCohorts: AnalysisRequestCohortToListDtoListIDataResult;
    AnalysisRequestCohortPayload: any;
    AnalysisRequestCohort: AnalysisRecurringRequestCohortToListDtoListIDataResult;
}

const initialState: State = {
    customerListPayload: null,
    customerList: null,
    myAnalysesPayload: null,
    myAnalysesList: null,
    RecurringAnalysesPayload: null,
    RecurringAnalyses: null,
    AnalysisRecurringRequestStudentsPayload: null,
    AnalysisRecurringRequestStudents: null,
    AnalysisRequestStudentsPayload: null,
    AnalysisRequestStudents: null,
    AnalysisRecurringRequestSchoolsPayload: null,
    AnalysisRecurringRequestSchools: null,
    AnalysisRequestSchoolsPayload: null,
    AnalysisRequestSchools: null,
    makeRecurringPayload: null,
    makeRecurring: null,
    AnalysisRecurringRequestLogsPayload: null,
    AnalysisRecurringRequestLogs: null,
    TotalKeywordUsageCountsPayload: null,
    TotalKeywordUsageCounts: null,
    updatedAnalysisRequestPayload: null,
    updatedAnalysisRequest: null,
    updateRecurringAnalysisRequestPayload: null,
    updateRecurringAnalysisRequest: null,
    toggleAnalysisRecurringRequestStatusPayload: null,
    toggleAnalysisRecurringRequestStatus: null,

    AnalysisRecurringRequestCohortPayload: null,
    AnalysisRecurringRequestCohorts: null,
    AnalysisRequestCohortPayload: null,
    AnalysisRequestCohort: null,
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        // Customer List
        case loadCustomerList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                customerListPayload: requestPayload,
            });
        }
        case loadCustomerList.success.type: {

            return Object.assign({}, state, {
                customerList: action,
            })
        }
        case loadMyAnalyses.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                myAnalysesPayload: requestPayload,
            });
        }
        case loadMyAnalyses.success.type: {

            return Object.assign({}, state, {
                myAnalysesList: action,
            })
        }
        case loadRecurringAnalyses.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                RecurringAnalyses: requestPayload,
            });
        }
        case loadRecurringAnalyses.success.type: {

            return Object.assign({}, state, {
                RecurringAnalyses: action,
            })
        }
        case loadAnalysisRecurringRequestStudents.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRecurringRequestStudentsPayload: requestPayload,
            });
        }
        case loadAnalysisRecurringRequestStudents.success.type: {

            return Object.assign({}, state, {
                AnalysisRecurringRequestStudents: action,
            })
        }
        case loadAnalysisRequestStudents.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRequestStudentsPayload: requestPayload,
            });
        }
        case loadAnalysisRequestStudents.success.type: {

            return Object.assign({}, state, {
                AnalysisRequestStudents: action,
            })
        }
        case loadAnalysisRecurringRequestSchools.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRecurringRequestSchoolsPayload: requestPayload,
            });
        }
        case loadAnalysisRecurringRequestSchools.success.type: {
            return Object.assign({}, state, {
                AnalysisRecurringRequestSchools: action,
            })
        }
        case loadAnalysisRequestSchools.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRequestSchoolsPayload: requestPayload,
            });
        }
        case loadAnalysisRequestSchools.success.type: {
            return Object.assign({}, state, {
                AnalysisRequestSchools: action,
            })
        }
        case makeRecurring.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                makeRecurringPayload: requestPayload,
            });
        }
        case makeRecurring.success.type: {

            return Object.assign({}, state, {
                makeRecurring: action,
            })
        }
        case loadAnalysisRecurringRequestLogs.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRecurringRequestLogsPayload: requestPayload,
            });
        }
        case loadAnalysisRecurringRequestLogs.success.type: {

            return Object.assign({}, state, {
                AnalysisRecurringRequestLogs: action,
            })
        }
        case loadTotalKeywordUsageCounts.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                TotalKeywordUsageCountsPayload: requestPayload,
            });
        }
        case loadTotalKeywordUsageCounts.success.type: {

            return Object.assign({}, state, {
                TotalKeywordUsageCounts: action,
            })
        }
        case updatedAnalysisRequest.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                updatedAnalysisRequestPayload: requestPayload,
            });
        }
        case updatedAnalysisRequest.success.type: {

            return Object.assign({}, state, {
                updatedAnalysisRequest: action,
            })
        }
        case updateRecurringAnalysisRequest.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                updateRecurringAnalysisRequestPayload: requestPayload,
            });
        }
        case updateRecurringAnalysisRequest.success.type: {

            return Object.assign({}, state, {
                updateRecurringAnalysisRequest: action,
            })
        }
        case toggleAnalysisRecurringRequestStatus.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                toggleAnalysisRecurringRequestStatusPayload: requestPayload,
            });
        }
        case toggleAnalysisRecurringRequestStatus.success.type: {

            return Object.assign({}, state, {
                toggleAnalysisRecurringRequestStatus: action,
            })
        }

        case loadAnalysisRecurringRequestCohorts.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRecurringRequestCohortPayload: requestPayload,
            });
        }
        case loadAnalysisRecurringRequestCohorts.success.type: {
            return Object.assign({}, state, {
                AnalysisRecurringRequestCohorts: action,
            })
        }

        case loadAnalysisRequestCohorts.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                AnalysisRequestCohortPayload: requestPayload,
            });
        }
        case loadAnalysisRequestCohorts.success.type: {
            return Object.assign({}, state, {
                AnalysisRequestCohort: action,
            })
        }

        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getCustomerList = (state: State) => state.customerList;
export const getMyAnalysesList = (state: State) => state.myAnalysesList;
export const getRecurringAnalyses = (state: State) => state.RecurringAnalyses;
export const getAnalysisRecurringRequestStudents = (state: State) => state.AnalysisRecurringRequestStudents;
export const getAnalysisRequestStudents = (state: State) => state.AnalysisRequestStudents;
export const getAnalysisRecurringRequestSchools = (state: State) => state.AnalysisRecurringRequestSchools;
export const getAnalysisRequestSchools = (state: State) => state.AnalysisRequestSchools;
export const makeAnalysisRecurring = (state: State) => state.makeRecurring;
export const getAnalysisRecurringRequestLogs = (state: State) => state.AnalysisRecurringRequestLogs;
export const getTotalKeywordUsageCounts = (state: State) => state.TotalKeywordUsageCounts;
export const updatedAnalysesRequest = (state: State) => state.updatedAnalysisRequest;
export const updateRecurringAnalysesRequest = (state: State) => state.updateRecurringAnalysisRequest;
export const toggleAnalysesRecurringRequestStatus = (state: State) => state.toggleAnalysisRecurringRequestStatus;

export const getAnalysisRecurringRequestCohorts = (state: State) => state.AnalysisRecurringRequestCohorts;
export const getAnalysisRequestCohorts = (state: State) => state.AnalysisRequestCohort;