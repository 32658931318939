import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@app/shared/services/authentication.service';
import { UserService } from '@app/shared/services/user.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  constructor(
    private _router: Router,
    public userService: UserService,
    private authenticationService: AuthenticationService,
  ) { }

  headerIcon = {
    icon: 'Securly-Discern-logo-blue',
    clickEvent: () => { }
  }
  // headerIcon:any
  
  ngOnInit() {
   
  }

  logout(){
    localStorage.clear()
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    // this._router.navigate(["/auth/loginemail"]);
    this.authenticationService.logout();
  }



  
  

 
  
 

  


  
}