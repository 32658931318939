
export class AppConsts {

    static appBaseUrl: string;
    static gainSightKey: string;
    static useSecurlySso: boolean;
    static apiBaseUrl: string;
    static uploadRequest: boolean;
    static isTenant: boolean;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish

    static readonly pusher = {
        cluster: "",
        app_key: "",
    };

    static readonly securlySso = {
        baseUrl: '',
        clientId: '',
    };
    
    static initialize(appConfig: any): void {

        AppConsts.appEnvironmentName = appConfig.appEnvironmentName;
        AppConsts.appBaseUrl = appConfig.appBaseUrl;
        AppConsts.apiBaseUrl = appConfig.apiBaseUrl;
        AppConsts.gainSightKey = appConfig.gainSightKey; 
        AppConsts.useSecurlySso = appConfig.useSecurlySso; 


        AppConsts.pusher.app_key = appConfig.pusher.app_key;
        AppConsts.pusher.cluster = appConfig.pusher.cluster;
    }
    // App version
    static appVersion: string;
    static appEnvironmentName: string;

    static readonly grid = {
        defaultPageSize: 10,
    };

    // Envronment Name
    static readonly environmentProduction = "Production";
    static readonly environmentDev = "Dev";

    // static readonly utcTZDifference = RDateTimeService.getUtcTzDifference();
    static readonly dateTimeFormat = "YYYY/MM/DD HH:mm";
    static readonly dateTimeFormatUsVersion = "MM/DD/YYYY HH:mm";

    // CustomeMessage
    static readonly info = "Info";
    static readonly success = "Success";
    static readonly warn = "Warning";
    static readonly error = "Error";
    static readonly confirm = "Confirm";
    static readonly block = "Block";

    // Selected language
    static readonly selectedEnglish = "English";
    static readonly selectedSpanish = "Español (Spanish)";
    static readonly discernInviteType:any = 1;

    // Home

    static readonly measureTrackHome  = "measureTrackHome";
    static readonly personalizeHome  = "personalizeHome";
    static readonly screeningHome  = "screeningHome";


    // Detail Reporting

    static readonly measureTrackReportCard  = "measureTrackReportCard";
    static readonly School  = "School";
    static readonly Student  = "Student";
    static readonly personalize  = "personalize";
    static readonly Trends  = "Trends";
    static readonly profileMeasureAndTrack  = "profileMeasureAndTrack";
    static readonly profilePersonalize  = "profilePersonalize";
    static readonly percentageBucketValue25  = 25;
    static readonly prevalence  = 'prevalence';


    // Analysis builder messages

    static readonly selectFrameworkTooltip: string = `Choose a framework to guide Discern on what to analyze in student's online activity. Click "view" to see the framework's definition and its key components.`;
    static readonly analysisDatesTooltip: string = `Frameworks require different data periods for accurate results. For instance, 'student interests' may need a week's data, while 'self-awareness' might require months. Dates adjust automatically based on your framework choice.`
    static readonly makeRecurringDefaultTooltip: string = `Enable this option to schedule the framework to run regularly based on the chosen date intervals, ensuring continuous and updated analysis.`
    static readonly failureMessage: string = `"Matching Analysis Found with Same Criteria" - followed by the date and status of the previous analysis, with a button to view it.`;

    static readonly analysisDatesMultipleFrameworksTooltip: string = `Date filtering is designed for individual frameworks. Select one framework to drill down further by date.`
    static readonly benchmarkTooltip: string = `The benchmark shows how often the selected variable has been identified in your district or school within the chosen date range, compared to national data. The color-coded percentile (e.g., 80-100th) indicates your standing relative to other institutions across the country.`
    static readonly keywordCountZero: string = "Online Activities for given dates are not found. Please select different analysis dates."

    // Tier Types

    static readonly Tier1  = "Tier 1";
    static readonly Tier2  = "Tier 2";
    static readonly Tier3  = "Tier 3";
}
