import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { Shell } from './shell/shell.service';
// import { DashboardStudentComponent } from './dashboard/dashboard-student/dashboard-student.component';
import { AuthenticationGuard } from './auth';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';

export const routes: Routes = [

  { path: 'not-found', component: NotFoundComponent },
  {
    path: 'app',
    component: AppComponent,
    canActivate: [AuthenticationGuard],
    children: [
      Shell.childRoutes([
        { path: '', redirectTo: 'home', pathMatch: 'full' },
        {
          path: 'home',
          loadChildren: () => import('./home/home.module').then(m => m.HomeModule), //Lazy load home module
        },
        {
          path: 'detailed-reporting',
          loadChildren: () => import('./detailed-reporting/detailed-reporting.module').then(m => m.DetailedReportingModule), //Lazy load detailed-reporting module
        },
        {
          path: 'frameworks',
          loadChildren: () => import('./frameworks/frameworks.module').then(m => m.FrameworksModule), //Lazy load frameworks module
        },
        {
          path: 'analysis-builder',
          loadChildren: () => import('./analysis-builder/analysis-builder.module').then(m => m.AnalysisBuilderModule), //Lazy load analysis-builder module
        },
        {
          path: 'my-analyses',
          loadChildren: () => import('./my-analysis/my-analysis.module').then(m => m.MyAnalysisModule), //Lazy load my-analysis module
        },
        {
          path: 'reporting',
          loadChildren: () => import('./reporting/reporting.module').then(m => m.ReportingModule), //Lazy load reporting module
        },
        {
          path: 'users',
          loadChildren: () => import('./users/users.module').then(m => m.UsersModule), //Lazy load users module
        },
        {
          path: 'invites',
          loadChildren: () => import('./invites/invites.module').then(m => m.InvitesModule), //Lazy load invites module
        },
        {
          path: 'my-plans',
          loadChildren: () => import('./my-plans/my-plans.module').then(m => m.MyPlansModule), //Lazy load my-plans module
        },
        {
          path: 'data-analyst',
          loadChildren: () => import('./data-analyst/data-analyst.module').then(m => m.DataAnalystModule), //Lazy load data-analyst module
        },
        {
          path: 'accounts',
          loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule), //Lazy load accounts module
        },
        {
          path: 'cohorts',
          loadChildren: () => import('./cohort/cohort.module').then(m => m.CohortModule), //Lazy load cohort module
        }

      ])
    ]
  },
  {
    path: '**', redirectTo: '/app/home', pathMatch: 'full'
  },
  // Fallback when no prior route is matched

  // { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
