import { createAsyncAction } from "@app/util";
import { createAction, props } from "@ngrx/store";

export const loadPrimaryViewReport = createAsyncAction(
    '[Frameworks] Load Primary view report',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAccounts = createAsyncAction(
    '[Accounts] Load Accounts',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadSchoolYearList = createAsyncAction(
    '[School Year] Load School Year',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const DownloadFrameworkTypeReport = createAsyncAction(
    '[Home] Download Framework Type Overview Report',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const downloadStudentLevelFrameworkReport = createAsyncAction(
    '[Home] Download Student Level Framework Report',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAnalyzedClinicalFrameWorks = createAsyncAction(
    '[Frameworks] Load Analyzed clinical framework',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAnalyzedClinicalFrameWorksPersonalized = createAsyncAction(
    '[Frameworks] Load Analyzed clinical framework Personalized',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAnalyzedClinicalFrameWorksScreening = createAsyncAction(
    '[Frameworks] Load Analyzed clinical framework Screening',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadGraphPrimaryViewReportByFrameworkV3 = createAsyncAction(
    '[Home] Load GraphPrimaryViewReportByFrameworkV3',
    props<{ payload: any }>(),
    props<{ items: any }>(),
    props<{ message: string }>()
);

export const loadListPrimaryViewReportByFrameworkV3 = createAsyncAction(
    '[Home] Load ListPrimaryViewReportByFrameworkV3',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);


export const loadGraphPrimaryViewReportByFrameworkV3Personalized = createAsyncAction(
    '[Home] Load GraphPrimaryViewReportByFrameworkV3 Personalized',
    props<{ payload: any }>(),
    props<{ items: any }>(),
    props<{ message: string }>()
);

export const loadListPrimaryViewReportByFrameworkV3Personalized = createAsyncAction(
    '[Home] Load ListPrimaryViewReportByFrameworkV3 Personalized',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadDashboardMetricsView = createAsyncAction(
    '[Home] Load DashboardMetricsView',
    props<{ payload: any }>(),
    props<{ items: any }>(),
    props<{ message: string }>()
);

export const loadScreenCohortViewReportByFrameworkV3 = createAsyncAction(
    '[Home] Load ScreenCohortViewReportByFrameworkV3',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadScreenCohortViewDillDownReport = createAsyncAction(
    '[Home] Load ScreenCohortViewDillDownReport',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadStudentDrillDownMeasureViz = createAsyncAction(
    '[Home] Load Student Drill Down MeasureViz',
    props<{ payload: any }>(),
    props<{ items: any }>(),
    props<{ message: string }>()
);

export const loadPrevalenceDrillDownList = createAsyncAction(
    '[Home] Load Prevalence Drill Down',
    props<{ payload: any }>(),
    props<{ items: any }>(),
    props<{ message: string }>()
);

export const loadStudentSummaryAndOnlineActivitiesWithDateFilter = createAsyncAction(
    '[Home] Load Student Summary and Online Activities With DateFilter Report',
    props<{ payload: any }>(),
    props<{ items: any }>(),
    props<{ message: string }>()

);
