<h3 translate></h3>
<img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />
<div class="alert alert-danger" *ngIf="error$ | async" translate>
  Email address is incorrect.
</div>
<p>Enter your email and we′ll send you instructions to reset your password</p>
<div class="form-style">
  <form novalidate>
    <div class="form-group">
      <label class="d-block">
        <input type="email" required class="form-control" id="email" name="emailAddress" #emailaddress="ngModel"
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" [(ngModel)]="emailAddress"
          [placeholder]="'Email Address' | translate" />
        <span hidden translate>Email Address</span>
        <div *ngIf="emailaddress.invalid && emailaddress.touched">
          <small *ngIf="emailaddress.errors?.['required']" class="text-danger" translate>
            Email address is required
          </small>
          <small *ngIf="emailaddress.errors?.['pattern']" class="text-danger" translate>
            Please enter a valid email address
          </small>
        </div>
      </label>
    </div>
    <button class="btn btn-primary w-100" (click)="sendResetLink()" [disabled]="emailaddress.invalid || isLoading"
      type="button">
      <span translate style="color: #fff;">Send Reset Link</span>
    </button>
  </form>
</div>