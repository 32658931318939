import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { createNewUser, deleteUserfromList, loadAccountList, loadAllSchools, loadUserById, loadUserList, loadUserRoles, updateUser } from "./users.actions";
import { AccountServiceProxy, AnalyzerServiceProxy, RoleServiceProxy, UserServiceProxy } from "@app/shared/service-proxies/service-proxies";

@Injectable()
export class UsersEffects {
    constructor(
        private actions$: Actions,
        private _userServiceProxy: UserServiceProxy,
        private _roleServiceProxyMountain: RoleServiceProxy,
        private _accountServiceProxy: AccountServiceProxy,
        private _analyzerServiceProxy: AnalyzerServiceProxy,
    ) { }
    loadUserList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUserList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userServiceProxy.paginate(
                    payload.payload.accountId,
                    payload.payload.pageIndex,
                    payload.payload.pageSize,
                    payload.payload.searchFilter,
                    payload.payload.userRoleSelect).pipe(
                        map((res: any) => {
                            if (res) {
                                return loadUserList.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to get all Users List.',
                                errorMapping: {},
                                failureAction: loadUserList.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to load all Users List.',
                            })
                        })
                    )
            })
        )
    })

    loadUserRoles$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUserRoles.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._roleServiceProxyMountain.getAll().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadUserRoles.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all UserRoles List.',
                            errorMapping: {},
                            failureAction: loadUserRoles.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all UserRoles List.',
                        })
                    })
                )
            })
        )
    })

    loadAccountList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAccountList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._accountServiceProxy.getAllAccounts(undefined).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAccountList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Accounts List.',
                            errorMapping: {},
                            failureAction: loadAccountList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Accounts List.',
                        })
                    })
                )
            })
        )
    })


    deleteUserfromList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(deleteUserfromList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userServiceProxy.deleteUser(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return deleteUserfromList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to delete user.',
                            errorMapping: {},
                            failureAction: deleteUserfromList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to delete user.',
                        })
                    })
                )
            })
        )
    })

    createNewUser$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(createNewUser.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userServiceProxy.createUser(payload.userToAddDto).pipe(
                    map((res: any) => {
                        if (res) {
                            return createNewUser.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to delete user.',
                            errorMapping: {},
                            failureAction: createNewUser.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to delete user.',
                        })
                    })
                )
            })
        )
    })

    loadAllSchools$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllSchools.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analyzerServiceProxy.getAllSchools(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllSchools.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to delete user.',
                            errorMapping: {},
                            failureAction: loadAllSchools.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to delete user.',
                        })
                    })
                )
            })
        )
    })


    updateUser$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(updateUser.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userServiceProxy.updateUser(
                    payload.payload.userId,
                    payload.payload.userToUpdateDto).pipe(
                    map((res: any) => {
                        if (res) {
                            return updateUser.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to update user.',
                            errorMapping: {},
                            failureAction: updateUser.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to update user.',
                        })
                    })
                )
            })
        )
    })

    loadUserById$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUserById.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._userServiceProxy.getById(payload.userId).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadUserById.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get user.',
                            errorMapping: {},
                            failureAction: loadUserById.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to get user.',
                        })
                    })
                )
            })
        )
    })
}