import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss']
})
export class WarningMessageComponent implements OnInit {

  name: any;
  message: any;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataObj: {
      id: any; name: any; message: any
    },
    public dialogRef: MatDialogRef<WarningMessageComponent>,
  ) {
    this.name = dataObj.name
    this.message = dataObj.message
  }

  ngOnInit(): void {
  }


  onMessageClose() {
    this.dialogRef.close({
      clicked: 'submit',
      id: this.dataObj.id,

    });
    this.close.emit("")
  }

}
