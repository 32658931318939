<securly-header class="custom-header" [headerIcon]="headerIcon">
  <div action-items class="d-flex">

    <!------------ App Switcher Start -------------->
    <div *ngIf="false" class="mt-1" #appSelectorDropdown="ngbDropdown" ngbDropdown placement="bottom-right" [autoClose]="'outside'">
      <a href="javascript:void(0)" class="d-inline-block nav-link btn p-0" ngbDropdownToggle id="appSwitcherDropdown"
        role="button">
        <i class="icon icon-apps m-0 mx-2"></i>
      </a>
      <div ngbDropdownMenu aria-labelledby="appSwitcherDropdown"
        class="appSwitcherDropdown dropdown-menu dropdown-menu-right">
        <div>
          <div class="dropdown-item dropdown-value section-title d-flex justify-content-between" ngbDropdownItem>
            <p class="dropdown-section-title regularBody font medium m-0">
              Switch to...
            </p>
          </div>
          <ng-container>
            <a ngbDropdownItem href="http://deviceconsole.securly.com/classroom" target="_blank" class="dropdown-item dropdown-value">
              <div class="dropdown-text dark-text font medium">
                <div class="app-logo">
                  <img src="assets/scss/logos/classroom.svg" alt=""  />
                </div>
                Classroom
              </div>
            </a>
            <a target="_blank" href="http://securly.com/app/login" class="dropdown-item dropdown-value" ngbDropdownItem>
              <div class="dropdown-text dark-text font medium">
                <div class="app-logo">
                  <img src="assets/scss/logos/filter-logo.svg" alt=""  />
                </div>
                Filter
              </div>
            </a>
            <a target="_blank" href="http://www.securly.com/app/aware/#/dashboard" class="dropdown-item dropdown-value"
              ngbDropdownItem>
              <div class="dropdown-text dark-text font medium">
                <div class="app-logo">
                  <img src="assets/scss/logos/aware.svg" alt="Aware" />
                </div>
                Aware
              </div>
            </a>

            <a target="_blank" href="" class="dropdown-item dropdown-value"
              ngbDropdownItem>
              <div class="dropdown-text dark-text font medium">
                <div class="app-logo">
                  <img src="assets/scss/logos/visitor-logo.svg" alt="" />
                </div>
                Visitor
              </div>
            </a>
          </ng-container>

          <ng-container>
            <div class="dropdown-item dropdown-value section-title" ngbDropdownItem>
              <p class="dropdown-section-title caption font medium m-0">
                {{ 'Discover' | uppercase }}
              </p>
            </div>
            <ng-container>
              <a href="https://dyknow.me" target="_blank" class="dropdown-item dropdown-value"
                ngbDropdownItem>
                <div class="dropdown-text dark-text font medium">
                  <div class="app-logo">
                    <img src="assets/scss/logos/reveal-logo.svg" alt="" />
                  </div>
                  Reveal
                </div>
              </a>
              <a href="https://rhithm.app" target="_blank" class="dropdown-item dropdown-value" ngbDropdownItem>
                <div class="dropdown-text dark-text font medium">
                  <div class="app-logo">
                    <img class="rhithm" src="assets/scss/logos/rhithm.svg" alt="" />
                  </div>
                  Rhithm
                </div>
              </a>
            </ng-container>
            <a href="https://www.securly.com/360-cloud" target="_blank" class="dropdown-item dropdown-value"
              ngbDropdownItem>
              <div class="dropdown-text dark-text font medium">
                <div class="app-logo"><i class="icon icon-e-add"></i></div>
                More Securly products...
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </div>

    <!------------ App Switcher End -------------->

    <div ngbDropdown>
      <a id="asd-dropdown" class="nav-link" ngbDropdownToggle>
        <div class="dropdwon-toggle">
          <mat-icon svgIcon="icon-border-user"></mat-icon>
          <mat-icon svgIcon="icon-angledown"></mat-icon>
        </div>
      </a>
      <div ngbDropdownMenu aria-labelledby="asd-dropdown"
        class="userAccountDropdown appSettingsDropdown dropdown-menu dropdown-menu-right">
        <div class="dropdown-item">
          <mat-icon svgIcon="icon-border-user"></mat-icon>
          <span>{{userService?.user?.email}}</span>
        </div>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item" (click)="logout()">
          <mat-icon svgIcon="icon-out"></mat-icon>
          <span>Logout</span>
        </div>

      </div>
    </div>
  </div>
</securly-header>