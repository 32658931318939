import { ClinicalFrameworkToAddDto, ClinicalFrameworkToListDtoForV2ListIDataResult, ClinicalFrameworkToListDtoIDataResult, ClinicalFrameworkToUpdateDto, IResult } from "@app/shared/service-proxies/service-proxies";
import { loadChangeStatusAPIResponse, loadClinicalFramework, loadCloneAPIResponse, loadCreateAPIResponse, loadDeleteAPIResponse, loadDetailAPIResponse, loadUpdateAPIResponse } from "./frameworks.actions";

export interface State {
    clinicalFrameworkListPayload: { pageIndex: number, pageSize: number };
    clinicalFrameworkList: ClinicalFrameworkToListDtoForV2ListIDataResult;

    deleteclinicalFrameworkPayload: { id: number };
    deleteclinicalFrameworkResponse: IResult;

    cloneclinicalFrameworkPayload: { id: number };
    cloneclinicalFrameworkResponse: IResult;

    changeStatusclinicalFrameworkPayload: { id: number };
    changeStatusclinicalFrameworkResponse: IResult;

    createclinicalFrameworkPayload: ClinicalFrameworkToAddDto;
    createclinicalFrameworkResponse: IResult;

    updateclinicalFrameworkPayload:{id: number, data:ClinicalFrameworkToUpdateDto} ;
    updateclinicalFrameworkResponse: IResult;

    getDetailclinicalFrameworkPayload:{id: number} ;
    getDetailclinicalFrameworkResponse: ClinicalFrameworkToListDtoIDataResult

}

const initialState: State = {
    clinicalFrameworkListPayload: null,
    clinicalFrameworkList: null,

    deleteclinicalFrameworkPayload: null,
    deleteclinicalFrameworkResponse: null,

    cloneclinicalFrameworkPayload: null,
    cloneclinicalFrameworkResponse: null,

    changeStatusclinicalFrameworkPayload: null,
    changeStatusclinicalFrameworkResponse: null,

    createclinicalFrameworkPayload: null,
    createclinicalFrameworkResponse: null,

    updateclinicalFrameworkPayload: null,
    updateclinicalFrameworkResponse: null,

    getDetailclinicalFrameworkPayload: null,
    getDetailclinicalFrameworkResponse: null
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

//----------------get Framework List-----------------------------------
        case loadClinicalFramework.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                clinicalFrameworkListPayload: requestPayload,
            });
        }
        case loadClinicalFramework.success.type: {

            return Object.assign({}, state, {
                clinicalFrameworkList: action,
            })
        }
 //----------------Delete-----------------------------------
        case loadDeleteAPIResponse.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                deleteclinicalFrameworkPayload: requestPayload,
            });
        }
        case loadDeleteAPIResponse.success.type: {
            return Object.assign({}, state, {
                deleteclinicalFrameworkResponse: action,
            })
        }

 //----------------Clone-----------------------------------
        case loadCloneAPIResponse.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                cloneclinicalFrameworkPayload: requestPayload,
            });
        }
        case loadCloneAPIResponse.success.type: {
            return Object.assign({}, state, {
                cloneclinicalFrameworkResponse: action,
            })
        }

 //----------------Change Status-----------------------------------
        case loadChangeStatusAPIResponse.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                changeStatusclinicalFrameworkPayload: requestPayload,
            });
        }
        case loadChangeStatusAPIResponse.success.type: {
            return Object.assign({}, state, {
                changeStatusclinicalFrameworkResponse: action,
            })
        }

//----------------Create-----------------------------------
        case loadCreateAPIResponse.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createclinicalFrameworkPayload: requestPayload,
            });
        }
        case loadCreateAPIResponse.success.type: {
            return Object.assign({}, state, {
                createclinicalFrameworkResponse: action,
            })
        }

//----------------Update-----------------------------------
        case loadUpdateAPIResponse.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                updateclinicalFrameworkPayload: requestPayload,
            });
        }
        case loadUpdateAPIResponse.success.type: {
            return Object.assign({}, state, {
                updateclinicalFrameworkResponse: action,
            })
        }

//----------------Get Details by Id-----------------------------------
        case loadDetailAPIResponse.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getDetailclinicalFrameworkPayload: requestPayload,
            });
        }
        case loadDetailAPIResponse.success.type: {
            return Object.assign({}, state, {
                getDetailclinicalFrameworkResponse: action,
            })
        }
        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getClinicalFrameworks = (state: State) => state.clinicalFrameworkList;
export const deleteclinicalFrameworkResponse = (state: State) => state.deleteclinicalFrameworkResponse;
export const cloneClinicalFrameworksResponse = (state: State) => state.cloneclinicalFrameworkResponse;
export const changeStatusClinicalFrameworksResponse = (state: State) => state.changeStatusclinicalFrameworkResponse;
export const createClinicalFrameworks = (state: State) => state.createclinicalFrameworkResponse;
export const updateClinicalFrameworks = (state: State) => state.updateclinicalFrameworkResponse;
export const detailClinicalFrameworks = (state: State) => state.getDetailclinicalFrameworkResponse;