import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./app/auth/auth.module').then(m => m.AuthModule), //Lazy load account module
    data: { preload: true }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),

  ],
  exports: [RouterModule],
  providers: [

  ]
})
export class RootRoutingModule { }