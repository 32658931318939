import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { DownloadFrameworkTypeReport, downloadStudentLevelFrameworkReport, loadAccounts, loadAnalyzedClinicalFrameWorks, loadAnalyzedClinicalFrameWorksPersonalized, loadAnalyzedClinicalFrameWorksScreening, loadDashboardMetricsView, loadGraphPrimaryViewReportByFrameworkV3, loadGraphPrimaryViewReportByFrameworkV3Personalized, loadListPrimaryViewReportByFrameworkV3, loadListPrimaryViewReportByFrameworkV3Personalized, loadPrevalenceDrillDownList, loadPrimaryViewReport, loadSchoolYearList, loadScreenCohortViewDillDownReport, loadScreenCohortViewReportByFrameworkV3, loadStudentDrillDownMeasureViz, loadStudentSummaryAndOnlineActivitiesWithDateFilter } from "./home.actions";
import { AccountServiceProxy, ClinicalFrameworkServiceProxy, DashboardDataVizReportServiceProxy, DashboardReportServiceProxy } from "@app/shared/service-proxies/service-proxies";

@Injectable()
export class HomeEffects {
    constructor(
        private actions$: Actions,
        public dashboardReportServiceProxy: DashboardReportServiceProxy,
        public _dashboardDataVizReportServiceProxy: DashboardDataVizReportServiceProxy,
        public accountServiceProxy: AccountServiceProxy,
        private clinicalFrameworkService: ClinicalFrameworkServiceProxy,
        public dashboardDataVizReportServiceProxy: DashboardDataVizReportServiceProxy,
    ) { }
    loadPrimaryViewReport$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadPrimaryViewReport.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardReportServiceProxy.getPrimaryViewReportByVariable(payload.customerTextId).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadPrimaryViewReport.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Primary View Report.',
                            errorMapping: {},
                            failureAction: loadPrimaryViewReport.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Primary View Report.',
                        })
                    })
                )
            })
        )
    })


    loadAccounts$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAccounts.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.accountServiceProxy.getAllAccounts(undefined).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAccounts.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Accounts.',
                            errorMapping: {},
                            failureAction: loadAccounts.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Accounts.',
                        })
                    })
                )
            })
        )
    })

    loadSchoolYearList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadSchoolYearList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                
                return  this.dashboardReportServiceProxy.getSchoolYearList(payload.accountId).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadSchoolYearList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all School Year.',
                            errorMapping: {},
                            failureAction: loadSchoolYearList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all School Year.',
                        })
                    })
                )
            })
        )
    })
    DownloadFrameworkTypeReport$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(DownloadFrameworkTypeReport.init),
            map((action: any) => action),

            mergeMap((payload) => {
                
                return  this._dashboardDataVizReportServiceProxy.downloadFrameworkTypeOverviewReport(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            res.clinicalFrameworkType= payload.clinicalFrameworkType;
                            return DownloadFrameworkTypeReport.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Download Framework Type Overview Report.',
                            errorMapping: {},
                            failureAction: DownloadFrameworkTypeReport.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Download Framework Type Overview Report.',
                        })
                    })
                )
            })
        )
    })
    downloadStudentLevelFrameworkReport$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(downloadStudentLevelFrameworkReport.init),
            map((action: any) => action),

            mergeMap((payload) => {
                
                return  this._dashboardDataVizReportServiceProxy.downloadStudentLevelFrameworkReport(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return downloadStudentLevelFrameworkReport.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to Download Student Level Framework  Report.',
                            errorMapping: {},
                            failureAction: downloadStudentLevelFrameworkReport.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to Download Student Level Framework  Report.',
                        })
                    })
                )
            })
        )
    })

    loadAnalyzedClinicalFrameWorks$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalyzedClinicalFrameWorks.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.clinicalFrameworkService.getAnalyzedClinicalFrameWorks(payload.accountId, payload.ClinicalFrameworkType).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalyzedClinicalFrameWorks.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Analyzed Clinical Framework .',
                            errorMapping: {},
                            failureAction: loadAnalyzedClinicalFrameWorks.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Analyzed Clinical Framework.',
                        })
                    })
                )
            })
        )
    })

    loadAnalyzedClinicalFrameWorksPersonalized$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalyzedClinicalFrameWorksPersonalized.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.clinicalFrameworkService.getAnalyzedClinicalFrameWorks(payload.accountId, payload.ClinicalFrameworkType).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalyzedClinicalFrameWorksPersonalized.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Analyzed Clinical Framework Personalized.',
                            errorMapping: {},
                            failureAction: loadAnalyzedClinicalFrameWorksPersonalized.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Analyzed Clinical Framework Personalized.',
                        })
                    })
                )
            })
        )
    })

    loadAnalyzedClinicalFrameWorksScreening$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAnalyzedClinicalFrameWorksScreening.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.clinicalFrameworkService.getAnalyzedClinicalFrameWorks(payload.accountId, payload.ClinicalFrameworkType).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAnalyzedClinicalFrameWorksScreening.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Analyzed Clinical Framework Screening.',
                            errorMapping: {},
                            failureAction: loadAnalyzedClinicalFrameWorksScreening.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Analyzed Clinical Framework Screening.',
                        })
                    })
                )
            })
        )
    })

    loadGraphPrimaryViewReportByFrameworkV3$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadGraphPrimaryViewReportByFrameworkV3.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy.getGraphPrimaryViewReportByFrameworkV3(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadGraphPrimaryViewReportByFrameworkV3.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all graphPrimaryViewReportByFrameworkV3 .',
                            errorMapping: {},
                            failureAction: loadGraphPrimaryViewReportByFrameworkV3.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all graphPrimaryViewReportByFrameworkV3.',
                        })
                    })
                )
            })
        )
    })

    loadListPrimaryViewReportByFrameworkV3$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadListPrimaryViewReportByFrameworkV3.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy.getListPrimaryViewReportByFrameworkV3(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadListPrimaryViewReportByFrameworkV3.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all listPrimaryViewReportByFrameworkV3 .',
                            errorMapping: {},
                            failureAction: loadListPrimaryViewReportByFrameworkV3.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all listPrimaryViewReportByFrameworkV3.',
                        })
                    })
                )
            })
        )
    })

    loadGraphPrimaryViewReportByFrameworkV3Personalized$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadGraphPrimaryViewReportByFrameworkV3Personalized.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy.getGraphPrimaryViewReportByFrameworkV3(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadGraphPrimaryViewReportByFrameworkV3Personalized.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all graphPrimaryViewReportByFrameworkV3 Personalized.',
                            errorMapping: {},
                            failureAction: loadGraphPrimaryViewReportByFrameworkV3Personalized.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all graphPrimaryViewReportByFrameworkV3 Personalized.',
                        })
                    })
                )
            })
        )
    })

    loadListPrimaryViewReportByFrameworkV3Personalized$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadListPrimaryViewReportByFrameworkV3Personalized.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy.getListPrimaryViewReportByFrameworkV3(payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadListPrimaryViewReportByFrameworkV3Personalized.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all listPrimaryViewReportByFrameworkV3 Personalized.',
                            errorMapping: {},
                            failureAction: loadListPrimaryViewReportByFrameworkV3Personalized.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all listPrimaryViewReportByFrameworkV3 Personalized.',
                        })
                    })
                )
            })
        )
    })

    loadDashboardMetricsView$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadDashboardMetricsView.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy.getDashboardMetricsView(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadDashboardMetricsView.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all loadDashboardMetricsView .',
                            errorMapping: {},
                            failureAction: loadDashboardMetricsView.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all loadDashboardMetricsView.',
                        })
                    })
                )
            })
        )
    })

    loadScreenCohortViewReportByFrameworkV3$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadScreenCohortViewReportByFrameworkV3.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy.getScreenCohortViewReportByFrameworkV3(payload.customerTextId, payload.selectedYearSchool, payload.frameworkIDs, payload.hideNotScreened).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadScreenCohortViewReportByFrameworkV3.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all loadScreenCohortViewReportByFrameworkV3 .',
                            errorMapping: {},
                            failureAction: loadScreenCohortViewReportByFrameworkV3.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all loadScreenCohortViewReportByFrameworkV3.',
                        })
                    })
                )
            })
        )
    })

    loadScreenCohortViewDillDownReport$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadScreenCohortViewDillDownReport.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardDataVizReportServiceProxy
                .getScreenCohortViewDrillDownReport(payload.dto).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadScreenCohortViewDillDownReport.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all loadScreenCohortViewDillDownReport .',
                            errorMapping: {},
                            failureAction: loadScreenCohortViewDillDownReport.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all loadScreenCohortViewDillDownReport.',
                        })
                    })
                )
            })
        )
    })


    loadStudentDrillDownMeasureViz$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadStudentDrillDownMeasureViz.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this.dashboardReportServiceProxy.getStudentDrillDownMeasureViz(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadStudentDrillDownMeasureViz.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Student Drill Down Measure Viz .',
                            errorMapping: {},
                            failureAction: loadStudentDrillDownMeasureViz.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Student Drill Down Measure Viz.',
                        })
                    })
                )
            })
        )
    })


    loadPrevalenceDrillDownList$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadPrevalenceDrillDownList.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._dashboardDataVizReportServiceProxy.getStudentPrevalenceAcrossAnalysisDrillDown(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadPrevalenceDrillDownList.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Prevalence Drill Down Viz .',
                            errorMapping: {},
                            failureAction: loadPrevalenceDrillDownList.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Prevalence Drill Down Viz.',
                        })
                    })
                )
            })
        )
    })


    loadStudentSummaryAndOnlineActivitiesWithDateFilter$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadStudentSummaryAndOnlineActivitiesWithDateFilter.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._dashboardDataVizReportServiceProxy.getStudentSummaryAndOnlineActivitiesForStudentWithDateFilterReport(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadStudentSummaryAndOnlineActivitiesWithDateFilter.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Student Summary And Online Activities With DateFilter .',
                            errorMapping: {},
                            failureAction: loadStudentSummaryAndOnlineActivitiesWithDateFilter.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Student Summary And Online Activities With DateFilter.',
                        })
                    })
                )
            })
        )
    })
}