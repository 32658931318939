import { Component, Inject, OnInit } from '@angular/core';
import * as reducers from '../../../../reducers';
import { Subject, skip, takeUntil } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ERole } from 'src/assets/enums/profileEnum';
import { UserService } from '@app/shared/services/user.service';
import { Store } from '@ngrx/store';
import { AccountServiceProxy, AnalyzerServiceProxy, AppVersion, GetAllSchoolsRequest, GetAllSchoolsResponseIDataResult, UserInviteServiceProxy, UserInviteToAddDto } from '@app/shared/service-proxies/service-proxies';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
import { Router } from '@angular/router';
import { loadAccountList, loadUserRoles } from '@app/invites/invites.actions';
import { createUserInvite } from '@app/invites/invites.actions';
import { AppConsts } from '@app/shared/AppConsts';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-create-user-invite',
  templateUrl: './create-user-invite.component.html',
  styleUrls: ['./create-user-invite.component.scss']
})
export class CreateUserInviteComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  hide = true;
  roleList: any[] = [];
  domains: any;
  domainList: any = [];
  addOnBlur = true;
  accountsList: any[] = [];
  filteredaccountsList: any = []
  customerSearchControl = new FormControl();
  // readonly separatorKeysCodes = [ENTER, COMMA] as const;
  createInivteForm: FormGroup;
  eRole = ERole;
  schoolSearchControl = new FormControl();
  filteredschoolList: any = [];
  schoolList: any = [];
  isLoading: boolean = false;

  constructor(
    public userService: UserService,
    private store: Store<reducers.State>,
    private _analyzerServiceProxy: AnalyzerServiceProxy,
    public _accountServiceProxy: AccountServiceProxy,
    private _snackBarService: SecurlySnackBarService,
    private _userInviteServiceProxy: UserInviteServiceProxy,
    private formbuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<CreateUserInviteComponent>,
    private _router: Router,
  ) {
    this.customerSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredaccountsList = this.accountsList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });

    this.schoolSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredschoolList = this.schoolList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });
  }

  ngOnInit(): void {
    this.initform()
    this.roleServiceProxy();
    this.store
      .select(reducers.getInivteUserRolesList)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((res) => {
        let data = res.data && res.data || [];
        this.roleList.push(...data);
        this.roleList.shift()
        this.createInivteForm.controls['roleId'].setValue(this.roleList[0]);
        this.setvalidation();
        if (this.userService.user?.role?.key == this.eRole.AccountAdmin) {
          this.roleList.shift();
        }
      });

    this.getAccountsList();
    this.store
      .select(reducers.getinviteAccountList)
      .pipe(skip(1), takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.data) {
          this.accountsList = res.data;
          if (this.modalData?.id) {
            let preSelectedAccount = this.accountsList.filter(x => x.customerEmail == this.modalData.email)[0]
            this.createInivteForm.controls['accountId'].setValue(preSelectedAccount)
          }
          this.filteredaccountsList = this.accountsList;
        }
      });
  }


  getAccountsList() {
    this.store.dispatch(loadAccountList.init({ isForSecurlySync: false }));
  }
  roleServiceProxy() {
    this.store.dispatch(loadUserRoles.init({}));
  }

  get f() {
    return this.createInivteForm.controls;
  }

  onSave() {
    this.isLoading = true;
    let userInviteToAddDto = new UserInviteToAddDto();
    userInviteToAddDto.appVersion = AppVersion.AppBaseUrlV2;
    userInviteToAddDto = this.createInivteForm.value;
    userInviteToAddDto.accountId = this.createInivteForm.controls['accountId'].value?.id;
    userInviteToAddDto.roleId = this.createInivteForm.controls['roleId'].value?.roleId;
    userInviteToAddDto.customerTextId = this.createInivteForm.controls['accountId'].value?.customerEmail ? this.createInivteForm.controls['accountId'].value?.customerEmail : '';
    userInviteToAddDto.schoolIds = this.createInivteForm.controls['schoolIds'].value ?? null;
    userInviteToAddDto.appVersion = AppVersion.AppBaseUrlV2;
    this.store.dispatch(createUserInvite.init({ ...userInviteToAddDto }));
    this.store.select(reducers.createUserInvites).pipe(skip(1), takeUntil(this.destroy$)).subscribe(
      (response: any) => {
        if (response.success == true) {
          this.isLoading = false;
          this.dialogRef.close(true);
          this._snackBarService.open(response.message, SecurlySnackBarType.Success);
        } else {
          this.isLoading = false;
          this._snackBarService.open(response.message, SecurlySnackBarType.Failure);
          // this.toastr.error(response.message);
        }
      },
      (error: any) => {
        this.isLoading = false;
        const errorResponse = JSON.parse(error.response);
        const errorResponseMessage = errorResponse.data.Password.errors[0].errorMessage
        if (error.status === 400 && errorResponse && errorResponseMessage) {
          // this.toastr.error(errorResponseMessage);
        } else {
          // Handle other errors
          // this.toastr.error(error.message);
        }
      });
  }

  onRoleSelected() {
    this.setvalidation();
    if (this.f['roleId'].value?.roleId == 3 && this.createInivteForm.controls['accountId'].value?.id) {
      this.getSchoolList();
    }
    if (this.userService.user?.role?.key == this.eRole.AccountAdmin) {
      this.getSchoolList();
    }
  }

  onAccountChange() {
    if (this.f['roleId'].value?.roleId == 3) {
      this.getSchoolList();
    }
  }

  getSchoolList() {

    let payload = {
      selectedAccountId: (this.createInivteForm.controls['accountId'].value).id
    } as GetAllSchoolsRequest
    this._analyzerServiceProxy.getAllSchools(payload).subscribe((response: GetAllSchoolsResponseIDataResult) => {
      if (response.success) {
        this.schoolList = response.data.schools;
        this.filteredschoolList = response.data.schools;
      }
    })
  }

  setvalidation() {
    this.createInivteForm.controls['schoolIds'].setValue([]);

    if (this.userService.user?.role?.key == this.eRole.AccountAdmin) {
      this.createInivteForm.controls['accountId'].setValue('');
    }
    if (this.f['roleId'].value?.roleId == 2 || this.f['roleId'].value?.roleId == 3) {
      this.createInivteForm.controls['accountId'].setValidators([Validators.required]);
    } else {
      this.createInivteForm.controls['accountId'].clearValidators();
    }

    if (this.f['roleId'].value?.roleId == 3) {
      this.createInivteForm.controls['schoolIds'].setValidators([Validators.required]);
    } else {
      this.createInivteForm.controls['schoolIds'].clearValidators();
    }

    this.createInivteForm.controls['schoolIds'].updateValueAndValidity();
    this.createInivteForm.controls['accountId'].updateValueAndValidity();

  }



  initform() {
    this.createInivteForm = this.formbuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      lastName: new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z][a-zA-Z ]+")]),
      email: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
      customerTextId: new FormControl(''),
      roleId: new FormControl('', [Validators.required]),
      accountId: new FormControl(null, [Validators.required]),
      userInviteType: new FormControl(AppConsts.discernInviteType, [Validators.required]),
      schoolIds: new FormControl([]),
    });
    if (this.userService.user?.role?.key != this.eRole.CampusAdmin) {
      this.createInivteForm.controls['roleId'].clearValidators();
      this.createInivteForm.controls['roleId'].updateValueAndValidity();

      this.createInivteForm.controls['accountId'].clearValidators();
      this.createInivteForm.controls['accountId'].updateValueAndValidity();
    }
    if (this.userService.user?.role?.key == this.eRole.AccountAdmin) {
      this.createInivteForm.controls['accountId'].setValue({ customerEmail: this.userService.user?.customerTextId, id: this.userService.user?.accountId })
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
