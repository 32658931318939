import { AccountDtoListIDataResult, GetAllSchoolsResponseIDataResult, RoleToListDtoListIDataResult, UserToListDtoIDataResult, UserToListDtoPaginatedListIDataResult } from "@app/shared/service-proxies/service-proxies";
import { createNewUser, deleteUserfromList, loadAccountList, loadAllSchools, loadUserById, loadUserList, loadUserRoles, updateUser } from "./users.actions";

export interface State {
    userListPayload: any;
    userList: UserToListDtoPaginatedListIDataResult;
    userRolesListPayload: any;
    userRolesList: RoleToListDtoListIDataResult
    accountListPayload: any;
    accountList: AccountDtoListIDataResult
    deleteUserPayload: any;
    deleteUser: any;
    createUserPayload: any;
    createUser: any;
    allSchoolsPayload: any;
    allSchools: GetAllSchoolsResponseIDataResult;
    updateUserPayload: any;
    updateUser: any;
    userByIdPayload: any;
    userById: UserToListDtoIDataResult



}

const initialState: State = {
    userListPayload: null,
    userList: null,
    userRolesListPayload: null,
    userRolesList: null,
    accountListPayload: null,
    accountList: null,
    deleteUserPayload: null,
    deleteUser: null,
    createUserPayload: null,
    createUser: null,
    allSchoolsPayload: null,
    allSchools: null,
    updateUserPayload: null,
    updateUser: null,
    userByIdPayload: null,
    userById: null,
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        // User List
        case loadUserList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                userListPayload: requestPayload,
            });
        }
        case loadUserList.success.type: {

            return Object.assign({}, state, {
                userList: action,
            })
        }

        // UserRoles List
        case loadUserRoles.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                userRolesListPayload: requestPayload,
            });
        }
        case loadUserRoles.success.type: {

            return Object.assign({}, state, {
                userRolesList: action,
            })
        }

        // Accounts List
        case loadAccountList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                accountListPayload: requestPayload,
            });
        }
        case loadAccountList.success.type: {

            return Object.assign({}, state, {
                accountList: action,
            })
        }

        // Delete User
        case deleteUserfromList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                deleteUserPayload: requestPayload,
            });
        }
        case deleteUserfromList.success.type: {

            return Object.assign({}, state, {
                deleteUser: action,
            })
        }

        // Create User
        case createNewUser.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createUserPayload: requestPayload,
            });
        }
        case createNewUser.success.type: {
            return Object.assign({}, state, {
                createUser: action,
            })
        }

        // load All Schools
        case loadAllSchools.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                allSchoolsPayload: requestPayload,
            });
        }
        case loadAllSchools.success.type: {
            return Object.assign({}, state, {
                allSchools: action,
            })
        }

        // Update User
        case updateUser.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                updateUserPayload: requestPayload,
            });
        }
        case updateUser.success.type: {
            return Object.assign({}, state, {
                updateUser: action,
            })
        }

        // get user by Id (Edit)
        case loadUserById.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                userByIdPayload: requestPayload,
            });
        }
        case loadUserById.success.type: {
            return Object.assign({}, state, {
                userById: action,
            })
        }

        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getUserList = (state: State) => state.userList;
export const getUserRolesList = (state: State) => state.userRolesList;
export const getAccountList = (state: State) => state.accountList;
export const deleteUser = (state: State) => state.deleteUser;
export const createUser = (state: State) => state.createUser;
export const getallSchools = (state: State) => state.allSchools;
export const getUpdatedUsers = (state: State) => state.updateUser;
export const getUserById = (state: State) => state.userById;


