import { DetailedReportingEffects } from './app/detailed-reporting/deatailed-reporting.effects';
import { NgModule } from '@angular/core';
import { HashLocationStrategy } from '@angular/common';
import { RootComponent } from './root.component';
import { RootRoutingModule } from './root-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppModule, metaReducers } from '@app/app.module';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { AngularMaterialModule } from '@app/shared/angular-material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { StoreModule } from '@ngrx/store';

import { reducers } from './app/reducers/index';
import { EffectsModule } from '@ngrx/effects';
import { RouterEffects } from '@app/router/router.effects';
import { AppEffects } from '@app/app.effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared';
import { CustomRouterStateSerializer } from '@app/router/utils';
import { CoreService } from '@app/core/core.service';
import { WindowRef } from '@app/core/window.interface';
import { HttpClientModule } from '@angular/common/http';
import { SharedEffects } from '@app/shared/shared.effects';
import { SecurlySnackBarModule } from '@securly-engineering/securly-styles';
import { FrameworkEffects } from '@app/frameworks/frameworks.effects';
import { HomeEffects } from '@app/home/home.effects';
import { AnalysisBuilderEffects } from '@app/analysis-builder/analysis-builder.effects';
import { MyAnalysisEffects } from '@app/my-analysis/my-analysis.effects';
import { UsersEffects } from '@app/users/users.effects';
import { InvitesEffects } from '@app/invites/invites.effects';
import { AccountsEffects } from '@app/accounts/accounts.effects';
import { AuthEffects } from '@app/auth/auth.effects';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  // fgsColor: "red",
  // fgsPosition: POSITION.centerCenter,
  // fgsSize: 60,
  // fgsType: SPINNER.doubleBounce, // foreground spinner type
  // bgsColor: "red",
  bgsPosition: POSITION.centerCenter,
  bgsSize: 60,
  bgsType: SPINNER.doubleBounce, // foreground spinner type
  // pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  // pbThickness: 5, // progress bar thickness
  masterLoaderId: "master",
};

@NgModule({
  declarations: [
    RootComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RootRoutingModule,
    AppModule,
    MatDialogModule,
    MatIconModule,
    AngularMaterialModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({
      showForeground: false,
      loaderId: 'master',
      excludeRegexp: [
        '/api/Account/GetAllAccounts',
        '/api/ClinicalFramework/GetAnalyzedClinicalFrameWorks',
        '/api/Analyzer/GetAllSchools',
        '/api/DashboardReport/GetSchoolYearList',
        '/api/DashboardDataVizReport/GetPrimaryReportByVariableForPersonalize',
        '/api/DashboardDataVizReport/GetVariablesPresenceRateBySchool',
        '/api/DashboardDataVizReport/GetVariablesPresenceRateByStudent',
        '/api/DashboardDataVizReport/GetDistrictChangeOverTimeView',
        '/api/ClinicalFramework/GetClinicalFrameworkVariableListByClinicalFrameworkId',
        '/api/ClinicalFramework/GetPromptPreview',
        '/api/DashboardDataVizReport/GetDistrictPrevalenceAcrossAnalysisView',
        '/api/DashboardDataVizReport/GetPrimaryReportByVariableForReportCard',
        '/api/DashboardDataVizReport/GetScreenReportCard',
        '/api/DashboardDataVizReport/GetScreenSchoolView',
        '/api/DashboardDataVizReport/GetListStudentFrameworkPresenceView',
        '/api/DashboardDataVizReport/GetVariableReportCardView',
      ],
    }),
ServiceWorkerModule.register('./ngsw-worker.js', {
  enabled: environment.production,
}),
  // StoreModule.forRoot(REDUCER_TOKEN, {
  //   metaReducers,
  //   runtimeChecks: {
  //     strictStateImmutability: true,
  //     strictActionImmutability: true
  //   }
  // }),
  StoreModule.forRoot(reducers, { metaReducers }),
  EffectsModule.forRoot([RouterEffects, AppEffects, SharedEffects, HomeEffects, FrameworkEffects, AnalysisBuilderEffects, MyAnalysisEffects, UsersEffects, InvitesEffects,
    AccountsEffects, AuthEffects, DetailedReportingEffects
  ]),
  StoreRouterConnectingModule.forRoot({
    serializer: FullRouterStateSerializer,
  }),
  StoreDevtoolsModule.instrument({ maxAge: 25 }),

  TranslateModule.forRoot(),

  SharedModule,
  SecurlySnackBarModule
  ],
providers: [
  AppSessionService,
  CoreService,
  WindowRef,
  { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
  { provide: Location, useClass: HashLocationStrategy },
],
  bootstrap: [RootComponent],
})
export class RootModule { }
