import { Component, OnInit, OnDestroy } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(

  ) {


  }

  async ngOnInit() {
    // this._realTimeUpdatePusherService.registerEvents();
  }

  ngOnDestroy() {
  }
}
