
import { APP_INITIALIZER, NgModule } from '@angular/core';
import * as ngCommon from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { storeFreeze } from 'ngrx-store-freeze';
import { CoreModule, ErrorHandlerInterceptor } from '@core';
import { SharedModule } from './shared';
import { ShellModule } from './shell/shell.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
export const metaReducers = [storeFreeze];
import {
  RouterStateSerializer,
} from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './router/utils';
import { HashLocationStrategy } from '@angular/common';
import { CacheInterceptor } from './@core/http/cache-interceptor';
import { PusherCachingServiceProxy } from './shared/service-proxies/service-proxies';
import { AppInitializer } from './app-initializer';
import { JwtInterceptor } from './shared/interceptors/jwt.interceptor';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
  NgxUiLoaderHttpModule,
} from "ngx-ui-loader";
import { RhithmHttpInterceptor } from './shared/interceptors/rhithmHttpInterceptor';


@NgModule({
  imports: [
    ngCommon.CommonModule,
    SharedModule,
    ShellModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
   
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitializer: AppInitializer) => appInitializer.init(),
      deps: [AppInitializer],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RhithmHttpInterceptor,
      multi: true
    },
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    { provide: Location, useClass: HashLocationStrategy },
    PusherCachingServiceProxy
  ],

})
export class AppModule { }
