import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { CredentialsService } from './credentials.service';

@Injectable()
export class AccountRouteGuard implements CanActivate {

    constructor(
        private _router: Router,
        private _sessionService: AppSessionService,
        private _credentialsService: CredentialsService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this._credentialsService.getCookie('DiscernUserCookieInfo')
        if (user) {
            this._router.navigate([this.selectBestRoute()]);
            return false;
        }
        return true;
    }

    selectBestRoute(): string {
        return '/app/home';
    }
}
