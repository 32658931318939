import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/i18n';
import { AccountComponent } from './account/account.component';
import { LoginEmailComponent } from './login-email/login-email.component';
import { AccountRouteGuard } from './account-route-guard';
import { RegisterComponent } from './register/register.component';
import { PasswordChangedMessageComponent } from './password-changed-message/password-changed-message.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmMailComponent } from './confirm-mail/confirm-mail.component';

const routes: Routes = [
  {
    path: '',
    component: AccountComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'loginemail',
      },
      {
        path: 'loginemail',
        component: LoginEmailComponent,
        canActivate: [AccountRouteGuard],
        data: { title: extract('Loginemail') },
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: { title: extract('forgot-password') },
      },
      {
        path: 'forgot-password/confirm-mail',
        component: ConfirmMailComponent,
        data: { title: extract('confirm-mail') }
      },
      {
        path: 'accept-user-invite',
        component: RegisterComponent,
        data: { title: extract('accept-user-invite') }
      },
      {
        path: 'accept-user-invite/confirmed',
        component: PasswordChangedMessageComponent,
        data: { title: extract('confirmed') }
      },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'reset-password/confirmed', component: PasswordChangedMessageComponent },
      { path: '**', redirectTo: '/auth/loginemail', pathMatch: 'full' },
      
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule { }
