import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";

export const loadUserInviteByCode = createAsyncAction(
    '[Auth] Load User Invite By Code',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const acceptUserInvite = createAsyncAction(
    '[Auth] Accept User Invite',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const forgetPassword = createAsyncAction(
    '[Auth] Forget User Password',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const resetPassword = createAsyncAction(
    '[Auth] Reset User Password',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const validateResetPasswordLink = createAsyncAction(
    '[Auth] Validate Reset Password Link',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);