import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
    selector: 'app-custom-message',
    templateUrl: './custom-message.component.html',
    styleUrls: ['./custom-message.component.scss']
})
export class CustomMessageComponent {
    name :any;
    message:any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public  dataObj: {
            id: any; name: any;message:any
          },
        public dialogRef: MatDialogRef<CustomMessageComponent>
    ){
        this.name = dataObj.name
        this.message = dataObj.message
    }
    @Output() close: EventEmitter<any> = new EventEmitter<any>();


    success() {

        this.dialogRef.close({
             clicked: 'submit',
             id: this.dataObj.id,

             });
    }



    onClose() {
        this.close.emit("")
    }

    onMessageClose() {
        this.dialogRef.close({
            clicked: 'submit',
            id: this.dataObj.id,

            });
        this.close.emit("")
    }


}
