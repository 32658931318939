import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { AccountServiceProxy, AnalyzerServiceProxy, ClinicalFrameworkServiceProxy, DataSourceTypeEnum, UserServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { createAccount, deleteAccount, getAccountById, loadAllAccounts, loadAllCustomers, loadAllFrameworks, syncElasticOrSisData, updateAccount } from "./accounts.actions";

@Injectable()
export class AccountsEffects {
    constructor(
        private actions$: Actions,
        private _accountServiceProxy: AccountServiceProxy,
        private _analyzerServiceProxy: AnalyzerServiceProxy,
        private _clinicalFrameworkService: ClinicalFrameworkServiceProxy,
    ) { }
    loadAllAccounts$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllAccounts.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._accountServiceProxy.getAccountsByPagination(
                    payload.pageIndex,
                    payload.pageSize,
                    payload.searchFilter,
                    payload.accountRoleSelect
                ).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllAccounts.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Accounts List.',
                            errorMapping: {},
                            failureAction: loadAllAccounts.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Accounts List.',
                        })
                    })
                )
            })
        )
    })

    loadAllCustomers$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllCustomers.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._analyzerServiceProxy.getMyCustomers().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllCustomers.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Customers.',
                            errorMapping: {},
                            failureAction: loadAllCustomers.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Customers.',
                        })
                    })
                )
            })
        )
    })

    loadAllFrameworks$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadAllFrameworks.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._clinicalFrameworkService.getAll().pipe(
                    map((res: any) => {
                        if (res) {
                            return loadAllFrameworks.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Frameworks.',
                            errorMapping: {},
                            failureAction: loadAllFrameworks.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load all Frameworks.',
                        })
                    })
                )
            })
        )
    })

    syncElasticOrSisData$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(syncElasticOrSisData.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._accountServiceProxy.syncElasticOrSisDataByAccountId(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return syncElasticOrSisData.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to sync ElasticOrSisData By AccountId.',
                            errorMapping: {},
                            failureAction: syncElasticOrSisData.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to sync ElasticOrSisData By AccountId.',
                        })
                    })
                )
            })
        )
    })

    deleteAccount$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(deleteAccount.init),
            map((action: any) => action),

            mergeMap((payload) => {
                return this._accountServiceProxy.deleteAccount(payload.id).pipe(
                    map((res: any) => {
                        if (res) {
                            return deleteAccount.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to delete Account.',
                            errorMapping: {},
                            failureAction: deleteAccount.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to delete Account.',
                        })
                    })
                )
            })
        )
    })

    createAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createAccount.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._accountServiceProxy.createAccount(payload.accountDTO).pipe(
                    map((res: any) => {
                        if (res) {
                            return createAccount.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to create Account.',
                            errorMapping: {},
                            failureAction: createAccount.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to create Account.',
                        })
                    })
                )
            })
        )
    })

    getAccountById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAccountById.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._accountServiceProxy.getAccountById(payload.accountId).pipe(
                    map((res: any) => {
                        if (res) {
                            return getAccountById.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get Account By Id.',
                            errorMapping: {},
                            failureAction: getAccountById.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to get Account By Id.',
                        })
                    })
                )
            })
        )
    })


    updateAccount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(updateAccount.init),
            map((action: any) => action),
            mergeMap((payload) => {
                return this._accountServiceProxy.updateAccount(payload.accountDTO).pipe(
                    map((res: any) => {
                        if (res) {
                            return updateAccount.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to update Account.',
                            errorMapping: {},
                            failureAction: updateAccount.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to update Account.',
                        })
                    })
                )
            })
        )
    })






}