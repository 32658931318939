import { Component, OnInit } from '@angular/core';
import { AppConsts } from '@app/shared/AppConsts';
import { UserRoleFilterEnum } from '@app/shared/service-proxies/service-proxies';
import { UserService } from '@app/shared/services/user.service';
import { Link } from '@securly-engineering/securly-styles';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {

  links: Link[] = [
    {
      url: '/app/home',
      title: 'Home',
      icon: 'homeline',
      isActive: true,
      clickEvent: () => {
      }
    },
    {
      url: '/app/detailed-reporting',
      title: 'Detailed Reporting',
      icon: 'line-chart-line',
      isActive: true,
      clickEvent: () => {
      }
    },
    {
      url: '/app/frameworks',
      title: 'Frameworks',
      icon: 'frameworks',
      isActive: true,
      clickEvent: () => {
      }
    },
    {
      url: '/app/analysis-builder',
      title: 'Analysis Builder',
      icon: 'tools-fill',
      isActive: true,
      clickEvent: () => {
      }
    },
    {
      url: '/app/my-analyses',
      title: 'My Analyses',
      icon: 'eye-line',
      isActive: true,
      clickEvent: () => {
      }
    },
    // {
    //   url: '/app/reporting',
    //   title: 'Reporting',
    //   icon: 'line-chart-line',
    //   isActive: this.showReporting(),
    //   clickEvent: () => {
    //   }
    // },
    {
      url: '/app/users',
      title: 'Users',
      icon: 'user-line',
      isActive: this.showUser(),
      clickEvent: () => {
      }
    },
    {
      url: '/app/invites',
      title: 'Invites',
      icon: 'mail-open-line',
      isActive: this.showInvite(),
      clickEvent: () => {
      }
    },
    {
      url: '/app/my-plans',
      title: 'My Plans',
      icon: 'group-line',
      isActive: false,
      clickEvent: () => {
      }
    },
    {
      url: '/app/data-analyst',
      title: 'Data Analyst',
      icon: 'chat-line',
      isActive: this.showDataAnalyst(),
      clickEvent: () => {
      }
    },
    {
      url: '/app/accounts',
      title: 'Accounts',
      icon: 'account-box-line',
      isActive: this.showAccount(),
      clickEvent: () => {
      }
    },
    // {
    //   url: '/app/cohorts',
    //   title: 'Cohort',
    //   icon: 'cohort',
    //   isActive: this.showCohort(),
    //   clickEvent: () => {
    //   }
    // }
  ];

  constructor(
    public userService: UserService,
  ) {
  }



  home = {
    icon: 'securly-logo',
    action: () => { }
  };

  ngOnInit() {

  }

  showAccount(): boolean {
    return this.userService.user.role.key == UserRoleFilterEnum.Host ? true : false;
  }

  showReporting(): boolean {
    return this.userService.user.role.key == UserRoleFilterEnum.Host ? true : false;
  }

  showUser(): boolean {
    return (this.userService.user.role.key == UserRoleFilterEnum.Host || this.userService.user.role.key == UserRoleFilterEnum.AccountAdmin) ? true : false;
  }

  showInvite() {
    return (this.userService.user.role.key == UserRoleFilterEnum.Host || this.userService.user.role.key == UserRoleFilterEnum.AccountAdmin) ? true : false;
  }

  showDataAnalyst(): boolean {
    return this.userService.user.role.key == UserRoleFilterEnum.Host ? true : false;
  }

  showCohort() {
    if (AppConsts.appEnvironmentName == AppConsts.environmentProduction) {
      return false;
    } else {
      return true;
    }
  }

}
