import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { loadPrimaryReportByVariableForPersonalize } from "./deatailed-reporting.actions";
import { DashboardDataVizReportServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";


@Injectable()
export class DetailedReportingEffects {
    constructor(
        private actions$: Actions,
        private dashboardDataVizReportServiceProxy: DashboardDataVizReportServiceProxy,
    ) { }


    loadPrimaryReportByVariableForPersonalize$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadPrimaryReportByVariableForPersonalize.init),
            map((action: any) => action),

            mergeMap((payload) => {
                
                return this.dashboardDataVizReportServiceProxy.getPrimaryReportByVariableForPersonalize(payload.payload).pipe(
                    map((res: any) => {
                        if (res) {
                            return loadPrimaryReportByVariableForPersonalize.success(res);
                        }
                    }),
                    catchError((error) => {
                        return httpErrorResponseHandler((error), {
                            defaultMessage: 'Failed to get all Primary View Report.',
                            errorMapping: {},
                            failureAction: loadPrimaryReportByVariableForPersonalize.failure,
                            unauthorizedError:
                                'Sorry, you do not have permission to load Primary Report By Variable For Personalize.',
                        })
                    })
                )
            })
        )
    })
   

}