import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { AppConsts } from 'src/app/shared/AppConsts';
import { API_BASE_URL } from '../service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


    baseUrl: any = AppConsts.apiBaseUrl;
  
    constructor(private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
  
      //  this.baseUrl = baseUrl? baseUrl : "";
  
    }
  
  
    convertBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
  
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
  
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };
  
  
    uploadFile(base64Image: any, uploadUrl: any) {
  
      let url_ = this.baseUrl + uploadUrl;
        const formData: FormData = new FormData();
  
        formData.append('DiscernDataFileType', base64Image);
        formData.append('DiscernDataFileType', 'UserInvitationsCsv');
  
        let options_: any = {
          withCredentials: true,
          observe: "response",
        };
        AppConsts.uploadRequest = true;
  
       return this.http.post<any>(url_, formData, options_)
    }

    downloadDemoCsv(){
      let url_ = this.baseUrl + "/api/File/DownloadDemoCsv";
      url_ = url_.replace(/[?&]$/, "");
      let options_: any = {
        withCredentials: true,
        observe: "response",
      };
      return this.http.get<string>( url_, options_)
    }
  }