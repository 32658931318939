<div class="container">
    <div class="message-box-wrapper">

        <img src="assets/scss/icons/SecurlyDiscern.png" class="img-fluid py-3" style="width:50%" />

        <img src="assets/success-tick.svg" class="img-fluid py-3" style="width:15%; padding: 0 !important;" />
        <h5 class="fw-semibold mb-12">Success !</h5>
        <p>{{message}}</p>
        <button routerLink="/auth/loginemail" class="btn btn-primary w-100 text-white">Back To Login</button>
    </div>
</div>