<div *ngIf="!message">
<h1 mat-dialog-title class="title">Delete {{name}}</h1>
<div mat-dialog-content class="content">
 Are you sure to want to delete this {{name}}?
</div>
<div mat-dialog-actions class="button justify-content-end d-flex">
  <button  mat-flat-button mat-dialog-close  (click)="onClose()">No</button>
  <button mat-flat-button mat-dialog-close color="warn" cdkFocusInitial (click)="success()">Yes</button>
</div>
</div>

<div *ngIf="message">
<h1 mat-dialog-title class="title sidebar-menu-link">Warning </h1>
<div mat-dialog-content class="content">
   <p>{{message}}</p>
</div>
<div mat-dialog-actions class="button justify-content-end d-flex">
  <button mat-flat-button mat-dialog-close color="warn" cdkFocusInitial (click)="onMessageClose()">Ok</button>
</div>
</div>
