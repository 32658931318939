import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";

export const loadCustomerList = createAsyncAction(
    '[My Analyses] Load Customer List',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadMyAnalyses = createAsyncAction(
    '[My Analyses] Load My Analyses',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadRecurringAnalyses = createAsyncAction(
    '[My Analyses] Load Recurring Analyses',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAnalysisRecurringRequestStudents = createAsyncAction(
    '[My Analyses] Load Analysis Recurring Request Students',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAnalysisRequestStudents = createAsyncAction(
    '[My Analyses] Load Analysis Request Students',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAnalysisRecurringRequestSchools = createAsyncAction(
    '[My Analyses] Load Analysis Recurring Request School',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAnalysisRequestSchools = createAsyncAction(
    '[My Analyses] Load Analysis Request School',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAnalysisRecurringRequestCohorts = createAsyncAction(
    '[My Analyses] Load Analysis Recurring Request Cohorts',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAnalysisRequestCohorts = createAsyncAction(
    '[My Analyses] Load Analysis Request Cohorts',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const makeRecurring = createAsyncAction(
    '[My Analyses] Make Recurring',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadAnalysisRecurringRequestLogs = createAsyncAction(
    '[My Analyses] Analysis Recurring Request Logs',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const loadTotalKeywordUsageCounts = createAsyncAction(
    '[My Analyses] Total Keyword Usage Count',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const updatedAnalysisRequest = createAsyncAction(
    '[My Analyses] Updated Analysis Request',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const updateRecurringAnalysisRequest = createAsyncAction(
    '[My Analyses] Updated Recurring Analysis Request',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
export const toggleAnalysisRecurringRequestStatus = createAsyncAction(
    '[My Analyses Toggle Analysis Recurring Request Status',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);
