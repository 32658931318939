import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/auth/credentials.service';
import * as SecurlyAuth from '../../../assets/securlyPlatform';
import { AppConsts } from '../AppConsts';
import { DiscernUserCookieInfoDto, UserServiceProxy } from '../service-proxies/service-proxies';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  discernUserInfoCookieName: string = "DiscernUserCookieInfo";
  constructor(
    private credentialsService: CredentialsService,
    private _userServiceProxy: UserServiceProxy,
    private _userService: UserService,
  ) { }
  getRhithmloginInfoFromCookie(): DiscernUserCookieInfoDto {
    var cookieJsonValue = this.credentialsService.getCookie(this.discernUserInfoCookieName);
    let parseedUserInfo
    if (!cookieJsonValue) {
      return null;
    } else {
      
      cookieJsonValue = decodeURIComponent(cookieJsonValue)
      if (JSON.parse(cookieJsonValue)?.data) {
        parseedUserInfo = JSON.parse(cookieJsonValue)?.data
      } else {
        parseedUserInfo = JSON.parse(cookieJsonValue)
      }
      let cookieUserInfo = DiscernUserCookieInfoDto.fromJS(parseedUserInfo);

      if (cookieUserInfo.userId > 0) {
        return cookieUserInfo;
      } else {
        return null;
      }
    }
  }

  async SecurelyInitAsync() {
    // Set your product's oauth clientid here - "securly_example_app" will only be allowed for testing
    await SecurlyAuth.Init("discern", "https://accounts.prtqa.securly.io");
  }
  async SecurelyIdentifyAsync() {
    try {
      await this.SecurelyInitAsync();
      var securlyTokenInfo = await SecurlyAuth.Identify();
      var securlyTokenEmailAddress = securlyTokenInfo.email;

      //check Rhithm Cookie
      var discernCookieInfo = this.getRhithmloginInfoFromCookie();


      this._userServiceProxy
        .getDiscernUserCookieInfo(securlyTokenEmailAddress)
        .subscribe((response) => {
          if (response?.data) {
            this.credentialsService.setCookie(this.discernUserInfoCookieName, JSON.stringify(response));
            setTimeout(() => {
              location.href = '/';
            }, 50);

          } else {
            location.href = location.origin + '/not-found';
          }

        })


    }
    catch (_e) {

      this.SecurlyLogin();
    }
  }

  SecurlyLogin() {
    let url = (window as any).location.href;
    if (location.href.indexOf('account/forgot-password') > 0) {
      return;
    }
    if (location.href.indexOf('account/user-invitation') > 0) {
      return;
    }
    if (location.href.indexOf('account/reset-password') > 0) {
      return;
    }
    SecurlyAuth.Login(url);
  }

  logout() {
    this.clearCookie("DiscernUserCookieInfo");
    this.clearCookie("securly_token");
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    let discernLogin = this.credentialsService.getCookie('discernLogin');

    if (!discernLogin) {
      this.clearCookie("discernLogin");
      this.SecurelyInitAsync().then(() => {
        SecurlyAuth.Logout().then(() => {
          setTimeout(() => {
            location.href = '';
          }, 1500);
        });
      });
    } else {
      this.clearCookie("discernLogin");
      location.href = '';
    }

  }

  clearCookie(cookieName: string): void {
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
  }

}


export class RhithmCookieUserInfoDto {
  emailAddress!: string | undefined;
  userId!: number;
  userName!: string | undefined;
  tenantId!: number | undefined;
  tenantName!: string | undefined;
  districtId!: number | undefined;
  districtName!: string | undefined;

  constructor(data?: any) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.emailAddress = _data["emailAddress"];
      this.userId = _data["userId"];
      this.userName = _data["userName"];
      this.tenantId = _data["tenantId"];
      this.tenantName = _data["tenantName"];
      this.districtId = _data["districtId"];
      this.districtName = _data["districtName"];
    }
  }

  static fromJS(data: any): RhithmCookieUserInfoDto {
    data = typeof data === 'object' ? data : {};
    let result = new RhithmCookieUserInfoDto();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["emailAddress"] = this.emailAddress;
    data["userId"] = this.userId;
    data["userName"] = this.userName;
    data["tenantId"] = this.tenantId;
    data["tenantName"] = this.tenantName;
    data["districtId"] = this.districtId;
    data["districtName"] = this.districtName;
    return data;
  }


}
