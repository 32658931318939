import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AcceptUserInviteDto, AccountServiceProxy, IResult, UserInviteServiceProxy } from '@app/shared/service-proxies/service-proxies';
import { CustomizerSettingsService } from '@app/shared/services/customizer-settings.service';
import * as reducers from '../../reducers';
import { Store } from '@ngrx/store';
import { acceptUserInvite, loadUserInviteByCode } from '../auth.actions';
import { Subject, skip, takeUntil } from 'rxjs';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  hide = true;
  token: string = '';
  formData: any;

  registerUserForm: any;
  isLoading:boolean = false;
  linkExpired: boolean = undefined;
  errorMsg: string = '';
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public themeService: CustomizerSettingsService,
    private activatedroute: ActivatedRoute,
    private userInviteServiceProxy: UserInviteServiceProxy,
    private formbuilder: FormBuilder,
    private accountServiceProxy: AccountServiceProxy,
    private _snackBarService: SecurlySnackBarService,
    private router: Router,
    private store: Store<reducers.State>,
    // private toastr: ToastrService,
  ) {
    this.initform();
    this.activatedroute.queryParams.subscribe(params => {

      this.token = encodeURIComponent(params['token']);
      this.store.dispatch(loadUserInviteByCode.init({ code: this.token }));
      this.store.select(reducers.getUserInvitesByCode).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: any) => {
        if (res.success) {
          this.linkExpired = false;
          this.errorMsg = '';
          this.registerUserForm.controls['firstname'].setValue(res.data.firstName);
          this.registerUserForm.controls['firstname'].disable();
          this.registerUserForm.controls['lastname'].setValue(res.data.lastName);
          this.registerUserForm.controls['lastname'].disable();
          this.registerUserForm.controls['email'].setValue(res.data.email);
          this.registerUserForm.controls['email'].disable();
          this.registerUserForm.controls['code'].setValue(this.token);
          this.registerUserForm.controls['userInviteId'].setValue(res.data.userInviteId);
        } else {
          this.linkExpired = true;
          this.errorMsg = res.message

        }
      });
    });
  }

  initform() {

    this.registerUserForm = this.formbuilder.group({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required]),
      userInviteId: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+~`|}{[\]:;?><,./-=])[0-9a-zA-Z!@#$%^&*()_+~`|}{[\]:;?><,./-=]{8,}$/)]),
      confirmpassword: new FormControl('', [Validators.required, Validators.pattern(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+~`|}{[\]:;?><,./-=])[0-9a-zA-Z!@#$%^&*()_+~`|}{[\]:;?><,./-=]{8,}$/)]),

    },
      {
        validators: this.checkPasswords
      }

    );
  }

  get f() {
    return this.registerUserForm.controls;
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls['password'].value;
    const confirmPass = group.controls['confirmpassword'].value;

    return pass === confirmPass ? null : { notSame: true };
  }
  generatePassword() {

    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890#?!@$%^&*-";
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    let password = "";
    do {
      password = "";
      for (let i = 0; i < 8; i++) {
        password += chars[Math.floor(Math.random() * chars.length)];
      }
    } while (!regex.test(password));
    return password;
  }

  autoGeneratePassword() {
    const password = this.generatePassword();
    this.registerUserForm.controls['password'].setValue(password);
    this.registerUserForm.controls['confirmpassword'].setValue(password);
  }

  register() {
    this.isLoading = true;
    if (!this.registerUserForm.invalid) {
      let body = {
        userInviteId: this.registerUserForm.controls['userInviteId'].value,
        code: this.registerUserForm.controls['code'].value,
        email: this.registerUserForm.controls['email'].value,
        password: this.registerUserForm.controls['password'].value,
      } as AcceptUserInviteDto
      this.store.dispatch(acceptUserInvite.init({ ...body }));
      this.store.select(reducers.acceptUserInvites).pipe(skip(1), takeUntil(this.destroy$)).subscribe((res: any) => {
        if (res.success) {
          this.isLoading = false;
          this.router.navigate(['/auth/accept-user-invite/confirmed'], { state: { message: 'Your account has been created successfully.' } });
          // this._snackBarService.open(res.message, SecurlySnackBarType.Success)
        } else {
          this.isLoading = false;
          this._snackBarService.open(res.message, SecurlySnackBarType.Failure)
        }
      });

    }

  }

  onEnterKeyPressed(event: any): void {
    event.preventDefault();
    // Add any other actions you want to perform here
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
