

import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { AppConsts } from '@app/shared/AppConsts';
import { AuthServiceProxy, LoginDto } from '@app/shared/service-proxies/service-proxies';
import { UserService } from '@app/shared/services/user.service';
import { Store } from '@ngrx/store';
import * as reducers from '../../reducers';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { filter } from 'rxjs';
import { CredentialsService } from '../credentials.service';


@Component({
  selector: 'app-login-email',
  templateUrl: './login-email.component.html',
  styleUrls: ['./login-email.component.scss']
})
export class LoginEmailComponent implements OnInit {
  isSSO: boolean;
  loginForm!: UntypedFormGroup;

  constructor(
    private store: Store<reducers.State>,
    private userService: UserService,
    private authService: AuthServiceProxy,
    private formBuilder: UntypedFormBuilder,
    private _loaderService: NgxUiLoaderService,
    private _router: Router,
    private credentialsService: CredentialsService,
  ) {
    this._router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log('NavigationEnd:  ', event.url);
        if (event.url != '/auth/loginemail' && AppConsts.useSecurlySso) {
          _loaderService.startBackgroundLoader('onSSO')
          this.isSSO = AppConsts.useSecurlySso
        } else {
          this.isSSO = false;
        }

      });
    this.createForm();
  }
  ngOnInit() {
  }
  ngOnDestroy() { }
  redirectToDashboard() {
    const loginDto = new LoginDto();
    loginDto.email = this.loginForm.controls.username.value;
    loginDto.password = this.loginForm.controls.password.value;

    this.authService.login(loginDto).subscribe(res => {
      if (res.success === true) {
          this.credentialsService.setCookie('discernLogin', 'true');
        this.userService.setLoginResponse(res.data);
        const initialUrl = location.origin + '/app/home';

        location.href = initialUrl;
      } else {
        alert(res.message);
      }
    })
  }
  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true,
    });
  }

}
