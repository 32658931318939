

import { ActionReducerMap, createSelector, ActionReducer } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { RouterStateUrl } from '../router/utils';
import * as fromApp from '../app.reducer';
import * as fromFrameworks from '../frameworks/frameworks.reducer';
import * as fromHome from '../home/home.reducer';
import * as fromdetailedReporting from '../detailed-reporting/deatailed-reporting.reducer';
import * as fromAnalysisBuilder from '../analysis-builder/analysis-builder.reducer';
import * as fromMyAnalysis from '../my-analysis/my-analysis.reducer';
import * as fromUsers from '../users/users.reducer';
import * as fromUsersInvite from '../invites/invites.reducer';
import * as fromAccounts from '../accounts/accounts.reducer';
import * as fromAuth from '../auth/auth.reducer';



export interface State {
  app: fromApp.State;
  home: fromHome.State;
  detailedReporting:fromdetailedReporting.State;
  frameworks: fromFrameworks.State;
  analysisBuilder: fromAnalysisBuilder.State;
  myAnalysis: fromMyAnalysis.State;
  users: fromUsers.State;
  userInvite: fromUsersInvite.State;
  accounts: fromAccounts.State;
  auth: fromAuth.State;
  // myPlans:fromMyPlans.State;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  app: fromApp.reducer,
  home: fromHome.reducer,
  detailedReporting:fromdetailedReporting.reducer,
  frameworks: fromFrameworks.reducer,
  analysisBuilder: fromAnalysisBuilder.reducer,
  myAnalysis: fromMyAnalysis.reducer,
  users: fromUsers.reducer,
  userInvite: fromUsersInvite.reducer,
  accounts: fromAccounts.reducer,
  auth: fromAuth.reducer,

  // myPlans:fromMyPlans.reducer,
  router: fromRouter.routerReducer,
};
/* Auth Start */
export const getAuthStates = (state: State) => state.auth;
export const getUserInvitesByCode = createSelector(
  getAuthStates,
  fromAuth.getUserInviteByCode
);
export const acceptUserInvites = createSelector(
  getAuthStates,
  fromAuth.acceptUserInvitation
);

export const userForgetPassword = createSelector(
  getAuthStates,
  fromAuth.forgetUserPassword
);

export const userResetPassword = createSelector(
  getAuthStates,
  fromAuth.resetUserPassword
);
export const validateUserResetPasswordLink = createSelector(
  getAuthStates,
  fromAuth.validateResetLink
);
/* Auth ends */

/* Home start*/
export const getHomeStates = (state: State) => state.home;
export const getAllPrimaryViewReports = createSelector(getHomeStates, fromHome.getPrimaryViewReport);
export const getAllAccounts = createSelector(getHomeStates, fromHome.getAccountsList);
export const getAllSchoolYears = createSelector(getHomeStates, fromHome.getSchoolYearList);
export const downloadFrameworkTypeOverviewReport = createSelector(getHomeStates, fromHome.downloadFrameworkTypeReport);
export const downloadStdLevelFrameworkReport = createSelector(getHomeStates, fromHome.downloadStdLevelFrameworkReports);
export const getAnalyzedClinicalFrameWorks = createSelector(getHomeStates, fromHome.getAnalyzedClinicalFrameWorks);
export const getAnalyzedClinicalFrameWorksPersonalize = createSelector(getHomeStates, fromHome.getAnalyzedClinicalFrameWorksPersonalize);
export const getAnalyzedClinicalFrameWorksScreen = createSelector(getHomeStates, fromHome.getAnalyzedClinicalFrameWorksScreen);
export const getGraphPrimaryViewReportByFrameworkV3 = createSelector(getHomeStates, fromHome.getGraphPrimaryViewReportByFrameworkV3);
export const getListPrimaryViewReportByFrameworkV3 = createSelector(getHomeStates, fromHome.getListPrimaryViewReportByFrameworkV3);
export const getGraphPrimaryViewReportByFrameworkV3Personalized = createSelector(getHomeStates, fromHome.getGraphPrimaryViewReportByFrameworkV3Personalized);
export const getListPrimaryViewReportByFrameworkV3Personalized = createSelector(getHomeStates, fromHome.getListPrimaryViewReportByFrameworkV3Personalized);
export const getDashboardMetricsView = createSelector(getHomeStates, fromHome.getDashboardMetricsView);
export const getScreenCohortViewReportByFrameworkV3 = createSelector(getHomeStates, fromHome.getScreenCohortViewReportByFrameworkV3);
export const getScreenCohortViewDillDownReport = createSelector(getHomeStates, fromHome.getScreenCohortViewDillDownReport);
export const getStudentDrillDownMeasureViz = createSelector(getHomeStates, fromHome.getStudentDrillDownMeasureViz);
export const getPrevalencetDrillDownViz = createSelector(getHomeStates, fromHome.getPrevalencetDrillDownViz);
export const getStudentSummaryWithDateFilter = createSelector(getHomeStates, fromHome.getStudentSummaryWithDateFilter);

/* Home End*/


/* Detailed Reporting start*/
export const getdetailedReportingStates = (state: State) => state.detailedReporting;
export const getPrimaryReportForPersonalize = createSelector(getdetailedReportingStates, fromdetailedReporting.getPrimaryReportForPersonalize);

/* Detailed Reporting End*/

/* 
  FrameWorks start
*/
export const getFrameworksStates = (state: State) => state.frameworks;
export const getAllClinicalFrameworks = createSelector(
  getFrameworksStates,
  fromFrameworks.getClinicalFrameworks,
);
export const getdeleteclinicalFrameworkResponse = createSelector(
  getFrameworksStates,
  fromFrameworks.deleteclinicalFrameworkResponse,
);
export const getCloneclinicalFrameworkResponse = createSelector(
  getFrameworksStates,
  fromFrameworks.cloneClinicalFrameworksResponse,
);
export const getChangeStatusclinicalFrameworkResponse = createSelector(
  getFrameworksStates,
  fromFrameworks.changeStatusClinicalFrameworksResponse,
);
export const createclinicalFrameworkResponse = createSelector(
  getFrameworksStates,
  fromFrameworks.createClinicalFrameworks,
);
export const updateclinicalFrameworkResponse = createSelector(
  getFrameworksStates,
  fromFrameworks.updateClinicalFrameworks,
);

export const detailclinicalFrameworkResponse = createSelector(
  getFrameworksStates,
  fromFrameworks.detailClinicalFrameworks,
);

/* FrameWorks end*/

/* Analysis Builder starts*/

export const getAnalysisBuilderStates = (state: State) => state.analysisBuilder;
export const getFrameworkDropdownList = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.getFrameworkDropdown);
export const getAllStudentsAsPaginated = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.getAllStudentsAsPaginated);
export const getAllKeywordSummary = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.getAllKeywordSummary);
export const getInternalRecordsSummary = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.getInternalRecordsSummary);
export const getPromptPreview = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.getPromptPreview);
export const isAnalysisRequestExist = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.isAnalysisRequestExist);
export const frameworkRequest = createSelector(getAnalysisBuilderStates, fromAnalysisBuilder.frameworkRequest);






/* Analysis Builder End*/

/* My Analysis Starts*/
export const getMyAnalysisStates = (state: State) => state.myAnalysis;
export const getCustomerList = createSelector(getMyAnalysisStates, fromMyAnalysis.getCustomerList);
export const getMyAnalyses = createSelector(getMyAnalysisStates, fromMyAnalysis.getMyAnalysesList);
export const getRecurringAnalyses = createSelector(getMyAnalysisStates, fromMyAnalysis.getRecurringAnalyses);
export const getAnalysisRecurringRequestStudent = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRecurringRequestStudents);
export const getAnalysisRequestStudent = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRequestStudents);
export const getAnalysisRecurringRequestSchool = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRecurringRequestSchools);
export const getAnalysisRequestSchool = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRequestSchools);
export const createAnalysisRecurring = createSelector(getMyAnalysisStates, fromMyAnalysis.makeAnalysisRecurring);
export const getAnalysisRecurringRequestLog = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRecurringRequestLogs);
export const getTotalKeywordUsageCount = createSelector(getMyAnalysisStates, fromMyAnalysis.getTotalKeywordUsageCounts);
export const updatedAnalysisRequest = createSelector(getMyAnalysisStates, fromMyAnalysis.updatedAnalysesRequest);
export const updateRecurringAnalysisRequest = createSelector(getMyAnalysisStates, fromMyAnalysis.updateRecurringAnalysesRequest);
export const toggleAnalysisRecurringRequestStatus = createSelector(getMyAnalysisStates, fromMyAnalysis.toggleAnalysesRecurringRequestStatus);
export const getAnalysisRecurringRequestCohorts = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRecurringRequestCohorts);
export const getAnalysisRequestCohorts = createSelector(getMyAnalysisStates, fromMyAnalysis.getAnalysisRequestCohorts);
/* My Analysis End*/

/* Users Start*/
export const getUsersStates = (state: State) => state.users;
export const getUserList = createSelector(getUsersStates, fromUsers.getUserList);
export const getUserRolesList = createSelector(getUsersStates, fromUsers.getUserRolesList);
export const getAccountList = createSelector(getUsersStates, fromUsers.getAccountList);
export const deleteUser = createSelector(getUsersStates, fromUsers.deleteUser);
export const createUser = createSelector(getUsersStates, fromUsers.createUser);
export const getallSchools = createSelector(getUsersStates, fromUsers.getallSchools);
export const getUpdatedUsers = createSelector(getUsersStates, fromUsers.getUpdatedUsers);
export const getUserById = createSelector(getUsersStates, fromUsers.getUserById);



/* Users End*/

/* Invite Start*/
export const getInivteStates = (state: State) => state.userInvite;
export const getUsersInivteList = createSelector(
  getInivteStates,
  fromUsersInvite.getUserInviteList
);
export const getInivteUserRolesList = createSelector(
  getInivteStates,
  fromUsersInvite.getUserRolesList
);
export const getinviteAccountList = createSelector(
  getInivteStates,
  fromUsersInvite.getAccountList
);
export const getSchoolsList = createSelector(
  getInivteStates,
  fromUsersInvite.getSchoolList
);
export const resendUsersInivte = createSelector(
  getInivteStates,
  fromUsersInvite.resendUserInvite
);
export const revokendUsersInivte = createSelector(
  getInivteStates,
  fromUsersInvite.revokeUserInvite
);
export const getDemoCSVFile = createSelector(
  getInivteStates,
  fromUsersInvite.getSampleCSVFile
);
export const createUserInvitationByCsv = createSelector(
  getInivteStates,
  fromUsersInvite.createUserInviteByCsv
);
export const createBulkUsersInviteBySecurlySync = createSelector(
  getInivteStates,
  fromUsersInvite.createBulkUserInviteBySecurlySync
);
export const createUserInvites = createSelector(
  getInivteStates,
  fromUsersInvite.createNewUserInvite
);
export const getAdminsByCustomer = createSelector(
  getInivteStates,
  fromUsersInvite.getAdminsListByCustomer
);

/* invite End*/

/* My Plans Starts*/

// export const getMyPlansStates = (state: State) => state.myPlans;

/* My Plans End*/

/*---------- Account Starts ---------- */
export const getAccountsStates = (state: State) => state.accounts;
export const getAccountsList = createSelector(getAccountsStates, fromAccounts.getAccountsList);
export const getMyCustomers = createSelector(getAccountsStates, fromAccounts.getMyCustomers);
export const getAllFrameworks = createSelector(getAccountsStates, fromAccounts.getAllFrameworks);
export const syncData = createSelector(getAccountsStates, fromAccounts.syncData);
export const deleteAccountId = createSelector(getAccountsStates, fromAccounts.deleteAccountId);
export const createNewAccount = createSelector(getAccountsStates, fromAccounts.createNewAccount);
export const getAccountInfoById = createSelector(getAccountsStates, fromAccounts.getAccountInfoById);
export const getUpdateAccount = createSelector(getAccountsStates, fromAccounts.getUpdateAccount);






/*---------- Account Ends ---------- */

/**
 * App error messages
 */

export const getAppErrorMessageState = (state: State) => state.app;

export const getGridColumnSettings = createSelector(
  getAppErrorMessageState,
  fromApp.getGridColumnSettings
);
export const getResetGridColumnSettings = createSelector(
  getAppErrorMessageState,
  fromApp.getResetGridColumnSettings
);




/**
 * Prod
 */
export const getAppState = (state: State) => state.app;
const productionReducer: ActionReducer<State> = combineReducers(reducers);
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>(
  'root reducer'
);

export function reducer(state: any, action: any) {
  return productionReducer(state, action);
}

export function getUserInviteList(getUserInviteList: any) {
  throw new Error('Function not implemented.');
}
// export function getUpdatedDashboardSortData(getUpdatedDashboardSortData: any) {
//   throw new Error('Function not implemented.');
// }

