import { OAuthModule } from 'angular-oauth2-oidc';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { I18nModule } from '@app/i18n';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '@app/shared';
import { AccountComponent } from './account/account.component';
import { LoginEmailComponent } from './login-email/login-email.component';
import { AccountRouteGuard } from './account-route-guard';
import { AuthServiceProxy, UserServiceProxy } from '@app/shared/service-proxies/service-proxies';
import { RegisterComponent } from './register/register.component';
import { PasswordChangedMessageComponent } from './password-changed-message/password-changed-message.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ConfirmMailComponent } from './confirm-mail/confirm-mail.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    AuthRoutingModule,
    SharedModule,
    OAuthModule.forRoot(),
    NgxUiLoaderModule

  ],
  providers: [
    UserServiceProxy,
    AccountRouteGuard,
    AuthServiceProxy,
    UserServiceProxy

  ],
  declarations: [
    AccountComponent,
    LoginEmailComponent,
    RegisterComponent,
    PasswordChangedMessageComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmMailComponent
  ],
})
export class AuthModule { }
