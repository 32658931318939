import { createAsyncAction } from "@app/util";
import { props } from "@ngrx/store";

export const loadAllAccounts = createAsyncAction(
    '[Accounts] Load All Accounts',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);


export const loadAllCustomers = createAsyncAction(
    '[Accounts] Load All Customers',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const loadAllFrameworks = createAsyncAction(
    '[Accounts] Load All Frameworks ',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const syncElasticOrSisData = createAsyncAction(
    '[Accounts] sync Elastic Or SisData ',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const deleteAccount = createAsyncAction(
    '[Accounts] Delete Account',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const createAccount = createAsyncAction(
    '[Accounts] Create Account',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const getAccountById = createAsyncAction(
    '[Accounts] get Account By Id',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);

export const updateAccount = createAsyncAction(
    '[Accounts] update Account',
    props<any>(),
    props<{ payload: any }>(),
    props<{ message: string }>()
);