import { Injectable } from "@angular/core";
import { httpErrorResponseHandler } from "@app/shared/http-error-response-handler";
import { AccountServiceProxy, UserInviteServiceProxy } from "@app/shared/service-proxies/service-proxies";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap } from "rxjs";
import { acceptUserInvite, forgetPassword, loadUserInviteByCode, resetPassword, validateResetPasswordLink } from './auth.actions';
@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private _userInviteServiceProxy: UserInviteServiceProxy,
        private _accountServiceProxy: AccountServiceProxy,
    ) { }
    loadUserInviteByCode$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(loadUserInviteByCode.init),
            map((action: any) => action),

            mergeMap((payload: any) => {
                return this._userInviteServiceProxy.getUserInviteByCode(payload.code)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                return loadUserInviteByCode.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to Load User Invite By Code.',
                                errorMapping: {},
                                failureAction: loadUserInviteByCode.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to Load User Invite By Code.',
                            })
                        })
                    )
            })
        )
    })
    acceptUserInvite$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(acceptUserInvite.init),
            map((action: any) => action),

            mergeMap((payload: any) => {
                return this._accountServiceProxy.acceptUserInvite(payload)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                return acceptUserInvite.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to Accept User Invite.',
                                errorMapping: {},
                                failureAction: acceptUserInvite.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to Accept User Invite.',
                            })
                        })
                    )
            })
        )
    })
    forgetPassword$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(forgetPassword.init),
            map((action: any) => action),

            mergeMap((payload: any) => {
                return this._accountServiceProxy.forgetUserPassword(payload)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                return forgetPassword.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to Forget Password.',
                                errorMapping: {},
                                failureAction: forgetPassword.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to Forget Password.',
                            })
                        })
                    )
            })
        )
    })
    validateResetPasswordLink$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(validateResetPasswordLink.init),
            map((action: any) => action),

            mergeMap((payload: any) => {
                return this._accountServiceProxy.validateUserResetPasswordLink(payload.email,payload.code)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                return validateResetPasswordLink.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to Validate Reset Link.',
                                errorMapping: {},
                                failureAction: validateResetPasswordLink.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to Validate Reset Link.',
                            })
                        })
                    )
            })
        )
    })
    resetPassword$ = createEffect(() => {

        return this.actions$.pipe(
            ofType(resetPassword.init),
            map((action: any) => action),

            mergeMap((payload: any) => {
                return this._accountServiceProxy.resetUserPassword(payload)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                return resetPassword.success(res);
                            }
                        }),
                        catchError((error) => {
                            return httpErrorResponseHandler((error), {
                                defaultMessage: 'Failed to Reset Password.',
                                errorMapping: {},
                                failureAction: resetPassword.failure,
                                unauthorizedError:
                                    'Sorry, you do not have permission to Reset Password.',
                            })
                        })
                    )
            })
        )
    })
}