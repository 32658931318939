<!-- <div class="container-fluid">
  <div class="not-found-wrapper">
    <div class="page-title">
      <h1>404</h1>
    </div>
    <div class="page-desc">
      <p>User Not Found</p>
    </div>
  </div>
</div> -->
<div class="error-page">
  <div class="error-container">
    <img src="assets/securly.svg" alt="Securly Logo" class="logo" />
    <h1>We couldn’t find an account with those credentials</h1>
    <p>Please check your login details and try again</p>
    <img src="assets/securly-error-page.png" alt="Keys Illustration" class="illustration" />
    <br>
    <button class="back-to-login" (click)="onBackToLogin()">Back to login</button>
  </div>
</div>