import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppSessionService } from '@app/shared/common/session/app-session.service';
import { CredentialsService } from './credentials.service';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _appSessionService: AppSessionService,
    private _credentialsService: CredentialsService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    const user = this._credentialsService.getCookie('DiscernUserCookieInfo')
    if (accessToken && refreshToken) {
      return true;
    }
    if (user) {
      return true;
    }
    else {
      this._router.navigate(['/auth/loginemail']);
      return false;
    }
  }

}
