import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConsts } from '@app/shared/AppConsts';
import { AuthenticationService } from '@app/shared/services/authentication.service';
import { filter } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private router: Router
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async (event: NavigationEnd) => {
        console.log('NavigationEnd:  ', event.url);
        if ( !event.url.includes('/auth/') && AppConsts.useSecurlySso) {
          await this.authService.SecurelyIdentifyAsync();
        }
      });
    // console.log("this is account...................", AppConsts.UseSecurlySso)

  }

  ngOnInit(): void {


  }

}
