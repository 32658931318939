import { AccountDtoIDataResult, AccountDtoPaginatedListIDataResult, ClinicalFrameworkToListDtoListIDataResult, GetMyCustomersResponse, IResult } from "@app/shared/service-proxies/service-proxies";
import { createAccount, deleteAccount, getAccountById, loadAllAccounts, loadAllCustomers, loadAllFrameworks, syncElasticOrSisData, updateAccount } from "./accounts.actions";

export interface State {
    accountsListPayload: any;
    accountsList: AccountDtoPaginatedListIDataResult;
    getMyCustomersPayload: any;
    getMyCustomers: GetMyCustomersResponse;
    getAllFrameworksPayload: any;
    getAllFrameworks: ClinicalFrameworkToListDtoListIDataResult;
    syncDataPayload: any;
    syncData: IResult;
    deleteAccountPayload: any;
    deleteAccount: any;
    createAccountPayload: any;
    createAccount: any;
    getAccountByIdEditPayload: any;
    getAccountByIdEdit: AccountDtoIDataResult
    updateAccountPayload: any;
    updateAccount: any;

}

const initialState: State = {
    accountsListPayload: null,
    accountsList: null,
    getMyCustomersPayload: null,
    getMyCustomers: null,
    getAllFrameworksPayload: null,
    getAllFrameworks: null,
    syncDataPayload: null,
    syncData: null,
    deleteAccountPayload: null,
    deleteAccount: null,
    createAccountPayload: null,
    createAccount: null,
    getAccountByIdEditPayload: null,
    getAccountByIdEdit: null,
    updateAccountPayload: null,
    updateAccount: null,
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        // ------------------Get All Accounts-------------------//
        case loadAllAccounts.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                accountsListPayload: requestPayload,
            });
        }
        case loadAllAccounts.success.type: {

            return Object.assign({}, state, {
                accountsList: action,
            })
        }

        // ------------------Get All Customers-------------------//
        case loadAllCustomers.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getMyCustomersPayload: requestPayload,
            });
        }
        case loadAllCustomers.success.type: {
            return Object.assign({}, state, {
                getMyCustomers: action,
            })
        }

        // ------------------Get All Frameworks-------------------//
        case loadAllFrameworks.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getAllFrameworksPayload: requestPayload,
            });
        }
        case loadAllFrameworks.success.type: {
            return Object.assign({}, state, {
                getAllFrameworks: action,
            })
        }

        // ------------------sync Elastic Or SisData By AccountId-------------------//
        case syncElasticOrSisData.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                syncDataPayload: requestPayload,
            });
        }
        case syncElasticOrSisData.success.type: {
            return Object.assign({}, state, {
                syncData: action,
            })
        }

        // ------------------delete Account-------------------//
        case deleteAccount.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                deleteAccountPayload: requestPayload,
            });
        }
        case deleteAccount.success.type: {
            return Object.assign({}, state, {
                deleteAccount: action,
            })
        }

        // ------------------create Account-------------------//
        case createAccount.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createAccountPayload: requestPayload,
            });
        }
        case createAccount.success.type: {
            return Object.assign({}, state, {
                createAccount: action,
            })
        }

        // ------------------edit  Account-------------------//
        case getAccountById.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                getAccountByIdEditPayload: requestPayload,
            });
        }
        case getAccountById.success.type: {
            return Object.assign({}, state, {
                getAccountByIdEdit: action,
            })
        }

        // ------------------update Account-------------------//
        case updateAccount.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                updateAccountPayload: requestPayload,
            });
        }
        case updateAccount.success.type: {
            return Object.assign({}, state, {
                updateAccount: action,
            })
        }

        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getAccountsList = (state: State) => state.accountsList;
export const getMyCustomers = (state: State) => state.getMyCustomers;
export const getAllFrameworks = (state: State) => state.getAllFrameworks;
export const syncData = (state: State) => state.syncData;
export const deleteAccountId = (state: State) => state.deleteAccount;
export const createNewAccount = (state: State) => state.createAccount;
export const getAccountInfoById = (state: State) => state.getAccountByIdEdit;
export const getUpdateAccount = (state: State) => state.updateAccount;



