
import { AccountDtoListIDataResult, GetAllSchoolsRequest, GetAllSchoolsResponseIDataResult, IResult, InsertBulkUsersInvitationBySecurlySyncDto, RoleToListDtoListIDataResult, UserInvitationCsvDto, UserInviteSecurlySyncToListDtoPaginatedListIDataResult, UserInviteToAddDto, UserInviteToListDtoPaginatedListIDataResult } from "@app/shared/service-proxies/service-proxies";
import { createBulkInviteBySecurlySync, createUserInvitationsByCsv, createUserInvite, downloadSampleFile, loadAccountList, loadAdminsByCustomer, loadSchoolList, loadUserInviteList, loadUserRoles, resendInvite, revokeInvite } from "./invites.actions";


export interface State {
    userInviteListPayload: any;
    userInviteList: UserInviteToListDtoPaginatedListIDataResult;
    userRolesListPayload: any;
    userRolesList: RoleToListDtoListIDataResult
    accountListPayload: any;
    accountList: AccountDtoListIDataResult
    schoolListPayload: GetAllSchoolsRequest;
    schoolList: GetAllSchoolsResponseIDataResult;
    revokeInvitePayload: any;
    isRevokeInvite: any;
    resendInvitePayload: any;
    isResendInvite: any;
    sampleCSVFile: any;
    createUserInvitesByCsvPayload: UserInvitationCsvDto;
    createUserInvitesByCsv: IResult;
    createUserInvitePayload: UserInviteToAddDto;
    createUserInvite: IResult;
    createBulkInviteBySecurlySyncPayload: InsertBulkUsersInvitationBySecurlySyncDto;
    createBulkInviteBySecurlySync: IResult;
    adminsListByCustomerPayload: any;
    adminsListByCustomer: UserInviteSecurlySyncToListDtoPaginatedListIDataResult;
}

const initialState: State = {
    userInviteListPayload: null,
    userInviteList: null,
    userRolesListPayload: null,
    userRolesList: null,
    accountListPayload: null,
    accountList: null,
    schoolListPayload: null,
    schoolList: null,
    revokeInvitePayload: null,
    isRevokeInvite: null,
    resendInvitePayload: null,
    isResendInvite: null,
    sampleCSVFile: null,
    createUserInvitePayload: null,
    createUserInvite: null,
    createUserInvitesByCsvPayload: null,
    createUserInvitesByCsv: null,
    createBulkInviteBySecurlySyncPayload: null,
    createBulkInviteBySecurlySync: null,
    adminsListByCustomerPayload: null,
    adminsListByCustomer: null
};

export function reducer(state = initialState, action: any): State {
    let stateToReturn = state; // default

    switch (action.type) {

        // User List
        case loadUserInviteList.init.type: {
            const requestPayload = action;
            return Object.assign({}, state, {
                userInviteListPayload: requestPayload,
            });
        }
        case loadUserInviteList.success.type: {

            return Object.assign({}, state, {
                userInviteList: action,
            })
        }
        // UserRoles List
        case loadUserRoles.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                userRolesListPayload: requestPayload,
            });
        }
        case loadUserRoles.success.type: {

            return Object.assign({}, state, {
                userRolesList: action,
            })
        }

        // Accounts List
        case loadAccountList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                accountListPayload: requestPayload,
            });
        }
        case loadAccountList.success.type: {

            return Object.assign({}, state, {
                accountList: action,
            })
        }

        // School List
        case loadSchoolList.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                schoolListPayload: requestPayload,
            });
        }
        case loadSchoolList.success.type: {

            return Object.assign({}, state, {
                schoolList: action,
            })
        }
        case createUserInvitationsByCsv.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createUserInvitesByCsvPayload: requestPayload,
            });
        }
        case createUserInvitationsByCsv.success.type: {

            return Object.assign({}, state, {
                createUserInvitesByCsv: action,
            })
        }
        case createUserInvite.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createUserInvitePayload: requestPayload,
            });
        }
        case createUserInvite.success.type: {

            return Object.assign({}, state, {
                createUserInvite: action,
            })
        }
        case createBulkInviteBySecurlySync.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                createBulkInviteBySecurlySyncPayload: requestPayload,
            });
        }
        case createBulkInviteBySecurlySync.success.type: {

            return Object.assign({}, state, {
                createBulkInviteBySecurlySync: action,
            })
        }
        // School List end

        case revokeInvite.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                revokeInvitePayload: requestPayload,
            });
        }
        case revokeInvite.success.type: {

            return Object.assign({}, state, {
                isRevokeInvite: action,
            })
        }
        case resendInvite.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                resendInvitePayload: requestPayload,
            });
        }
        case resendInvite.success.type: {

            return Object.assign({}, state, {
                isResendInvite: action,
            })
        }
        case loadAdminsByCustomer.init.type: {
            const requestPayload = action.payload;
            return Object.assign({}, state, {
                adminsListByCustomerPayload: requestPayload,
            });
        }
        case loadAdminsByCustomer.success.type: {

            return Object.assign({}, state, {
                adminsListByCustomer: action,
            })
        }
        case downloadSampleFile.init.type: {
            return Object.assign({}, state);
        }
        case downloadSampleFile.success.type: {

            return Object.assign({}, state, {
                sampleCSVFile: action,
            })
        }

        default:
            stateToReturn
            break;
    }
    return stateToReturn;

}
export const getUserInviteList = (state: State) => state.userInviteList;
export const getUserRolesList = (state: State) => state.userRolesList;
export const getAccountList = (state: State) => state.accountList;
export const getSchoolList = (state: State) => state.schoolList;
export const resendUserInvite = (state: State) => state.isResendInvite;
export const revokeUserInvite = (state: State) => state.isRevokeInvite;
export const getSampleCSVFile = (state: State) => state.sampleCSVFile;
export const createUserInviteByCsv = (state: State) => state.createUserInvitesByCsv;
export const createNewUserInvite = (state: State) => state.createUserInvite;
export const createBulkUserInviteBySecurlySync = (state: State) => state.createBulkInviteBySecurlySync;
export const getAdminsListByCustomer = (state: State) => state.adminsListByCustomer;