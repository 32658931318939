import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { WarningMessageComponent } from '@app/analysis-builder/warning-message/warning-message.component';
import { AppConsts } from '@app/shared/AppConsts';
import { ClinicalFrameworkServiceProxy, CohortCreationInputDto, CohortCreationSourceEnum, CohortServiceProxy, CohortStudentFilterInputDto } from '@app/shared/service-proxies/service-proxies';
import { UserService } from '@app/shared/services/user.service';
import { SecurlySnackBarService, SecurlySnackBarType } from '@securly-engineering/securly-styles';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { ERole } from 'src/assets/enums/profileEnum';

@Component({
  selector: 'app-create-cohort',
  templateUrl: './create-cohort.component.html',
  styleUrls: ['./create-cohort.component.scss']
})
export class CreateCohortComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild("paginatorCreateCohort") paginatorCreateCohort: MatPaginator;

  tableName = 'cohort-user-list';

  displayedColumns: string[] = [
    'checkBox',
    'email',
    'name',
    'schools',
  ];

  resetColumnOrder = [
    { id: 'checkBox', name: 'CheckBox', hidden: false, disable: false },
    { id: 'email', name: 'Email', hidden: false, disable: false },
    { id: 'name', name: 'Name', hidden: false, disable: false },
    { id: 'schools', name: 'Schools', hidden: false, disable: false },
  ];

  searchControl = new FormControl();
  searchStudent = "";
  cohortList: any[] = [];
  // btnDisable: boolean = true;
  cohortForm: FormGroup;

  availableUsers: any[] = [];

  filterSelected: number = 1;
  filterOptions = [
    { label: 'Select All', value: 1 },
    { label: 'Select Specific', value: 2 },
  ];
  selectedUsers: any = [];

  pageIndex: number = 1;
  pageSize: number = 10;
  pageSizeOptions = [10, 25, 50, 100];
  totalRecordCount: number;


  variablesSearchControl = new FormControl();

  accountsList: any = [];
  selectedAccount: any = '';
  filteredaccountsList: any = [];

  variablesList: any[] = [];
  filteredVariablesList: any[] = [];

  accountId: any;
  eRole = ERole;
  filterValue: number = 1;
  isAllStudents: boolean = true;
  studentIds: any;
  cohortCreationSourceEnum: CohortCreationSourceEnum;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _dialogRef: MatDialogRef<CreateCohortComponent>,
    private _dialog: MatDialog,
    private _clinicalFramework: ClinicalFrameworkServiceProxy,
    private _cohortService: CohortServiceProxy,
    public userService: UserService,
    private _snackBarService: SecurlySnackBarService,
    private formbuilder: FormBuilder,
  ) {
    // console.log("create cohort..........",this.data);

    if (this.userService.user?.role?.name != this.eRole.ADMIN) {
      this.accountId = this.userService.user?.accountId;
    }

    this.variablesSearchControl.valueChanges.subscribe((x: any) => {
      this.filteredVariablesList = this.variablesList?.filter((a: any) =>
        a['name'].toLowerCase().includes(x.toLowerCase())
      );
    });

    this.searchControl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((x) => {
      this.searchStudent = x
      if (this.searchStudent != null) {
        this.getStudents();
      }

    });
  }

  ngOnInit(): void {
    if (this.userService.user?.role?.key == this.eRole.ADMIN) {
      this.searchControl.disable();
    }

    this.initForm();
    this.getStudents();

  }

  initForm() {
    this.cohortForm = this.formbuilder.group({
      name: new FormControl('', [Validators.required]),
      clinicalFrameworkName: new FormControl("", [Validators.required]),
      clinicalFrameworkVariables: new FormControl([], [Validators.required]),
      summary: new FormControl(''),
    })
    this.cohortForm.controls.clinicalFrameworkName.disable();
    this.cohortForm?.controls.clinicalFrameworkName.setValue(this.data.clinicalFrameworkName);
    this.cohortForm.controls.clinicalFrameworkVariables.setValue([this.data.variableName]);

    this.getClinicalFrameworkVariableList();
  }

  get f() {
    return this.cohortForm.controls;
  }

  getClinicalFrameworkVariableList() {
    this._clinicalFramework.getClinicalFrameworkVariableListByClinicalFrameworkId(this.data.clinicalFrameWorkId).subscribe((res: any) => {
      this.variablesList = res?.data;
      this.filteredVariablesList = res?.data;

      // this.setSelectedClinicalFrameworkVariable();
    })
  }

  // setSelectedClinicalFrameworkVariable() {
  //   // Find the object that matches the variableName from the data
  //   const selectedVariable = this.filteredVariablesList.find(
  //     variable => variable.name === this.data.variableName
  //   );
  //   if (selectedVariable) {
  //     this.cohortForm.controls.clinicalFrameworkVariables.setValue([selectedVariable]);
  //   }
  // }

  onSelectVariables() {
    // console.log("clinicalFrameworkVariables",this.cohortForm.value.clinicalFrameworkVariables);

  }

  getStudents() {

    if (this.data?.type === AppConsts.prevalence) {
      this.cohortForm.controls.clinicalFrameworkVariables.disable();
      this.cohortCreationSourceEnum = CohortCreationSourceEnum.CohortDrillDownForPrevalenceRate;
    } else {
      this.cohortCreationSourceEnum = CohortCreationSourceEnum.HomeNotScreenedDrillDown;
    }

    let cohortStudentFilterInputDto = new CohortStudentFilterInputDto();
    cohortStudentFilterInputDto.accountId = this.accountId ? this.accountId : this.data.accountId;
    cohortStudentFilterInputDto.clinicalFrameworkIds = Array.isArray(this.data.clinicalFrameWorkId) ? this.data.clinicalFrameWorkId : [this.data.clinicalFrameWorkId];
    cohortStudentFilterInputDto.filterVariables = this.cohortForm.controls.clinicalFrameworkVariables.value;
    cohortStudentFilterInputDto.startDate = this.data.startDate;
    cohortStudentFilterInputDto.endDate = this.data.endDate;
    cohortStudentFilterInputDto.pageIndex = this.pageIndex;
    cohortStudentFilterInputDto.pageSize = this.pageSize;
    cohortStudentFilterInputDto.schoolIds = this.data.selectedSchools?.length > 0 ? this.data.selectedSchools : [];
    cohortStudentFilterInputDto.type = this.cohortCreationSourceEnum;
    cohortStudentFilterInputDto.searchFilter = this.searchStudent;
    cohortStudentFilterInputDto.percentageBucket = this.data?.percentageBucket;

    this._cohortService.getDrillDownStudentsForCohort(cohortStudentFilterInputDto).subscribe((res: any) => {
      if (res.success == true) {
        this.cohortList = res?.data?.datas.map((item: any) => ({
          ...item,
          check: true
        }));
        this.totalRecordCount = res?.data?.totalRecordCount;

        this.availableUsers = [...this.availableUsers, ...res?.data?.datas.filter((item: any) => {
          // Ensure there are no duplicates by checking if the studentId already exists
          return !this.availableUsers.some((user: any) => user.studentId === item.studentId);
        })];


        if (this.cohortList) {
          if (this.filterSelected == 1) {
            this.cohortList?.forEach((element: any) => {
              element.check = true

              let indexOAU = this.availableUsers.findIndex((x: any) => x.studentId == element.studentId);
              if (indexOAU == -1) {
                this.availableUsers.push(element);
              }

              let index = this.selectedUsers.findIndex((x: any) => x.studentId == element.studentId);
              if (index == -1) {
                this.selectedUsers.push(element);
              }

            });
          } else {
            this.cohortList?.forEach((element: any) => {

              let indexOAU = this.availableUsers.findIndex((x: any) => x.studentId == element.studentId);
              if (indexOAU == -1) {
                this.availableUsers.push(element);
              }

              let index = this.selectedUsers.findIndex((x: any) => x.studentId == element.studentId);
              if (index > -1) {
                element.check = true
              } else {
                element.check = false
              }

            });
          }
        }
      }
    })
  }

  onCheckBoxClick(rowData: any) {
    rowData.check = !rowData.check;
    if (rowData.check) {
      if (!this.isKeyPresent(this.selectedUsers, rowData.studentId)) {
        this.selectedUsers.push(rowData);
      }
    } else {
      this.filterSelected = 2
      this.isAllStudents = false; // Select Specific
      this.selectedUsers = this.selectedUsers.filter((obj: any) => obj.studentId !== rowData.studentId);
    }
    if (this.selectedUsers.length == this.availableUsers.length) {
      this.filterSelected = 1 // Select All
      this.isAllStudents = true;
    }
  }


  isKeyPresent(arr: any, key: any) {
    return arr.some((obj: any) => obj.studentId === key);
  }

  onFilterSelection(val: any) {
    this.selectedUsers = [];
    if (val == 1) {
      this.cohortList.forEach((element: any) => {
        element.check = true;
      })
      this.isAllStudents = true;
      this.selectedUsers = this.cohortList;
    } else {
      this.cohortList.forEach((element: any) => {
        element.check = false;
      })
      this.isAllStudents = false;
    }

  }

  close() {
    this._dialogRef.close();
  }

  onPageChange(event?: any) {
    this.pageIndex = this.paginatorCreateCohort.pageIndex + 1;
    this.pageSize = this.paginatorCreateCohort.pageSize;
    this.getStudents();
  }

  resetPaginator() {
    this.pageIndex = 1;
    this.pageSize = this.pageSizeOptions[0];
    if (this.paginatorCreateCohort) {
      this.paginatorCreateCohort.pageIndex = 1;
      this.paginatorCreateCohort.pageSize = this.pageSizeOptions[0];
    }

  }

  onClose() {
    this._dialogRef.close();
  }

  save() {
    let cohortCreationInputDto = new CohortCreationInputDto();
    cohortCreationInputDto.name = this.cohortForm.value.name;
    cohortCreationInputDto.accountId = this.data.accountId ? this.data.accountId : this.userService.user?.accountId;;
    cohortCreationInputDto.clinicalFrameworkIds = Array.isArray(this.data.clinicalFrameWorkId) ? this.data.clinicalFrameWorkId : [this.data.clinicalFrameWorkId];
    cohortCreationInputDto.cohortCreationSource = this.cohortCreationSourceEnum;
    cohortCreationInputDto.cohortId = null;
    cohortCreationInputDto.accountId = this.accountId ? this.accountId : this.data.accountId;
    cohortCreationInputDto.filterVariables = this.cohortForm.value.clinicalFrameworkVariables;
    cohortCreationInputDto.startDate = this.data.startDate;
    cohortCreationInputDto.endDate = this.data.endDate;
    cohortCreationInputDto.isAllStudents = this.isAllStudents;
    cohortCreationInputDto.studentIds = this.isAllStudents ? [] : this.selectedUsers.map((x: any) => x.studentId);
    cohortCreationInputDto.schoolIds = this.data.selectedSchools?.length > 0 ? this.data.selectedSchools : [];
    cohortCreationInputDto.summary = "";


    this._cohortService.createCohortFromReportingViz(cohortCreationInputDto).subscribe((res: any) => {
      if (res.success) {
        this._snackBarService.open("Successfully Created", SecurlySnackBarType.Success, 2000);
        this._dialog.closeAll();
      }
      else {
        this.warningDialog();
      }
    })
  }

  warningDialog(event?: any): void {
    let dataObj = {
      message: "Cohort Not Created."
    }
    const dialogRef = this._dialog.open(WarningMessageComponent, {
      width: '550px',
      data: dataObj
    });
    dialogRef.afterClosed().subscribe((data: any) => {
      if (data.clicked === 'submit') {
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
