import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';

import { I18nModule } from '@app/i18n';
import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidenav/sidebar.component';
import { AppSelectorComponent } from './app-selector/app-selector.component';
import { SecurlyButtonModule, SecurlyHeaderModule, SecurlyLeftMenuModule } from '@securly-engineering/securly-styles';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@app/shared';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    I18nModule,
    RouterModule,
    MatIconModule,
    SecurlyButtonModule,
    SecurlyHeaderModule,
    SecurlyLeftMenuModule,
    MatSidenavModule,
    MatButtonModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    SharedModule

  ],
  declarations: [
    HeaderComponent,
    AppSelectorComponent,
    SidebarComponent,
    ShellComponent,
  ],
})
export class ShellModule { }
